export const apiurll = "http://192.168.5.121:9003"
export const apiurlipo = "https://v3.mynt.in/ipo"
// export const apiurlipo = "http://192.168.5.79:9003"
export const apiurl = "https://rekycbe.mynt.in/report"
// export const apiurl = "http://192.168.5.138:9000"
export const apidailypnl = "https://report.mynt.in"
// export const pledgeurl = "https://rekycbe.mynt.in/pledge"
export const pledgeurlapi = "https://rekycbe.mynt.in/pledge/"
export const beapi = "https://be.zebull.in/api/"

// export const pledgeurlapi = "http://192.168.5.138:9000/"




