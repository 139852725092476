<template>
    <v-app>
        <v-container class="pa-0 mt-n5 pa-sm-3 pa-md-3 pb-0">
            <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
                <strong>{{ msg }}</strong>
            </v-alert>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 pb-0 d-none d-md-block ml-4">
                <v-col cols="12" class="">
                    <v-card class="mt-5 elevation-0">
                        <v-toolbar class="mb-3 toolbar-contantd px-md-3" elevation="0" color="white">
                            <v-list-item class="pl-0 pl-md-1 ">
                                <v-list-item-content>
                                    <v-list-item-title class="text-start"
                                        style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                        Ledger</v-list-item-title>
                                    <v-list-item-subtitle class="text-start pt-1"
                                        style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Financial
                                        activities through debits and credits
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- <v-spacer></v-spacer> -->

                            <v-menu bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n4 mr-2 ml-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                        <v-list-item-title @click="valdwl(item.title)">{{ item.title
                                            }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-select label="Filled" :items="filterr" @change="filterseg(filterdata_unpl)" dense rounded
                                solo flat v-model="filterdata_unpl" placeholder="filter" background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n3 fildrop" style="max-width: 140px; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                    <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select>
                            <v-menu class="" left v-model="menu2" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field @click:prepend-inner="menu2 = true" style="width: 370px;"
                                        class="mt-3 mr-2 " solo rounded flat v-model="fromshow" label="Date" dense
                                        color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card>
                                    <div>
                                        <v-date-picker color="black" v-model="fromdate"></v-date-picker>
                                        <v-date-picker color="black" v-model="todate"
                                            @change="getlegderdata()"></v-date-picker>
                                    </div>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getlegderdataforparticular(7)">Last 7 days</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getlegderdataforparticular(30)">Last 30 days</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getledger(items[0])">
                                            Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getledger(items[1])">
                                            Last FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getledger(items[2])">
                                            {{ items[2] }}</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getledger(items[3])">
                                            {{ items[3] }}</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                            @click="getledger(items[4])">
                                            {{ items[4] }}</p>
                                    </div>
                                </v-card>
                            </v-menu>
                            <v-text-field v-model="search" class=" ml-2 mt-4" height="36px" background-color="#F1F3F8"
                                style="min-width: 180px; " label="Search " solo rounded flat dense>
                                <template v-slot:prepend-inner>
                                    <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                        height="18px" />
                                </template>
                            </v-text-field>
                        </v-toolbar>
                        <v-divider class="mb-5"></v-divider>
                        <div class="display-flex row  pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class=" pl-3 py-1  " outlined height="90px" width="">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Opening Balance</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card v-if="showup.opening_balance > 0 && showup.opening_balance"
                                                class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(238, 255, 237);">
                                                <p class="mb-0"
                                                    style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.opening_balance).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else-if="showup.opening_balance < 0 && showup.opening_balance"
                                                class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(255, 238, 238);">
                                                <p class="mb-0"
                                                    style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.opening_balance).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else-if="showup.opening_balance == 0 && showup.opening_balance"
                                                class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"
                                                    style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.opening_balance).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"
                                                    style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    0</p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Total Debit </p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(255, 238, 238);">
                                                <p v-if="showup.dr_amt || showup.dr_amt == 0" class="mb-0"
                                                    style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ showup.dr_amt.toFixed(2) }}</p>

                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Total Credit</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card v-if="showup.cr_amt > 0" class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(238, 255, 237);">
                                                <p class="mb-0"
                                                    style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.cr_amt).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else-if="showup.cr_amt < 0" class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(255, 238, 238);">
                                                <p class="mb-0"
                                                    style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.cr_amt).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else-if="showup.cr_amt == 0" class="py-1 px-2 elevation-0 ml-1"
                                                color="#F1F3F8">
                                                <p class="mb-0"
                                                    style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.cr_amt).toFixed(2) }}</p>

                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Closing Balance</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card v-if="showup.closing_balance > 0" class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(238, 255, 237);">
                                                <p class="mb-0"
                                                    style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.closing_balance).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else-if="showup.closing_balance < 0"
                                                class="py-1 px-2 elevation-0 ml-1"
                                                style="background-color: rgb(255, 238, 238);">
                                                <p class="mb-0"
                                                    style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.closing_balance).toFixed(2) }}</p>

                                            </v-card>
                                            <v-card v-else-if="showup.closing_balance == 0"
                                                class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"
                                                    style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (showup.closing_balance).toFixed(2) }}</p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="">
                            <v-data-table ref="dataTableRef" :search="search" :loading="loader" mobile-breakpoint
                                hide-default-footer height="450px" fixed-header disable-sort :headers="Overviewheaders"
                                :items="sortedItems" :items-per-page="itemperpage"
                                style="border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg">

                                <template v-slot:[`item.COCD`]="{ item }">
                                    <span v-if="item.bill == 'No'" class="font-weight-regular">{{ item.TYPE }}</span>
                                    <span v-else class="font-weight-regular">Bill</span>
                                    <p class="mb-0 font-weight-regular" style="font-size: 11px;" v-if="item.COCD != ''">
                                        ({{
                                            item.COCD }})
                                    </p>

                                </template>
                                <template v-slot:[`item.DR_AMT`]="{ item }">
                                    <span class="font-weight-regular"><span class="font-weight-bold caption"
                                            v-if="item.DR_AMT != 0">₹ </span>{{ item.DR_AMT }}</span>
                                </template>
                                <template v-slot:[`item.VOUCHERDATE`]="{ item }">
                                    <span class="font-weight-bold">{{ item.VOUCHERDATE }}</span>
                                </template>
                                <template v-slot:[`item.NET_AMT`]="{ item }">
                                    <span v-if="item.NET_AMT > 0" class="font-weight-regular" style="color: green;">{{
                                        (item.NET_AMT).toFixed(2) }}</span>
                                    <span v-if="item.NET_AMT < 0" class="font-weight-regular" style="color: red;">{{
                                        (item.NET_AMT).toFixed(2) }}</span>
                                    <span v-if="item.NET_AMT == 0" class="font-weight-regular" style="color: black;">{{
                                        (item.NET_AMT).toFixed(2) }}</span>
                                </template>
                                <template v-slot:[`item.CR_AMT`]="{ item }">
                                    <span class="font-weight-regular"><span class="font-weight-bold caption"
                                            v-if="item.CR_AMT != 0">₹ </span>{{ item.CR_AMT }}</span>
                                </template>
                                <template v-slot:[`item.NARRATION`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.NARRATION }}<span
                                            style="cursor: pointer;color: #0037B7; border-bottom: 1px #FF8D24;"
                                            @click="popup(item)" v-if="item.BILLNO != '' && item.bill == 'Yes'"
                                            class="font-weight-bold"><span style="color: black;">-</span>({{
                                                item.BILLNO.slice(-4)
                                            }})</span></span>
                                </template>
                                <template v-slot:no-data>
                                    <v-col class="mx-auto pa-15 mt-5">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                            height="" />
                                        <p class="font-weight-bold black--text">No data available</p>
                                    </v-col>
                                </template>
                            </v-data-table>
                            <v-dialog v-model="dialog" width="80%" class="elevation-0"
                                transition="dialog-bottom-transition">
                                <v-card class=" elevation-0 pt-3 pb-2" height="100%">
                                    <v-btn :ripple="false" dense class="mt-n4" text icon solo absolute top right
                                        @click="closepop()"><v-icon size="18" color="grey">mdi
                                            mdi-close</v-icon></v-btn>
                                    <v-toolbar class=" mb-1 mt-4 toolbar-contant" elevation="0" color="white">
                                        <v-list class="" style="background-color: transparent;">
                                            <v-list-item class=" pt-0">
                                                <v-list-item-content class=" pt-0">
                                                    <v-list-item-title class="text-start"
                                                        style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                                        Bills and Taxes <span class="d-md-none"
                                                            style="color: #000000;font-size: 12px;font-weight:440;line-height:24px;letter-spacing:-1px">
                                                            ({{ desserts5.length }}
                                                            Transactions)</span></v-list-item-title>

                                                    <v-list-item-subtitle class="text-start pt-1"
                                                        style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">Bills
                                                        and Taxes Details About the bill
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-spacer></v-spacer>
                                        <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                                                <v-list-item-content class="pl-2 pa-0">
                                                    <v-list-item-title
                                                        class="text-start font-weight-medium d-none d-md-block"
                                                        style="color: #000000;font-size: 14px;line-height:16px;">
                                                        {{ desserts5.length }}
                                                        Transactions</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-text-field v-model="search1" class="tophundraedmutual mt-4" height="36px"
                                            background-color="#F1F3F8" style="width: 2%;" label="Search" solo rounded
                                            flat dense>
                                            <template v-slot:prepend-inner>
                                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')"
                                                    width="20px" height="18px" />
                                            </template>
                                        </v-text-field>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <div class="display-flex row mx-4 py-8">
                                        <v-row>
                                            <v-col v-for="item in dessertsexpense" :key="item.name">
                                                <v-card class=" pl-3 py-1  " outlined height="82px" width="">
                                                    <p class="pt-1 mb-3"
                                                        style="font-weight:450;font-size: 13px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                                        {{ item.SCRIP_NAME }}</p>
                                                    <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                                        color="#000000"></v-divider>
                                                    <div class="display-flex row pt-6 pl-2">
                                                        <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                            <p class="mb-0"
                                                                style="color: #666;font-size: 12px;font-weight: 350;line-height: 12px;letter-spacing: 0.9px;">
                                                                {{ item.NETAMT }}</p>
                                                        </v-card>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class=" mb-4">

                                        <v-data-table hide-default-footer dense :loading="loaderpop" height="250px"
                                            class="mx-3" :items-per-page="desserts5.length" fixed-header
                                            :headers="Overviewheaders4" :items="desserts5" :search="search1"
                                            disable-sort>
                                            <template v-slot:footer>
                                                <v-divider></v-divider>
                                                <div class="text-end">
                                                    <!-- <span>{{ totalnet }}</span> -->
                                                    <span class="mr-5 mt-4"
                                                        style="color: black;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Total
                                                        : <span v-if="totalnet > 0" style="color: green"> {{
                                                            totalnet.toFixed(2)
                                                            }}</span>
                                                        <span v-if="totalnet < 0" style="color: red"> {{
                                                            totalnet.toFixed(2)
                                                            }}</span>
                                                    </span>
                                                </div>

                                            </template>
                                            <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                                <span class="font-weight-bold body-2">{{ item.SCRIP_NAME }}</span>
                                            </template>
                                            <template v-slot:[`item.BQTY`]="{ item }">
                                                <span class="font-weight-regular caption">{{ (item.BQTY).toFixed(2)
                                                    }}</span>
                                            </template>
                                            <template v-slot:[`item.BRATE`]="{ item }">
                                                <span class="font-weight-regular caption">{{ (item.BRATE).toFixed(2)
                                                    }}</span>
                                            </template>
                                            <template v-slot:[`item.BAMT`]="{ item }">
                                                <span class="font-weight-regular caption">{{ (item.BAMT).toFixed(2)
                                                    }}</span>
                                            </template>
                                            <template v-slot:[`item.SQTY`]="{ item }">
                                                <span class="font-weight-regular caption">{{ (item.SQTY).toFixed(2)
                                                    }}</span>
                                            </template>
                                            <template v-slot:[`item.SRATE`]="{ item }">
                                                <span class="font-weight-regular caption">{{ (item.SRATE).toFixed(2)
                                                    }}</span>
                                            </template>
                                            <template v-slot:[`item.SAMT`]="{ item }">
                                                <span class="font-weight-regular caption">{{ (item.SAMT).toFixed(2)
                                                    }}</span>
                                            </template>
                                            <template v-slot:[`item.NETQTY`]="{ item }">
                                                <span v-if="item.NETQTY" class="font-weight-regular caption">{{
                                                    item.NETQTY }}</span>
                                                <span v-else class="font-weight-regular caption">0</span>

                                            </template>
                                            <template v-slot:[`item.NETAMT`]="{ item }">
                                                <span v-if="item.NETAMT" class="font-weight-regular caption">{{
                                                    item.NETAMT }}</span>
                                                <span v-else class="font-weight-regular caption">0</span>

                                            </template>

                                        </v-data-table>
                                    </div>
                                </v-card>
                            </v-dialog>
                        </div>
                    </v-card>
                </v-col>
            </div>
            <div class=" d-md-none">
                <v-col class="pa-2">
                    <v-card class="elevation-0">
                        <v-toolbar class="mt-3  mb-1 toolbar-contant" elevation="0" color="white">

                            <v-list class="px-0" style="background-color: transparent;">
                                <v-list-item class=" px-0 pt-0">
                                    <v-list-item-content class=" pt-0 px-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                            Ledger</v-list-item-title>
                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 10px;font-weight:469;line-height:14px">Financial
                                            activities through debits and credits
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-divider class=""></v-divider>
                        <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3">
                            
                            <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="5">
                                                <p class="caption"
                                                    style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                                    Opening Balance</p>
                                            </v-col>
                                            <v-col cols="7">
                                                <p v-if="showup.opening_balance > 0" style="color: #E12626; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.opening_balance).toFixed(2) }}
                                                </p>
                                                <p v-else style="color: #000000; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    0
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="5">
                                                <p class="caption"
                                                    style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                                    Total Debit</p>
                                            </v-col>
                                            <v-col cols="7">
                                                <p v-if="showup.dr_amt > 0" style="color: #E12626; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.dr_amt).toFixed(2) }}
                                                </p>
                                                <p v-else style="color: #000000; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    0
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="5">
                                                <p class="caption"
                                                    style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                                    Total Credit</p>
                                            </v-col>
                                            <v-col cols="7">
                                                <p v-if="showup.cr_amt > 0"
                                                    style="color: rgb(52, 168, 83) ; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.cr_amt).toFixed(2) }}
                                                </p>
                                                <p v-else-if="showup.cr_amt < 0"
                                                    style="color: rgb(225, 38, 38); line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.cr_amt).toFixed(2) }}
                                                </p>
                                                <p v-else-if="showup.cr_amt == 0"
                                                    style="color: #000000; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.cr_amt).toFixed(2) }}
                                                </p>
                                                <p v-else style="color: #000000; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    0
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="5">
                                                <p class="caption"
                                                    style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                                    Closing Balance</p>
                                            </v-col>
                                            <v-col cols="7">
                                                <p v-if="showup.closing_balance > 0 && showup.closing_balance"
                                                    style="color: rgb(52, 168, 83) ; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.closing_balance).toFixed(2) }}
                                                </p>
                                                <p v-else-if="showup.closing_balance < 0 && showup.closing_balance"
                                                    style="color: rgb(225, 38, 38); line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.closing_balance).toFixed(2) }}
                                                </p>
                                                <p v-else-if="showup.closing_balance == 0 && showup.closing_balance"
                                                    style="color: #000000; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    {{ (showup.closing_balance).toFixed(2) }}
                                                </p>
                                                <p v-else style="color: #000000; line-height:28px;"
                                                    class="ctnbtl body-1 text-right">
                                                    0
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                        <v-divider class=" mt-3"></v-divider>
                        <v-row class="pt-3">
                            <v-col cols="10">
                                <v-text-field v-model="search" class="tophundraedmutual ml-2 " height="24px"
                                    background-color="#F1F3F8" style="width: 100%;" label="Search" solo rounded flat
                                    dense>
                                    <template v-slot:prepend-inner>
                                        <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')"
                                            width="20px" height="18px" />
                                    </template>
                                </v-text-field>
                            </v-col>
                            <!-- <v-col cols="5">
                                <v-select style="width: 100%;" dense background-color="#F1F3F8" rounded
                                    @change="getledger(yearis)" :items="items" v-model="yearis" flat solo
                                    class=" txtfield  elevation-0 rounded-pill  ml-auto  " label="From"
                                    prepend-inner-icon="mdi-calendar" hide-details append-icon="mdi-chevron-down">
                                </v-select>
                            </v-col> -->
                            <v-col cols="2" class="text-right">
                                <div class="text-center">
                                    <v-bottom-sheet v-model="sheet" inset>

                                        <v-sheet class="text-center" height="200px">
                                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                                            </v-btn> -->
                                            <!-- {{ radios }}/ -->
                                            <p class="mb-0 pt-3 title font-weight-medium">Ledger<span
                                                    class="caption">({{
                                                        yearis }})</span></p>
                                            <v-card class="elevation-0 ma-8 mt-0">
                                                <v-radio-group class="mt-0" v-model="radios" mandatory>
                                                    <v-radio color="black" label="Download Pdf"
                                                        value="Download Pdf"></v-radio>
                                                    <v-radio color="black" label="Download Excel"
                                                        value="Download Excel"></v-radio>
                                                </v-radio-group>
                                                <v-btn @click="valdwl(radios)" class="white--text elevation-0"
                                                    color="black" block rounded flat solo>
                                                    {{
                                                        radios }}</v-btn>
                                            </v-card>
                                        </v-sheet>
                                    </v-bottom-sheet>
                                </div>
                                <v-btn v-if="desserts.length > 0" @click="sheet = true" class="ml-auto mr-6 pt-2" flat
                                    solo icon>
                                    <v-icon>mdi
                                        mdi-download</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class=" "></v-divider>
                        <v-row>
                            <v-col cols="8">
                                <v-menu class="" v-model="menu23" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-4 mx-2 " solo rounded flat v-model="fromshow"
                                            label="Date" dense color="black" background-color="#F1F3F8"
                                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="on"></v-text-field>
                                    </template>

                                    <v-card>
                                        <div>
                                            <v-date-picker color="black" v-model="fromdate"></v-date-picker>
                                            <v-date-picker color="black" v-model="todate"
                                                @change="getlegderdata()"></v-date-picker>
                                        </div>
                                        <v-divider></v-divider>
                                        <div class="d-flex mt-n2">
                                            <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getlegderdataforparticular(7)">Last 7 days</p>
                                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getlegderdataforparticular(30)">Last 30 days</p>
                                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getledger(items[0])">Current FY</p>
                                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getledger(items[1])">Last FY</p>
                                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getledger(items[2])">{{ items[2] }}</p>
                                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getledger(items[3])">{{ items[3] }}</p>
                                            <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                                                @click="getledger(items[4])">{{ items[4] }}</p>
                                            <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(-1)">Last F Year</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="mountedfunyearselect(0)">Current F Year</p> -->
                                            <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                        </div>
                                    </v-card>

                                </v-menu>
                            </v-col>
                            <v-col class="text-right">
                                <!-- <v-btn @click="yearpopupscreen" class="ml-auto mr-6 mt-5" flat solo
                                    icon> <v-icon>mdi-calendar</v-icon></v-btn> -->
                                    <v-select label="Filled" :items="filterr" @change="filterseg(filterdata_unpl)" dense
                            rounded
                            
                                        solo flat
                                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                                class="mr-2 mt-4 fildrop" style="max-width: 140px; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select>
                            </v-col>
                        </v-row>
                        <v-bottom-sheet inset v-model="yearpop">
                            <v-sheet class="text-center" height="300px">
                                <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                                            </v-btn> -->
                                <!-- {{ radios }}/ -->
                                <p class="mb-0 pt-3 title font-weight-medium">Select Year</p>
                                <v-card class="elevation-0 ma-8 mt-0">
                                    <v-radio-group v-model="yearradio">
                                        <v-radio color="black" v-for="n in items" :key="n" :label="n"
                                            :value="n"></v-radio>
                                    </v-radio-group>
                                    <v-btn @click="getledger(yearradio)" class="white--text elevation-0" color="black"
                                        block rounded flat solo>Done
                                    </v-btn>
                                </v-card>
                            </v-sheet>
                        </v-bottom-sheet>

                        <v-divider class=""></v-divider>

                        <v-card v-for="item in filteredDesserts " :key="item.index"
                            style="border-top: 5px #F1F3F8 solid;" class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col>
                                    <p class="text-start pt-2 mb-0"
                                        style="color: #000000;font-size: 15px;font-weight:459;">
                                        {{ item.VOUCHERDATE }} <span class="caption"> {{ item.COCD }}</span>
                                    </p>
                                </v-col>
                                <v-col class="text-end">
                                    <p v-if="item.CR_AMT != 0" class="text-end pt-2 mb-0"
                                        style="color: green;font-size: 15px;font-weight:459;">{{ item.CR_AMT }}
                                    </p>
                                    <p v-if="item.DR_AMT != 0" class="text-end pt-2 mb-0"
                                        style="color: red;font-size: 15px;font-weight:459;">{{ item.DR_AMT }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-2"></v-divider>
                            <v-row class="px-2">
                                <v-col cols="3">
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item.TYPE }}
                                    </p>
                                </v-col>
                                <v-col class="text-end" cols="9">
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Closing Balance :
                                        <span>{{ item.NET_AMT }}</span>
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-2 "></v-divider>
                            <v-row class="mb-3 mt-2 px-5">
                                <span class="font-weight-regular caption">{{ item.NARRATION }}<span
                                        style="cursor: pointer;color: #0037B7; border-bottom: 1px #FF8D24;"
                                        @click="popup1(item)" v-if="item.bill == 'Yes'" class="font-weight-bold"><span
                                            style="color: black;">-</span>({{
                                                item.BILLNO.slice(-4)
                                            }})</span></span>
                            </v-row>


                        </v-card>
                        <v-card v-if="filteredDesserts.length == 0 && loader == false"
                            style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <!-- <v-icon size="140">mdi mdi-file-image-remove</v-icon> -->
                                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                    <p class="font-weight-bold black--text">No data available</p>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-dialog v-model="dialog1" fullscreen transition="dialog-bottom-transition">

                            <v-card class="elevation-0">

                                <v-list-item-content class=" pt-5 pl-5">
                                    <v-list-item-title class="text-start"
                                        style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                        Bills and Taxes </v-list-item-title>

                                    <v-list-item-subtitle class="text-start pt-1"
                                        style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">Bills
                                        and
                                        Taxes Details About the bill
                                    </v-list-item-subtitle>
                                    <v-btn absolute right top icon color="black" @click="dialog1 = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-list-item-content>
                                <v-divider></v-divider>
                                <v-card color="#eeeeee" outlined
                                    class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3">
                                    <div class="text-center pa-4">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Total</p>
                                        <p v-if="totalnet < 0 && totalnet != ''" class="ctnbtl headline mb-0"
                                            style="line-height:28px; color: red; ">
                                            {{ (totalnet).toFixed(2) }}</p>
                                        <p v-if="totalnet > 0 && totalnet != ''" class="ctnbtl headline mb-0"
                                            style="line-height:28px; color: green; ">
                                            {{ (totalnet).toFixed(2) }}</p>
                                        <p v-if="totalnet == 0 && totalnet != ''" class="ctnbtl headline mb-0"
                                            style="line-height:28px; color: rgb(0, 0, 0); ">
                                            {{ (totalnet).toFixed(2) }}</p>
                                        <p v-if="totalnet == ''" class="ctnbtl headline mb-0"
                                            style="line-height:28px;  ">
                                            -</p>
                                    </div>
                                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                                        <v-row no-gutters>
                                            <!-- {{ dessertsexpense }} -->
                                            <v-col v-for="item in dessertsexpense" :key="item.index" cols="12">
                                                <v-row no-gutters>
                                                    <v-col cols="5">
                                                        <p class="caption"
                                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                                            {{ item.SCRIP_NAME }}</p>
                                                    </v-col>
                                                    <v-col cols="7">
                                                        <p style="color: rgb(0, 0, 0) ; line-height:28px;"
                                                            class="ctnbtl body-1 text-right">
                                                            {{ item.NETAMT }}
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card>
                                <v-divider class="mt-2 mb-2 "></v-divider>
                                <div class="d-flex row pb-1 mr-n4">
                                    <p class="text-start    mt-4 ml-2 px-4 "
                                        style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
                                        No of tranaction
                                        <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ desserts5.length
                                            }})</span>
                                    </p>
                                </div>
                                <v-card v-for="item in desserts5 " :key="item.index"
                                    style="border-top: 5px #F1F3F8 solid;" class="elevation-0  px-4" width="100%">
                                    <p class="text-start pa-2 pb-0 mb-0"
                                        style="color: #000000;font-size: 15px;font-weight:459;">{{
                                        item.SCRIP_NAME }}
                                    </p>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-row class="px-2">
                                        <v-col>
                                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net
                                                Amount:
                                            </p>
                                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy
                                                Quantity:
                                                <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                    item.BQTY
                                                    }}</span>
                                            </p>
                                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate
                                                :
                                                <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                    (item.BRATE).toFixed(2) }}</span>
                                            </p>
                                        </v-col>
                                        <v-col class="text-end">
                                            <span v-if="item.NETAMT">
                                                <p v-if="item.NETAMT > 0" class="mb-0 body-2 font-weight-regular"
                                                    style="color: green;">
                                                    {{ (item.NETAMT).toFixed(2) }}</p>
                                                <p v-else-if="item.NETAMT < 0" class="mb-0 body-2 font-weight-regular"
                                                    style="color: red;">{{
                                                        (item.NETAMT).toFixed(2) }}</p>
                                                <p v-else-if="item.NETAMT == 0" class="mb-0 body-2 font-weight-regular"
                                                    style="color: #000000;">
                                                    {{ (item.NETAMT).toFixed(2) }}</p>
                                            </span>
                                            <!-- <span v-else>
                                                <p v-if="item.CLOSINGAMT > 0" class="mb-0 body-2 font-weight-regular"
                                                    style="color: green;">
                                                    {{ item.CLOSINGAMT }}</p>
                                                <p v-else-if="item.CLOSINGAMT < 0" class="mb-0 body-2 font-weight-regular"
                                                    style="color: red;">{{
                                                        item.CLOSINGAMT }}</p>
                                                <p v-else-if="item.CLOSINGAMT == 0" class="mb-0 body-2 font-weight-regular"
                                                    style="color: #000000;">
                                                    {{ item.CLOSINGAMT }}</p>
                                            </span> -->
                                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sale
                                                Quantity: <span class="mb-0 body-2 font-weight-regular"
                                                    style="color: #000000;">{{
                                                    (item.SQTY).toFixed(2) }}
                                                </span>
                                            </p>
                                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sale Rate
                                                :
                                                <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                    item.SRATE }}
                                                </span>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card v-if="desserts5.length == 0 && loader == false"
                                    style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                                    class="elevation-0  px-4" width="100%">
                                    <v-row class="px-2">
                                        <v-col class="mx-auto text-center my-4">
                                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                                height="" />
                                            <p class="font-weight-bold black--text">No data available</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card v-if="loader"
                                    style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                                    class="elevation-0  px-4" width="100%">
                                    <v-row class="px-2">
                                        <v-col class="mx-auto text-center my-4">
                                            <v-progress-circular :size="50" color="primary"
                                                indeterminate></v-progress-circular>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
            </div>
        </v-container>
    </v-app>
</template>
<script>
import axios from "axios"
// import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import zebupdfImage from '../../../assets/zebupdf.png';

import { apiurl } from '../../../Api.js'

import ExcelJS from 'exceljs';
import imagePath from '@/assets/zebulogo.svg';
export default {
    data() {
        return {
            expanded: [],
            dessertsexpense: [],
            totalnet: 0,
            singleExpand: true,
            filteropenbal: 0,
            filterclosebal: 0,

            dessertHeaders: [
                {
                    text: 'Dessert (100g serving)',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Calories', value: 'calories' },
                { text: 'Fat (g)', value: 'fat' },
                { text: 'Carbs (g)', value: 'carbs' },
                { text: 'Protein (g)', value: 'protein' },
                { text: 'Iron (%)', value: 'iron' },
                { text: '', value: 'data-table-expand' },
            ],
            profiledetails: [],
            cname: '',
            dessertstabs: [
                {
                    name: 'Frozen Yogurt',
                    calories: 159,
                    fat: 6.0,
                    carbs: 24,
                    protein: 4.0,
                    iron: 1,
                },
                {
                    name: 'Ice cream sandwich',
                    calories: 237,
                    fat: 9.0,
                    carbs: 37,
                    protein: 4.3,
                    iron: 1,
                },
                {
                    name: 'Eclair',
                    calories: 262,
                    fat: 16.0,
                    carbs: 23,
                    protein: 6.0,
                    iron: 7,
                },
                {
                    name: 'Cupcake',
                    calories: 305,
                    fat: 3.7,
                    carbs: 67,
                    protein: 4.3,
                    iron: 8,
                },
                {
                    name: 'Gingerbread',
                    calories: 356,
                    fat: 16.0,
                    carbs: 49,
                    protein: 3.9,
                    iron: 16,
                },
                {
                    name: 'Jelly bean',
                    calories: 375,
                    fat: 0.0,
                    carbs: 94,
                    protein: 0.0,
                    iron: 0,
                },
                {
                    name: 'Lollipop',
                    calories: 392,
                    fat: 0.2,
                    carbs: 98,
                    protein: 0,
                    iron: 2,
                },
                {
                    name: 'Honeycomb',
                    calories: 408,
                    fat: 3.2,
                    carbs: 87,
                    protein: 6.5,
                    iron: 45,
                },
                {
                    name: 'Donut',
                    calories: 452,
                    fat: 25.0,
                    carbs: 51,
                    protein: 4.9,
                    iron: 22,
                },
                {
                    name: 'KitKat',
                    calories: 518,
                    fat: 26.0,
                    carbs: 65,
                    protein: 7,
                    iron: 6,
                },
            ],
            itemstab: [
                'Overview', 'Family'
            ],
            msg: '',
            alert: false,
            radios: null,
            sheet: false,
            // alert: false,
            filterdata_unpl: "All",

            filterr: ['All', 'Receipt', 'Payment', 'Journal', 'System journal'],
            itemsdwl: [
                {
                    title: 'Download Excel',
                },
                {
                    title: 'Download Pdf',
                },
            ],
            items: [
            ],
            items2: [
                { val: '2017', key: 0 },
                { val: '2018', key: 1 },
                { val: '2019', key: 2 },
                { val: '2020', key: 3 },
                { val: '2021', key: 4 },
                { val: '2022', key: 5 },
                { val: '2023', key: 6 },
                { val: '2024', key: 7 },
                { val: '2025', key: 8 },
            ],
            yearsele: true,
            more: false,
            less: true,
            menu2: false,
            menu23: false,
            tab: null,
            search: '',
            search1: '',
            loaderpop: false,
            itemperpage: 0,
            desserts: [],
            desserts5: [],
            desserts6: [],
            dessertsforfilter: [],
            pdffile: [],
            showup: [],
            loader: false,
            todate: '',

            fromdate: '',

            fromshow: '',
            toshow: '',
            dialog1: false,
            finaltotal: 0,
            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
            desserts3: [
                {
                    name: 'Brokerage',
                    val: '-',
                },
                {
                    name: 'GST',
                    val: '-'
                },
                {
                    name: 'STT',
                    val: '-'
                },

                {
                    name: 'SEBI',
                    val: '-'
                },
                {
                    name: 'TOT',
                    val: '-'
                },

                {
                    name: 'Stampduty',
                    val: '-'
                },
                {
                    name: ' CM Charges ',
                    val: '-'
                },
            ],
            Overviewheaders4: [

                { text: 'Scrip ', value: 'SCRIP_NAME', width: "12%", align: 'start', class: "headerfont" },
                { text: 'BQty', value: 'BQTY', align: 'end', class: "headerfont" },
                { text: 'BRate ', value: 'BRATE', align: 'end', class: "headerfont" },
                { text: 'BAmount ', value: 'BAMT', align: 'end', class: "headerfont" },
                { text: 'SQty ', value: 'SQTY', align: 'end', class: "headerfont" },
                { text: 'SRate ', value: 'SRATE', align: 'end', class: "headerfont" },
                { text: 'SAmount ', value: 'SAMT', align: 'end', class: "headerfont" },
                { text: 'NQty ', value: 'NETQTY', align: 'end', class: "headerfont" },
                { text: 'NAmt ', value: 'NETAMT', align: 'end', class: "headerfont" },
            ],
            Overviewheaders: [
                { text: 'Date', value: 'VOUCHERDATE', width: "12%", align: 'start', class: "headerfont" },
                { text: 'Exchange', value: 'COCD', width: "15%", class: "headerfont" },
                { text: 'Details', value: 'NARRATION', class: "headerfont" },
                { text: 'Debit', value: 'DR_AMT', align: 'end', class: "headerfont" },
                { text: 'Credit', value: 'CR_AMT', align: 'end', class: "headerfont" },
                { text: 'Net Amount', value: 'NET_AMT', align: 'end', class: "headerfont" },
            ],
            yearis: '',
            sortOrder: -1, // 1 for ascending, -1 for descending
            sortOrderr: 1, // 1 for ascending, -1 for descending
            sortKey: 'Sort_No',
            yearpop: false,
            yearradio: '',


        };
    },
    created() {
        // Perform default sort on component creation
        this.performDefaultSort();
    },
    computed: {
        filteredDesserts() {
            const searchTerm = this.search.toLowerCase();
            return this.sortedItems.filter(item => item.COCD.toLowerCase().includes(searchTerm));
        },
        sortedItems() {
            return this.desserts.slice().sort((a, b) => {
                const modifier = this.sortOrder;
                return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
            });
        },
        sortedItemss() {
            return this.desserts.slice().sort((a, b) => {
                const modifier = this.sortOrderr;
                return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
            });
        },
    },
    methods: {
        filterseg(item) {
            var data = this.dessertsforfilter;
            if (item === "All") {
                this.desserts = data;
                let valueforcr = 0
                let valuefordr = 0

                for (let i = 0; i < this.desserts.length; i++) {
                    if (this.desserts[i].CR_AMT != null) { // Check for null or undefined values
                        valueforcr += this.desserts[i].CR_AMT;
                        this.showup.cr_amt = valueforcr
                    }
                    if (this.desserts[i].DR_AMT != null) { // Check for null or undefined values
                        valuefordr += this.desserts[i].DR_AMT;
                        this.showup.dr_amt = valuefordr
                    }
                }
                this.showup.closing_balance = this.filterclosebal
                this.showup.opening_balance = this.filteropenbal
                // let previousNetAmt = 0;
                // this.desserts.reverse();
                // console.log(this.desserts);
                //     for (let i = 0; i < this.desserts.length; i++) {
                //         if (this.desserts[i].CR_AMT != 0) {
                //         this.desserts[i].NET_AMT = previousNetAmt + this.desserts[i].CR_AMT;
                //         console.log("cr");
                //         } else if (this.desserts[i].DR_AMT != 0) {
                //         this.desserts[i].NET_AMT = previousNetAmt - this.desserts[i].DR_AMT;
                //         console.log("dr");


                //         } else {
                //         this.desserts[i].NET_AMT = previousNetAmt;
                //         console.log("else");

                //         }
                //         previousNetAmt = this.desserts[i].NET_AMT;
                //     }
                //     this.desserts.reverse();

            } else if (item === "Receipt") {
                this.desserts = data.filter(dessert => dessert.TYPE === 'Reciept');
                let valueforcr = 0
                let valuefordr = 0
                // console.log(this.desserts,"HJKL");
                if (this.desserts.length > 0) {

                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != null) { // Check for null or undefined values
                            valueforcr += this.desserts[i].CR_AMT;
                            this.showup.cr_amt = valueforcr
                        }
                        if (this.desserts[i].DR_AMT != null) { // Check for null or undefined values
                            valuefordr += this.desserts[i].DR_AMT;
                            this.showup.dr_amt = valuefordr
                        }
                    }
                    let previousNetAmt = 0;
                    // this.desserts.reverse();
                    this.desserts = this.desserts.slice().sort((a, b) => {
                        const modifier = 1;
                        if (a[this.sortKey] > b[this.sortKey]) {
                            return modifier * 1;
                        } else if (a[this.sortKey] < b[this.sortKey]) {
                            return modifier * -1;
                        } else {
                            return 0;
                        }
                    });
                    // console.log(this.desserts);
                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt + this.desserts[i].CR_AMT;
                            // console.log("cr");
                        } else if (this.desserts[i].DR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt - this.desserts[i].DR_AMT;
                            // console.log("dr");


                        } else {
                            this.desserts[i].NET_AMT = previousNetAmt;
                            // console.log("else");

                        }
                        if (this.desserts[i].NET_AMT) {
                            previousNetAmt = this.desserts[i].NET_AMT;

                        }
                    }
                    // this.desserts.reverse();
                    if (this.desserts[this.desserts.length - 1].NET_AMT) {
                        this.showup.closing_balance = this.desserts[this.desserts.length - 1].NET_AMT

                    } else {
                        this.showup.closing_balance = 0
                    }
                    this.showup.opening_balance = 0
                } else {
                    this.showup.dr_amt = 0
                    this.showup.cr_amt = 0
                    this.showup.opening_balance = 0
                    this.showup.closing_balance = 0
                }


                // console.log(this.showup.cr_amt,this.desserts[0].CR_AMT);

            } else if (item === "Payment") {
                this.desserts = data.filter(dessert => dessert.TYPE === 'Payment');
                let valueforcr = 0
                let valuefordr = 0
                if (this.desserts.length > 0) {

                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != null) { // Check for null or undefined values
                            valueforcr += this.desserts[i].CR_AMT;
                            this.showup.cr_amt = valueforcr
                        }
                        if (this.desserts[i].DR_AMT != null) { // Check for null or undefined values
                            valuefordr += this.desserts[i].DR_AMT;
                            this.showup.dr_amt = valuefordr
                        }
                    }
                    let previousNetAmt = 0;
                    // this.desserts.reverse();
                    this.desserts = this.desserts.slice().sort((a, b) => {
                        const modifier = 1;
                        if (a[this.sortKey] > b[this.sortKey]) {
                            return modifier * 1;
                        } else if (a[this.sortKey] < b[this.sortKey]) {
                            return modifier * -1;
                        } else {
                            return 0;
                        }
                    });
                    // console.log(this.desserts);
                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt + this.desserts[i].CR_AMT;
                            // console.log("cr");
                        } else if (this.desserts[i].DR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt - this.desserts[i].DR_AMT;
                            // console.log("dr");


                        } else {
                            this.desserts[i].NET_AMT = previousNetAmt;
                            // console.log("else");

                        }
                        previousNetAmt = this.desserts[i].NET_AMT;
                    }
                    // this.desserts.reverse();

                    this.showup.closing_balance = this.desserts[this.desserts.length - 1].NET_AMT
                    this.showup.opening_balance = 0
                } else {
                    this.showup.dr_amt = 0
                    this.showup.cr_amt = 0
                    this.showup.opening_balance = 0
                    this.showup.closing_balance = 0
                }

            } else if (item === "Journal") {
                this.desserts = data.filter(dessert => dessert.TYPE === 'Journal');
                let valueforcr = 0
                let valuefordr = 0
                if (this.desserts.length > 0) {

                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != null) { // Check for null or undefined values
                            valueforcr += this.desserts[i].CR_AMT;
                            this.showup.cr_amt = valueforcr
                        }
                        if (this.desserts[i].DR_AMT != null) { // Check for null or undefined values
                            valuefordr += this.desserts[i].DR_AMT;
                            this.showup.dr_amt = valuefordr
                        }
                    }
                    let previousNetAmt = 0;
                    this.desserts = this.desserts.slice().sort((a, b) => {
                        const modifier = 1;
                        if (a[this.sortKey] > b[this.sortKey]) {
                            return modifier * 1;
                        } else if (a[this.sortKey] < b[this.sortKey]) {
                            return modifier * -1;
                        } else {
                            return 0;
                        }
                    });
                    // this.desserts.reverse();
                    // console.log(this.desserts);
                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt + this.desserts[i].CR_AMT;
                            // console.log("cr");
                        } else if (this.desserts[i].DR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt - this.desserts[i].DR_AMT;
                            // console.log("dr");


                        } else {
                            this.desserts[i].NET_AMT = previousNetAmt;
                            // console.log("else");

                        }
                        previousNetAmt = this.desserts[i].NET_AMT;
                    }
                    // this.desserts.reverse();
                    this.showup.closing_balance = this.desserts[this.desserts.length - 1].NET_AMT
                    this.showup.opening_balance = 0
                } else {
                    this.showup.dr_amt = 0
                    this.showup.cr_amt = 0
                    this.showup.opening_balance = 0
                    this.showup.closing_balance = 0
                }
            } else if (item === "System journal") {
                this.desserts = data.filter(dessert => dessert.TYPE === 'Bill');
                let valueforcr = 0
                let valuefordr = 0
                if (this.desserts.length > 0) {

                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != null) { // Check for null or undefined values
                            valueforcr += this.desserts[i].CR_AMT;
                            this.showup.cr_amt = valueforcr
                        }
                        if (this.desserts[i].DR_AMT != null) { // Check for null or undefined values
                            valuefordr += this.desserts[i].DR_AMT;
                            this.showup.dr_amt = valuefordr
                        }
                    }
                    let previousNetAmt = 0;
                    this.desserts = this.desserts.slice().sort((a, b) => {
                        const modifier = 1;
                        if (a[this.sortKey] > b[this.sortKey]) {
                            return modifier * 1;
                        } else if (a[this.sortKey] < b[this.sortKey]) {
                            return modifier * -1;
                        } else {
                            return 0;
                        }
                    });

                    // this.desserts.reverse();
                    // console.log(this.desserts);
                    for (let i = 0; i < this.desserts.length; i++) {
                        if (this.desserts[i].CR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt + this.desserts[i].CR_AMT;
                            // console.log("cr");
                        } else if (this.desserts[i].DR_AMT != 0) {
                            this.desserts[i].NET_AMT = previousNetAmt - this.desserts[i].DR_AMT;
                            // console.log("dr");
                        }
                        // else {
                        // this.desserts[i].NET_AMT = previousNetAmt;
                        // console.log("else");
                        // }
                        // console.log(this.desserts[i]);

                        // console.log("|sr no|",this.desserts[i].Sort_No,"|pr|",previousNetAmt.toFixed(2),"|CR|",this.desserts[i].CR_AMT.toFixed(2), " |DR|", (this.desserts[i].DR_AMT).toFixed(2) ,"|net add|",this.desserts[i].NET_AMT.toFixed(2),);
                        previousNetAmt = this.desserts[i].NET_AMT;
                    }
                    // this.desserts.reverse();
                    this.showup.closing_balance = this.desserts[this.desserts.length - 1].NET_AMT
                    this.showup.opening_balance = 0
                } else {
                    this.showup.dr_amt = 0
                    this.showup.cr_amt = 0
                    this.showup.opening_balance = 0
                    this.showup.closing_balance = 0
                }
            }
        },
        decryptionFunction(payld) {
            var CryptoJS = require("crypto-js");

            const payload = payld;
            const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
            const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
            const encryptedData = payload;

            // Decrypt the data using AES
            const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
                iv,
                mode: CryptoJS.mode.CBC,
            });
            const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

            // // console.log('Decrypted Data:', decryptedData);
            return decryptedData;
        },
        yearpopupscreen() {
            // console.log("fghjk")
            this.yearpop = true
            // this.yearradio = this.yearis
        },
        getlegderdata() {
            this.menu2 = false
            this.menu23 = false
            this.yearis = ''
            //   this.eqloader = true
            //   this.dessertsexpand = []
            //   this.dessertsexpandderivative = []
            //   this.dessertsexpandcommodity = []
            //   this.dessertsexpandcurrency = []


            this.desserts = []

            if (this.fromdate != '' && this.todate != '') {
                this.loader = true
                var fromdate = ''
                var todate = ''
                let dateString = this.fromdate
                let dateObject = new Date(dateString);
                let year = dateObject.getFullYear();
                let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                let day = dateObject.getDate();
                let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
                fromdate = formattedDate
                this.fromshow = fromdate
                this.segfrom = fromdate
                this.fromdateapi = fromdate
                let dateString1 = this.todate
                let dateObject1 = new Date(dateString1);
                let year1 = dateObject1.getFullYear();
                let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
                let day1 = dateObject1.getDate();
                let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
                todate = formattedDate1
                this.toshow = todate
                this.fromshow = this.fromshow + '_' + 'to' + '_' + this.toshow
                // this.equitiesdata()
                // this.derivativesdat()
                let data = JSON.stringify({
                    "cc": this.actid,
                    "from": fromdate,
                    "to": todate,
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: api.apiurl + '/getLedger',
                    url: `${apiurl}/getLedger`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data
                };
                let axiosthis = this
                axios.request(config)
                    .then((response) => {
                        var data = []
                        axiosthis.filterdata_unpl = "All",


                            axiosthis.showup = response.data
                        axiosthis.filteropenbal = response.data.opening_balance

                        axiosthis.filterclosebal = response.data.closing_balance
                        if (axiosthis.showup.msg == 'No Data Available') {
                            axiosthis.loader = false
                        }
                        // axiosthis.itemperpage =
                        axiosthis.loader = false
                        data = response.data['full_stat']
                        axiosthis.itemperpage = data.length
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].CR_AMT == 0 && data[i].DR_AMT == 0) {
                                //console.log("itsempty")
                            } else {
                                //console.log("itsemptyno");
                                var dada = data[i]
                                axiosthis.desserts.push(dada)
                            }

                        }
                        for (let v = 0; v < axiosthis.desserts.length; v++) {
                            let dateString = axiosthis.desserts[v].VOUCHERDATE
                            if (dateString !== "") {
                                let dateObject = new Date(dateString);
                                if (!isNaN(dateObject.getTime())) {
                                    let day = dateObject.getDate();
                                    let month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
                                    let year = dateObject.getFullYear();
                                    // console.log('date',day,'month',month,'year',year,axiosthis.desserts[v].VOUCHERDATE,axiosthis.desserts[v].DR_AMT);
                                    let formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
                                    axiosthis.desserts[v].VOUCHERDATE = formattedDate;
                                    // console.log(axiosthis.desserts[v].VOUCHERDATE);

                                } else {
                                    // You might want to log an error or handle it in an appropriate way
                                }
                                // console.log(axiosthis.desserts, "sdsd");

                            }

                        }
                        // console.log(axiosthis.desserts, "axiosthis.desserts");
                        for (let i = 0; i < axiosthis.desserts.length; i++) {
                            if (axiosthis.desserts[i]['SETL_PAYINDATE'] != '') {
                                let dateString = axiosthis.desserts[i]['SETL_PAYINDATE']
                                let dateObject = new Date(dateString);
                                let year = dateObject.getFullYear();
                                let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                                let day = dateObject.getDate();
                                let formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
                                axiosthis.desserts[i]['SETL_PAYINDATE'] = formattedDate
                            }
                        }
                        for (let u = 0; u < axiosthis.desserts.length; u++) {
                            if (axiosthis.desserts[u].VOUCHERDATE !== '') {
                                const sortedData = axiosthis.desserts.slice().sort((a, b) => {
                                    const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                                    const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                                    return dateA - dateB;
                                });
                                const sortedDataREVERSE = axiosthis.desserts.slice().sort((a, b) => {
                                    const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                                    const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                                    return dateB - dateA;
                                });
                                axiosthis.dessertsforfilter = sortedData;
                                axiosthis.desserts = sortedData;
                                axiosthis.dessertsforfilter = sortedData;

                                axiosthis.pdffile = sortedDataREVERSE
                            }
                        }
                        //         const tags = Array.from(new Set(this.desserts.flatMap(item => item.TYPE)));
                        // axiosthis.filterr = ['All', ...tags];

                        //     for (let e = 0; e < axiosthis.pdffile.length; e++) {
                        //     if (axiosthis.pdffile[e].TYPE === 'Opening Balance') {
                        //         // If the condition is satisfied, remove the object from the array
                        //         console.log("true");
                        //         const removedItem = axiosthis.pdffile.splice(e, 1);
                        //         // Push the removed object to the last position
                        //         axiosthis.pdffile.push(removedItem[0]);
                        //         // Decrement e to avoid skipping the next element
                        //         // e--;
                        //     }
                        // }
                        //     console.log(axiosthis.pdffile, "gggg");
                        // for (let e = 0; e < axiosthis.desserts.length; e++) {
                        //     if (axiosthis.desserts[e].TYPE === 'Opening Balance') {
                        //         // If the condition is satisfied, remove the object from the array
                        //         console.log("true");
                        //         const removedItem = axiosthis.desserts.splice(e, 1);
                        //         // Push the removed object to the last position
                        //         axiosthis.desserts.push(removedItem[0]);
                        //         // Decrement e to avoid skipping the next element
                        //         // e--;
                        //     }
                        // }
                        // Assuming axiosthis is an object with a desserts property which is an array
                        // Also assuming axiosthis.desserts[e].axiosthis.desserts should be axiosthis.desserts[e].dessert


                        // Now, axiosthis.desserts array has the 'Opening Balance' objects moved to the last positions

                        // this.scrollToBottom()
                    })
                    .catch((error) => {

                        console.log(error);
                        if (error.message == 'Network Error') {
                            this.msg = error.message
                            axiosthis.loader = false
                            axiosthis.alert = true
                            setTimeout(() => {
                                axiosthis.alert = false
                            }, 5000);
                            // alert(error.message)
                        }
                    });
                // const axios = require('axios');

                // this.equitiesdata()
                // this.derivativesdat()

            }
        },
        getlegderdataforparticular(valuefor) {
            this.menu2 = false
            this.menu23 = false
            this.yearis = ''
            //   this.eqloader = true
            //   this.dessertsexpand = []
            //   this.dessertsexpandderivative = []
            //   this.dessertsexpandcommodity = []
            //   this.dessertsexpandcurrency = []


            this.desserts = []

            // if (this.fromdate != '' && this.todate != '') {
            this.loader = true
            var fromdate = ''
            var todate = ''
            var valuedate = valuefor
            // let dateString = this.fromdate
            // var today = new Date();
            var today = new Date();
            let sevthdate = new Date(today);
            sevthdate.setDate(today.getDate() - valuedate);
            let ddsevthdate = String(sevthdate.getDate()).padStart(2, '0');
            let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
            let yyyysevthdate = sevthdate.getFullYear();
            let seventhdate = ddsevthdate + "/" + mmsevthdate + "/" + yyyysevthdate;
            this.fromdate = yyyysevthdate + "-" + mmsevthdate + "-" + ddsevthdate;
            fromdate = seventhdate
            this.fromshow = fromdate
            this.segfrom = fromdate
            this.fromdateapi = fromdate
            // let dateString1 = this.todate
            let dateObject1 = new Date(today);
            let year1 = dateObject1.getFullYear();
            let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
            let day1 = dateObject1.getDate();
            let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
            this.todate = year1 + '-' + (month1 < 10 ? '0' : '') + month1 + '-' + (day1 < 10 ? '0' : '') + day1
            todate = formattedDate1
            this.toshow = todate
            this.fromshow = this.fromshow + '_' + 'to' + '_' + this.toshow
            // this.equitiesdata()
            // this.derivativesdat()
            let data = JSON.stringify({
                "cc": this.actid,
                "from": fromdate,
                "to": todate,
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: api.apiurl + '/getLedger',
                url: `${apiurl}/getLedger`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    var data = []
                    axiosthis.filterdata_unpl = "All",

                        axiosthis.showup = response.data
                    axiosthis.filteropenbal = response.data.opening_balance

                    axiosthis.filterclosebal = response.data.closing_balance
                    if (axiosthis.showup.msg == 'No Data Available') {
                        axiosthis.loader = false
                    }
                    // axiosthis.itemperpage =
                    axiosthis.loader = false
                    data = response.data['full_stat']
                    axiosthis.itemperpage = data.length
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].CR_AMT == 0 && data[i].DR_AMT == 0) {
                            //console.log("itsempty")
                        } else {
                            //console.log("itsemptyno");
                            var dada = data[i]
                            axiosthis.desserts.push(dada)
                        }

                    }
                    for (let v = 0; v < axiosthis.desserts.length; v++) {
                        let dateString = axiosthis.desserts[v].VOUCHERDATE
                        if (dateString !== "") {
                            let dateObject = new Date(dateString);
                            if (!isNaN(dateObject.getTime())) {
                                let day = dateObject.getDate();
                                let month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
                                let year = dateObject.getFullYear();
                                // console.log('date',day,'month',month,'year',year,axiosthis.desserts[v].VOUCHERDATE,axiosthis.desserts[v].DR_AMT);
                                let formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
                                axiosthis.desserts[v].VOUCHERDATE = formattedDate;
                                // console.log(axiosthis.desserts[v].VOUCHERDATE);

                            } else {
                                // You might want to log an error or handle it in an appropriate way
                            }
                            // console.log(axiosthis.desserts, "sdsd");

                        }

                    }
                    // console.log(axiosthis.desserts, "axiosthis.desserts");
                    for (let i = 0; i < axiosthis.desserts.length; i++) {
                        if (axiosthis.desserts[i]['SETL_PAYINDATE'] != '') {
                            let dateString = axiosthis.desserts[i]['SETL_PAYINDATE']
                            let dateObject = new Date(dateString);
                            let year = dateObject.getFullYear();
                            let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                            let day = dateObject.getDate();
                            let formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
                            axiosthis.desserts[i]['SETL_PAYINDATE'] = formattedDate
                        }
                    }
                    for (let u = 0; u < axiosthis.desserts.length; u++) {
                        if (axiosthis.desserts[u].VOUCHERDATE !== '') {
                            const sortedData = axiosthis.desserts.slice().sort((a, b) => {
                                const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                                const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                                return dateA - dateB;
                            });
                            const sortedDataREVERSE = axiosthis.desserts.slice().sort((a, b) => {
                                const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                                const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                                return dateB - dateA;
                            });
                            axiosthis.dessertsforfilter = sortedData;
                            axiosthis.desserts = sortedData;
                            axiosthis.pdffile = sortedDataREVERSE
                        }
                    }
                    //         const tags = Array.from(new Set(this.desserts.flatMap(item => item.TYPE)));
                    // axiosthis.filterr = ['All', ...tags];
                    //     for (let e = 0; e < axiosthis.pdffile.length; e++) {
                    //     if (axiosthis.pdffile[e].TYPE === 'Opening Balance') {
                    //         // If the condition is satisfied, remove the object from the array
                    //         console.log("true");
                    //         const removedItem = axiosthis.pdffile.splice(e, 1);
                    //         // Push the removed object to the last position
                    //         axiosthis.pdffile.push(removedItem[0]);
                    //         // Decrement e to avoid skipping the next element
                    //         // e--;
                    //     }
                    // }
                    //     console.log(axiosthis.pdffile, "gggg");
                    // for (let e = 0; e < axiosthis.desserts.length; e++) {
                    //     if (axiosthis.desserts[e].TYPE === 'Opening Balance') {
                    //         // If the condition is satisfied, remove the object from the array
                    //         console.log("true");
                    //         const removedItem = axiosthis.desserts.splice(e, 1);
                    //         // Push the removed object to the last position
                    //         axiosthis.desserts.push(removedItem[0]);
                    //         // Decrement e to avoid skipping the next element
                    //         // e--;
                    //     }
                    // }
                    // Assuming axiosthis is an object with a desserts property which is an array
                    // Also assuming axiosthis.desserts[e].axiosthis.desserts should be axiosthis.desserts[e].dessert


                    // Now, axiosthis.desserts array has the 'Opening Balance' objects moved to the last positions

                    // this.scrollToBottom()
                })
                .catch((error) => {

                    console.log(error);
                    if (error.message == 'Network Error') {
                        this.msg = error.message
                        axiosthis.loader = false
                        axiosthis.alert = true
                        setTimeout(() => {
                            axiosthis.alert = false
                        }, 5000);
                        // alert(error.message)
                    }
                });
            // const axios = require('axios');

            // this.equitiesdata()
            // this.derivativesdat()

            // }
        },
        performDefaultSort() {
            this.sortItems();
        },
        sortItems() {
            this.desserts.sort((a, b) => {
                const modifier = this.sortOrder;
                return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
            });
        },
        convertImageToBase64() {

            fetch(imagePath)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.imageBase64 = reader.result;
                    };
                    reader.readAsDataURL(blob);
                })
                .catch((error) => {
                    console.error('Error loading local image:', error);
                });
        },
        valdwl(item) {
            let data = item
            // console.log("dsdsdsdsds", data);
            this.sheet = false
            if (data === "Download Excel") {
  if (!this.imageBase64) {
    alert('Excel Download error: Missing image data.');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('My Sheet');

  // Define header rows
  const headerRows = [
    ["","Ledger","","Client ID", this.actid],
    ["","","","Client Name", this.cname],
    ["","", "","Client PAN", this.profiledetails['PAN_NO']],
    ["","", "","Client email", this.profiledetails['CLIENT_ID_MAIL']],
    ["","", "","Year", this.yearis],
    [],
    ["Opening Balance","Total Debit",  "Total Credit","Closing Balance"],
    [this.showup.opening_balance, this.showup.dr_amt,  this.showup.cr_amt,  this.showup.closing_balance],
    [],
    ["Date", "Exchange", "Details", "Debit", "Credit", "Net Amount"]
  ];

  // Prepare rows data
  const rows = this.sortedItemss.map(element => [
    element.VOUCHERDATE || "",
    element.COCD || "",
    element.NARRATION || "",
    element.DR_AMT || 0,
    element.CR_AMT || 0,
    element.NET_AMT || 0,
  ]);

  // Combine headerRows with data rows
  const allRows = headerRows.concat(rows);
  const footerRows = [
    [],
    ["Zebu Share And Wealth Management Private Limited"],
    ["Correspondence Office: Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42"],
    ["Phone No: 044-4855 7991, Fax: 044-4855 7991"],
    ["TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300"],
    ["CIN NO: U67120TZ2013PTC019704 Website Address: www.zebuetrade.com, Investor Grievances Email id: grievance@zebuetrade.com"]
  ];

  allRows.push(...footerRows);

  // Add rows to the worksheet
  allRows.forEach((row) => {
    const excelRow = worksheet.addRow(row);

    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);

      if (cell === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }

      // Apply font and fill styles based on cell content
      if (['ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Ledger'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 14, bold: true };
      } else if (['Date', 'Exchange', 'Details', 'Net Amount', 'Bill No', 'Debit', 'Credit'].includes(cell)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      } else if (['Opening Balance', 'Total Debit', 'Total Credit', 'Closing Balance'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 11, bold: true };
      }

      // Align all cells to the left
      cellRef.alignment = { horizontal: 'left' };
    });
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  // Add the image to the first cell
  const imageId = workbook.addImage({
    base64: this.imageBase64,
    extension: 'svg'
  });

  worksheet.getRow(1).height = 32.53;
  worksheet.getColumn(1).width = 20;

  worksheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });

  // Write the Excel file to a buffer
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Ledger_${this.yearis}.xlsx`;
    link.click();
  }).catch(error => console.error('Error generating Excel file:', error));
}

            else if (data == 'Download Pdf') {
                let rows = [];
                let rowsexpense = [];
                // let headerexpense = [];
                let columnHeader = ['Date', 'Exchange', 'Details', 'Debit', 'Credit', 'Net Amount'];

                let pdfName = `${'Ledger'}${this.yearis}`;
                // for (let i = 0; i < this.dessertsexpense.length; i++) {
                //     var temp3 = 
                //     this.dessertsexpense[i].NET_AMOUNT 

                //     rowsexpense.push(temp3)

                // }
                // this.dessertsexpense.forEach(element => {
                //     var temp3 = [
                //         element.NET_AMOUNT
                //     ]
                //     rowsexpense.push(temp3)
                // });
                console.log(rowsexpense);
                this.sortedItemss.forEach(element => {
                    var temp = [
                        element.VOUCHERDATE, element.COCD, element.NARRATION, element.DR_AMT, element.CR_AMT, element.NET_AMT
                    ];
                    rows.push(temp);

                });
                // console.log(headerexpense);
                // console.log(rows);
                // Create a new jsPDF document
                var doc = new jsPDF();
                // Add header and customize text
                //doc.setFont('Tenon');
                // doc.setFontSize(12);
                // doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
                // doc.setFontSize(6);
                // doc.text("Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42", 38, 13);
                // doc.setFontSize(6);
                // doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
                // doc.setFontSize(6);
                // doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
                // doc.setFontSize(6);
                // doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
                doc.setFontSize(7);
                doc.setFont('bold');
                doc.text(`${'Client Name   :  '}${this.cname}`, 15, 31);
                doc.setFontSize(7);
                doc.text(`${'Client Code    :  '}${this.actid}`, 15, 35);
                doc.setFontSize(7);
                doc.text(`${'PAN               :  '}${this.profiledetails['PAN_NO']}`, 15, 39);
                doc.setFontSize(7);
                doc.text(`${'Email Id         :  '}${this.profiledetails['CLIENT_ID_MAIL']}`, 15, 43);
                doc.setFontSize(7);
                doc.text(`${'From     :  '}${this.fromshow.split("_to_")[0]}`, 133, 29);
                doc.setFontSize(7);
                doc.text(`${'To         :  '}${this.fromshow.split("_to_")[1]}`, 133, 33);
                doc.setFontSize(7);
                doc.text(`${'Mobile  :  '}${this.profiledetails['MOBILE_NO']}`, 133, 37);
                doc.setFontSize(7);
                doc.text(`${'Address :  '}${this.profiledetails['CL_RESI_ADD1']}`, 133, 41);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD2']}`, 144, 44);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD3']}`, 144, 47);
                doc.setFontSize(14);
                doc.text('Ledger', 90, 14.5);
                const dividerY = 23; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(0, dividerY, 218, dividerY);
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                // doc.text('Ledger', 100, 33);
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                // doc.setLineWidth(0.2); // Set line width
                // doc.setDrawColor(0); // Set line color to black
                // doc.line(0, dividerYy, 218, dividerYy); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                const rectangleX = 15; // X-coordinate of the top-left corner of the rectangle
                const rectangleY = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth = 40; // Width of the rectangle
                const rectangleHeight = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX, rectangleY, rectangleWidth, rectangleHeight); // Draw rectangle border

                // Add text inside the rectangle

                const textInsideRectangle = 'Opening Balance';
                const textInsideRectangleX = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY = rectangleY + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle, textInsideRectangleX, textInsideRectangleY);
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(18, 60, 25, 60);



                // Set font size for the table
                const rectangleX2 = 60; // X-coordinate of the top-left corner of the rectangle
                const rectangleY2 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth2 = 40; // Width of the rectangle
                const rectangleHeight2 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX2, rectangleY2, rectangleWidth2, rectangleHeight2); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle2 = 'Total Debit';
                const textInsideRectangleX2 = rectangleX2 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY2 = rectangleY2 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle2, textInsideRectangleX2, textInsideRectangleY2);

                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(63, 60, 70, 60);
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(108, 60, 115, 60);

                const rectangleX3 = 105; // X-coordinate of the top-left corner of the rectangle
                const rectangleY3 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth3 = 40; // Width of the rectangle
                const rectangleHeight3 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX3, rectangleY3, rectangleWidth3, rectangleHeight3); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle3 = 'Total Credit';
                const textInsideRectangleX3 = rectangleX3 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY3 = rectangleY3 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle3, textInsideRectangleX3, textInsideRectangleY3);
                const rectangleX4 = 150; // X-coordinate of the top-left corner of the rectangle
                const rectangleY4 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth4 = 40; // Width of the rectangle
                const rectangleHeight4 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX4, rectangleY4, rectangleWidth4, rectangleHeight4); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle4 = 'Closing Balance';
                const textInsideRectangleX4 = rectangleX4 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY4 = rectangleY4 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle4, textInsideRectangleX4, textInsideRectangleY4);

                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(153, 60, 160, 60);
                // doc.setLineWidth(0.2); // Set line width
                // doc.setDrawColor(0); // Set line color to black
                // doc.line(108, 60, 115, 60); 
                // Add table using autoTable
                let textColor;
                if (this.showup.opening_balance < 0) {
                    textColor = [255, 0, 0]; // Red for negative values
                } else if (this.showup.opening_balance > 0) {
                    textColor = [0, 128, 0]; // Green for positive values
                } else {
                    textColor = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleval1 = (this.showup.opening_balance).toFixed(2);
                const textInsideRectangleval1x = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval1Y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor[0], textColor[1], textColor[2]);
                doc.text(textInsideRectangleval1, textInsideRectangleval1x, textInsideRectangleval1Y);

                let textColor1;
                // if (this.disunrealised_pnl < 0) {
                textColor1 = [255, 0, 0]; // Red for negative values
                // } else if (this.disunrealised_pnl > 0) {
                // textColor1 = [0, 128, 0]; // Green for positive values
                // } else {
                // textColor1 = [0, 0, 0]; // Black for zero
                // }
                const textInsideRectangleval2 = (this.showup.dr_amt).toFixed(2);
                const textInsideRectangleval2x = rectangleX + 48; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval2y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor1[0], textColor1[1], textColor1[2]);
                doc.text(textInsideRectangleval2, textInsideRectangleval2x, textInsideRectangleval2y);

                let textColor2;
                // if (this.segval < 0) {
                //     textColor2 = [255, 0, 0]; // Red for negative values
                // } else if (this.segval > 0) {
                textColor2 = [0, 128, 0]; // Green for positive values
                // } else {
                //     textColor2 = [0, 0, 0]; // Black for zero
                // }
                const textInsideRectangleval3 = (this.showup.cr_amt).toFixed(2);
                const textInsideRectangleval3x = rectangleX + 93; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval3y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor2[0], textColor2[1], textColor2[2]);
                doc.text(textInsideRectangleval3, textInsideRectangleval3x, textInsideRectangleval3y);

                let textColor3;
                if (this.showup.closing_balance < 0) {
                    textColor3 = [255, 0, 0]; // Red for negative values
                } else if (this.showup.closing_balance > 0) {
                    textColor3 = [0, 128, 0]; // Green for positive values
                } else {
                    textColor3 = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleval4 = (this.showup.closing_balance).toFixed(2);
                const textInsideRectangleval4x = rectangleX + 138; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval4y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor3[0], textColor3[1], textColor3[2]);
                doc.text(textInsideRectangleval4, textInsideRectangleval4x, textInsideRectangleval4y);

                doc.setFontSize(8);
                // //doc.setFont('Tenon');

                const columnStyles = {
                    0: { fontStyle: 'bold', textColor: [0, 0, 0], halign: 'left' },
                    1: { halign: 'right' },
                    2: { halign: 'left' },
                    3: { halign: 'left' },
                    4: { halign: 'right' },
                    5: { halign: 'right' },
                    6: { halign: 'right' },
                    7: { halign: 'right' },
                    8: { halign: 'right' }
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
                // const columnStyles2 = {
                //     0: { fontStyle: 'bold',textColor: [0, 0, 0],align: 'right'},
                //     1 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     2 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     3 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     4 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     5:  {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     6 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     7 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     8 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'}
                //     // 1: {} // Index 0 corresponds to the first column ('Symbol')
                // };
                autoTable(doc, {
                    head: [columnHeader], body: rows, startY: 79, styles: {

                        fontSize: 7,
                        // font: 'Tenon',
                        lineColor: [200, 200, 200],
                        // textColor: [0,0,0],
                    }, theme: 'grid', // Use the 'grid' theme
                    headStyles: {
                        fillColor: [31, 52, 101], // RGB color for dark blue header background
                        textColor: [255, 255, 255], // White text color for the header
                    },
                    columnStyles: columnStyles,
                    table: {
                        // fillColor: [31, 52, 101]
                        // fillColor: tableBackgroundColor,
                    },
                });
                //                         if (doc.internal.pages.length > 1) {
                //                             doc.addPage();
                //                             doc.autoTable.previous.finalY = 10

                // } else {
                //     doc.autoTable.previous.finalY += 20; // Add some spacing between tables
                // }
                // doc.text('End table', 10, doc.autoTable.previous.finalY + 10);
                // doc.setFontSize(12);
                // doc.setTextColor(0, 0, 0);
                // doc.text('Charges & taxes', 15, doc.autoTable.previous.finalY + 10);
                // autoTable(doc, {
                //     head: [headerexpense],
                //     body: [rowsexpense],
                //     startY: doc.autoTable.previous.finalY + 20, // Adjust the startY position as needed
                //     styles: {
                //         fontSize: 7,
                //         // font: 'Tenon',
                //         // textColor: [200, 200, 200], // Set font color to black
                //     },theme: 'grid',
                //     headStyles: {
                //     fillColor: [31, 52, 101], // RGB color for dark blue header background
                //     textColor: [255, 255, 255], // White text color for the header
                // },
                // columnStyles: columnStyles2,

                //     table: {},
                // });
                // Add custom text
                // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                // Save the PDF
                var totalPages = doc.internal.getNumberOfPages();
                for (var i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    const dividerY = doc.internal.pageSize.height - 13; // Adjust the Y-coordinate based on your layout
                    doc.setFontSize(6);
                    doc.setTextColor(0);
                    doc.text(14, doc.internal.pageSize.height - 9, 'Zebu Share And Wealth Management Private Limited');
                    doc.setFontSize(4);
                    doc.setTextColor(0);
                    doc.text(14, doc.internal.pageSize.height - 7, 'Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42');
                    doc.setFontSize(4);
                    doc.setTextColor(0);
                    doc.text(84, doc.internal.pageSize.height - 7, 'Phone No:044-4855 7991, Fax :044-4855 7991');
                    doc.setFontSize(4);
                    doc.setTextColor(0);
                    doc.text(14, doc.internal.pageSize.height - 5, 'TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704');
                    doc.setFontSize(4);
                    doc.setTextColor(0);
                    doc.text(109, doc.internal.pageSize.height - 5, 'Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com');
                    doc.setLineWidth(0.2); // Set line width 
                    doc.setDrawColor(0); // Set line color to black 
                    doc.line(0, dividerY, 218, dividerY);
                }
                doc.save(pdfName + '.pdf');
            }



            //pdf


        },
        closepop() {
            this.dialog = false
        },
        lessbtn() {
            this.more = true
            this.less = false
            this.itemperpage = 8
        },
        morebtn() {
            this.more = false
            this.less = true
            this.itemperpage = this.desserts.length + 1
        },
        popup1(item) {
            // console.log(item);
            this.loader = true
            this.dialog1 = true
            this.finaltotal = ''
            this.totalnet = ''
            this.desserts5 = []
            this.dessertsexpense = []
            this.desserts3 = [
                {
                    name: 'Brokerage',
                    val: '-',
                },
                {
                    name: 'GST',
                    val: '-'
                },
                {
                    name: 'STT',
                    val: '-'
                },
                {
                    name: ' CM Charges ',
                    val: '-'
                },
                {
                    name: 'SEBI',
                    val: '-'
                },
                {
                    name: 'TOT',
                    val: '-'
                },

                {
                    name: 'Stampduty',
                    val: '-'
                },
            ];


            let dateString = item.VOUCHERDATE;
            let dada = dateString.split(' ')
            // console.log(dada[0], "dada");
            let formattedDateString = dada[0]

            const axios = require('axios');
            let data = JSON.stringify({
                // "cc": this.actid,
                // "from": item.VOUCHERDATE,
                // "to": item.VOUCHERDATE,
                // "segment": item.COCD

                "settlementno": item.SETTLEMENT_NO,
                "markettype": item.MKT_TYPE,
                "companycode": item.COCD,
                "cc": item.ACCOUNTCODE,
                "tradedate": formattedDateString
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: `{apiurl} /getBillbyDate`,
                url: `${apiurl}/getbillsummary`,
                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this

            axios.request(config)
                .then((response) => {

                    axiosthis.desserts5 = response.data['Transactions']
                    axiosthis.dessertsexpense = response.data['Expenses']
                    // console.log(axiosthis.desserts6, "axiosthis.desserts5axiosthis.desserts5axiosthis.desserts5");

                    if (response.data.msg != 'No Data Available') {

                        axiosthis.loaderpop = false
                        axiosthis.loader = false
                        let data1 = response.data['Transactions']


                        let data = response.data['Expenses']

                        //  for (let i = 0; i < data1.length; i++) {
                        //     data.push(data1[i])
                        //  }
                        // axiosthis.desserts6 = data1

                        // for (let i = 0; i < data.length; i++) {
                        //     display.push(data[i])
                        // }
                        // axiosthis.desserts6 = data1
                        // axiosthis.desserts3[5].val = axiosthis.desserts6[i].NET_AMOUNT !== undefined
                        //     ? axiosthis.desserts6[i].NET_AMOUNT
                        //     : CLOSINGAMT;

                        // for (let i = 0; i < axiosthis.desserts6.length; i++) {
                        //     if (axiosthis.desserts6[i].SCRIP_NAME == "BRK") {
                        //         axiosthis.desserts3[0].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;

                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "TOT" || axiosthis.desserts6[i].SCRIP_NAME1 == "TOT NSE Exchange" || axiosthis.desserts6[i].SCRIP_NAME1 == "TOT") {
                        //         axiosthis.desserts3[5].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;
                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "CMCHARGES") {
                        //         axiosthis.desserts3[3].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;
                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "SEBITOC") {
                        //         axiosthis.desserts3[4].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;


                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "STT") {
                        //         axiosthis.desserts3[2].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;


                        //     }
                        //     if (axiosthis.desserts6[i].SCRIP_NAME == "STAMP DUTY") {
                        //         axiosthis.desserts3[6].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;


                        //     }
                        //     if (axiosthis.desserts6[i].SCRIP_NAME == "CGST") {

                        //         for (let ii = 0; ii < axiosthis.desserts6.length; ii++) {
                        //             if (axiosthis.desserts6[ii].SCRIP_NAME == "SGST") {
                        //                 axiosthis.desserts3[1].val = axiosthis.desserts6[i].NET_AMOUNT + axiosthis.desserts6[ii].NET_AMOUNT
                        //             }
                        //             else if (axiosthis.desserts6[ii].SCRIP_NAME == "CGST") {
                        //                 axiosthis.desserts3[1].val = axiosthis.desserts6[i].NET_AMOUNT
                        //             }
                        //         }
                        //     }
                        // }axiosthis.desserts6
                        // console.log(data, "fdfdfdfdfd");
                        axiosthis.totalnet = 0.00
                        for (let i = 0; i < data.length; i++) {
                            // console.log(data[i].NETAMT, "fdfdfdfdfdbefore");
                            if (data[i].NETAMT) {
                                axiosthis.totalnet += data[i].NETAMT
                            }
                            // console.log(axiosthis.totalnet,"fdfdfdfdfd");
                        }
                        axiosthis.totalnetadd = 0.00
                        for (let i = 0; i < data1.length; i++) {
                            // console.log(data1[i].NETAMT, "fdfdfdfdfdbefore");
                            if (data1[i].NETAMT) {
                                axiosthis.totalnetadd += data1[i].NETAMT
                            }
                            // console.log(axiosthis.totalnet,"fdfdfdfdfd");
                        }
                        let sum = axiosthis.totalnetadd + axiosthis.totalnet
                        axiosthis.totalnet = sum
                        // console.log(axiosthis.totalnet, "kjkjkjkj");
                        // console.log(axiosthis.total,"axiosthis.total");

                    } else {
                        axiosthis.loaderpop = false
                        axiosthis.loader = false
                    }

                })
                .catch((error) => {
                    console.log(error);
                    if (error.message == 'Network Error') {
                        this.msg = error.message
                        axiosthis.loader = false
                        axiosthis.alert = true
                        setTimeout(() => {
                            axiosthis.alert = false
                        }, 5000);
                        // alert(error.message)
                    }
                });
        },
        popup(item) {
            // console.log(item);
            this.finaltotal = ''
            this.totalnet = ''

            this.loaderpop = true
            this.dialog = true
            this.desserts5 = []
            this.desserts6 = []
            // this.desserts3 = [
            //     {
            //         name: 'Brokerage',
            //         val: '-',
            //     },
            //     {
            //         name: 'GST',
            //         val: '-'
            //     },
            //     {
            //         name: 'STT',
            //         val: '-'
            //     },
            //     {
            //         name: ' CM Charges ',
            //         val: '-'
            //     },
            //     {
            //         name: 'SEBI',
            //         val: '-'
            //     },
            //     {
            //         name: 'TOT',
            //         val: '-'
            //     },

            //     {
            //         name: 'Stampduty',
            //         val: '-'
            //     },


            // ];
            let dateString = item.VOUCHERDATE;
            let dada = dateString.split(' ')
            // console.log(dada[0], "dada");
            let formattedDateString = dada[0]

            const axios = require('axios');
            let data = JSON.stringify({
                // "cc": this.actid,
                // "from": item.VOUCHERDATE,
                // "to": item.VOUCHERDATE,
                // "segment": item.COCD

                "settlementno": item.SETTLEMENT_NO,
                "markettype": item.MKT_TYPE,
                "companycode": item.COCD,
                "cc": item.ACCOUNTCODE,
                "tradedate": formattedDateString
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: `{apiurl} /getBillbyDate`,
                url: `${apiurl}/getbillsummary`,
                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this

            axios.request(config)
                .then((response) => {

                    axiosthis.desserts5 = response.data['Transactions']
                    axiosthis.dessertsexpense = response.data['Expenses']
                    // console.log(axiosthis.desserts6, "axiosthis.desserts5axiosthis.desserts5axiosthis.desserts5");

                    if (response.data.msg != 'No Data Available') {
                        axiosthis.loaderpop = false
                        let data1 = response.data['Transactions']


                        let data = response.data['Expenses']

                        //  for (let i = 0; i < data1.length; i++) {
                        //     data.push(data1[i])
                        //  }
                        // axiosthis.desserts6 = data1

                        // for (let i = 0; i < data.length; i++) {
                        //     display.push(data[i])
                        // }
                        // axiosthis.desserts6 = data1
                        // axiosthis.desserts3[5].val = axiosthis.desserts6[i].NET_AMOUNT !== undefined
                        //     ? axiosthis.desserts6[i].NET_AMOUNT
                        //     : CLOSINGAMT;

                        // for (let i = 0; i < axiosthis.desserts6.length; i++) {
                        //     if (axiosthis.desserts6[i].SCRIP_NAME == "BRK") {
                        //         axiosthis.desserts3[0].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;

                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "TOT" || axiosthis.desserts6[i].SCRIP_NAME1 == "TOT NSE Exchange" || axiosthis.desserts6[i].SCRIP_NAME1 == "TOT") {
                        //         axiosthis.desserts3[5].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;
                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "CMCHARGES") {
                        //         axiosthis.desserts3[3].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;
                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "SEBITOC") {
                        //         axiosthis.desserts3[4].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;


                        //     } if (axiosthis.desserts6[i].SCRIP_NAME == "STT") {
                        //         axiosthis.desserts3[2].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;


                        //     }
                        //     if (axiosthis.desserts6[i].SCRIP_NAME == "STAMP DUTY") {
                        //         axiosthis.desserts3[6].val = axiosthis.desserts6[i].NET_AMOUNT ? axiosthis.desserts6[i].NET_AMOUNT : CLOSINGAMT;


                        //     }
                        //     if (axiosthis.desserts6[i].SCRIP_NAME == "CGST") {

                        //         for (let ii = 0; ii < axiosthis.desserts6.length; ii++) {
                        //             if (axiosthis.desserts6[ii].SCRIP_NAME == "SGST") {
                        //                 axiosthis.desserts3[1].val = axiosthis.desserts6[i].NET_AMOUNT + axiosthis.desserts6[ii].NET_AMOUNT
                        //             }
                        //             else if (axiosthis.desserts6[ii].SCRIP_NAME == "CGST") {
                        //                 axiosthis.desserts3[1].val = axiosthis.desserts6[i].NET_AMOUNT
                        //             }
                        //         }
                        //     }
                        // }axiosthis.desserts6
                        // console.log(data, "fdfdfdfdfd");
                        axiosthis.totalnet = 0.00
                        for (let i = 0; i < data.length; i++) {
                            // console.log(data[i].NETAMT, "fdfdfdfdfdbefore");
                            if (data[i].NETAMT) {
                                axiosthis.totalnet += data[i].NETAMT
                            }
                            // console.log(axiosthis.totalnet,"fdfdfdfdfd");
                        }
                        axiosthis.totalnetadd = 0.00
                        for (let i = 0; i < data1.length; i++) {
                            // console.log(data1[i].NETAMT, "fdfdfdfdfdbefore");
                            if (data1[i].NETAMT) {
                                axiosthis.totalnetadd += data1[i].NETAMT
                            }
                            // console.log(axiosthis.totalnet,"fdfdfdfdfd");
                        }

                        let sum = axiosthis.totalnetadd + axiosthis.totalnet
                        axiosthis.totalnet = sum
                        // console.log(axiosthis.totalnet, "kjkjkjkj");
                        // console.log(axiosthis.total,"axiosthis.total");

                    } else {
                        axiosthis.loaderpop = false
                        axiosthis.desserts5 = []
                    }

                })
                .catch((error) => {
                    console.log(error);
                    if (error.message == 'Network Error') {
                        this.msg = error.message
                        axiosthis.loader = false
                        axiosthis.alert = true
                        setTimeout(() => {
                            axiosthis.alert = false
                        }, 5000);
                        // alert(error.message)
                    }
                });
        },
        getledger(yearis) {
            // this.fromshow = ''
            this.yearpop = false
            this.menu2 = false
            this.menu23 = false
            this.desserts = []
            this.loader = true
            var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            if (mm < 4 && yyyy == yearis) {
                var yearmount = yearis - 1
            } else {
                yearmount = yearis
            }
            // var year = yearis

            let yearnext = yearmount + 1
            this.todate = yearnext + '-' + '03' + '-' + '31'
            this.fromdate = yearmount + '-' + '04' + '-' + '01'
            this.fromshow = '01/04' + '/' + yearmount + '_to_' + '31/03' + '/' + yearnext
            let data = JSON.stringify({
                "cc": this.actid,
                "from": '01/04' + '/' + yearmount,
                "to": '31/03' + '/' + yearnext,
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: api.apiurl + '/getLedger',
                url: `${apiurl}/getLedger`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    var data = []
                    axiosthis.filterdata_unpl = "All",

                        axiosthis.showup = response.data
                    axiosthis.filteropenbal = response.data.opening_balance

                    axiosthis.filterclosebal = response.data.closing_balance
                    if (axiosthis.showup.msg == 'No Data Available') {
                        axiosthis.loader = false
                    }
                    // axiosthis.itemperpage =
                    axiosthis.loader = false
                    data = response.data['full_stat']
                    axiosthis.itemperpage = data.length
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].CR_AMT == 0 && data[i].DR_AMT == 0) {
                            //console.log("itsempty")
                        } else {
                            //console.log("itsemptyno");
                            var dada = data[i]
                            axiosthis.desserts.push(dada)
                        }

                    }
                    for (let v = 0; v < axiosthis.desserts.length; v++) {
                        let dateString = axiosthis.desserts[v].VOUCHERDATE
                        if (dateString !== "") {
                            let dateObject = new Date(dateString);
                            if (!isNaN(dateObject.getTime())) {
                                let day = dateObject.getDate();
                                let month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
                                let year = dateObject.getFullYear();
                                // console.log('date',day,'month',month,'year',year,axiosthis.desserts[v].VOUCHERDATE,axiosthis.desserts[v].DR_AMT);
                                let formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
                                axiosthis.desserts[v].VOUCHERDATE = formattedDate;
                                // console.log(axiosthis.desserts[v].VOUCHERDATE);

                            } else {
                                // You might want to log an error or handle it in an appropriate way
                            }
                            // console.log(axiosthis.desserts, "sdsd");

                        }

                    }
                    // console.log(axiosthis.desserts, "axiosthis.desserts");
                    for (let i = 0; i < axiosthis.desserts.length; i++) {
                        if (axiosthis.desserts[i]['SETL_PAYINDATE'] != '') {
                            let dateString = axiosthis.desserts[i]['SETL_PAYINDATE']
                            let dateObject = new Date(dateString);
                            let year = dateObject.getFullYear();
                            let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                            let day = dateObject.getDate();
                            let formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
                            axiosthis.desserts[i]['SETL_PAYINDATE'] = formattedDate
                        }
                    }
                    for (let u = 0; u < axiosthis.desserts.length; u++) {
                        if (axiosthis.desserts[u].VOUCHERDATE !== '') {
                            const sortedData = axiosthis.desserts.slice().sort((a, b) => {
                                const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                                const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                                return dateA - dateB;
                            });
                            const sortedDataREVERSE = axiosthis.desserts.slice().sort((a, b) => {
                                const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                                const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                                return dateB - dateA;
                            });
                            axiosthis.dessertsforfilter = sortedData;
                            axiosthis.desserts = sortedData;
                            axiosthis.pdffile = sortedDataREVERSE
                        }
                    }
                    //     const tags = Array.from(new Set(this.desserts.flatMap(item => item.TYPE)));
                    // axiosthis.filterr = ['All', ...tags];
                    //     for (let e = 0; e < axiosthis.pdffile.length; e++) {
                    //     if (axiosthis.pdffile[e].TYPE === 'Opening Balance') {
                    //         // If the condition is satisfied, remove the object from the array
                    //         console.log("true");
                    //         const removedItem = axiosthis.pdffile.splice(e, 1);
                    //         // Push the removed object to the last position
                    //         axiosthis.pdffile.push(removedItem[0]);
                    //         // Decrement e to avoid skipping the next element
                    //         // e--;
                    //     }
                    // }
                    //     console.log(axiosthis.pdffile, "gggg");
                    // for (let e = 0; e < axiosthis.desserts.length; e++) {
                    //     if (axiosthis.desserts[e].TYPE === 'Opening Balance') {
                    //         // If the condition is satisfied, remove the object from the array
                    //         console.log("true");
                    //         const removedItem = axiosthis.desserts.splice(e, 1);
                    //         // Push the removed object to the last position
                    //         axiosthis.desserts.push(removedItem[0]);
                    //         // Decrement e to avoid skipping the next element
                    //         // e--;
                    //     }
                    // }
                    // Assuming axiosthis is an object with a desserts property which is an array
                    // Also assuming axiosthis.desserts[e].axiosthis.desserts should be axiosthis.desserts[e].dessert


                    // Now, axiosthis.desserts array has the 'Opening Balance' objects moved to the last positions

                    // this.scrollToBottom()
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message == 'Network Error') {
                        this.msg = error.message
                        axiosthis.loader = false
                        axiosthis.alert = true
                        setTimeout(() => {
                            axiosthis.alert = false
                        }, 5000);
                        // alert(error.message)
                    }
                });
        },
        scrollToBottom() {
            const dataTable = this.$refs.dataTableRef;

            if (dataTable) {
                setTimeout(() => {
                    const container = dataTable.$el.querySelector('.v-data-table__wrapper');
                    container.scrollTop = container.scrollHeight;
                }, 0);
            }
        },

    },
    mounted() {
        this.loader = true
        var today = new Date();
        // var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        // var nextyear = yyyy + 1
        // var startYear = yyyy - 4;
        this.yearis = yyyy
        // for (let year = startYear; year <= yyyy; year++) {
        //   this.items.push(year);
        // }
        // var today = new Date();
        // var yyyy = today.getFullYear();

        if (mm < 4) {
            var yearmount = yyyy - 1
        } else {
            yearmount = yyyy
        }
        var startYear = yearmount - 4;
        this.yearis = yearmount;

        for (let year = yearmount; year >= startYear; year--) {
            this.items.push(year);
        }
        // var todayapi = dd + '/' + mm + '/' + yyyy;
        var fromapi = '01' + '/' + '04' + '/' + yearmount;
        let nextyear = parseInt(yearmount) + 1

        var today1 = '31' + '/' + '03' + '/' + nextyear;
        // this.toshow = today1
        this.fromshow = fromapi + '_to_' + today1
        // this.fromdateapi = fromapi
        this.todate = nextyear + '-' + '03' + '-' + '31'
        this.fromdate = yearmount + '-' + '04' + '-' + '01'
        const axios = require('axios');
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        this.cname = localStorage.getItem("cname");
        var res = this.decryptionFunction(localStorage.getItem("profile_data"));
        // console.log('ttttt',JSON.parse(res));
        var resp = JSON.parse(res);
        this.profiledetails = resp.client_data;
        // let nextyear = parseInt(yearmount) + 1
        let data = JSON.stringify({
            "cc": this.actid,
            "from": fromapi,
            "to": today1,
        });

        let config = {
            method: 'post',
            // url: api.apiurl + '/getLedger',
            url: `${apiurl}/getLedger`,

            headers: {
                'Content-Type': 'application/json',
                'clientid': this.actid,
                'Authorization': this.susertoken
            },
            data: data
        };
        let axiosthis = this
        axios.request(config)
            .then((response) => {
                if (response.data.emsg == 'invalid token') {
                    window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`)
                }
                axiosthis.loader = false
                var data = []
                axiosthis.filterdata_unpl = "All",

                    axiosthis.showup = response.data
                axiosthis.filteropenbal = response.data.opening_balance

                axiosthis.filterclosebal = response.data.closing_balance


                // console.log(response.status);

                // if (response.status == 500) {
                //     axiosthis.loader = false
                //     alert("frds")
                // }
                data = response.data['full_stat']
                axiosthis.itemperpage = data.length
                for (let i = 0; i < data.length; i++) {
                    if (data[i].CR_AMT == 0 && data[i].DR_AMT == 0) {
                        //console.log("itsempty")
                    } else {
                        //console.log(data[i].CR_AMT, data[i].DR_AMT)
                        var dada = data[i]
                        axiosthis.desserts.push(dada)
                    }

                }
                // for (let i = 0; i < axiosthis.desserts.length; i++) {
                //     if (axiosthis.desserts[i]['VOUCHERDATE'] != '') {
                //         let dateString = axiosthis.desserts[i]['VOUCHERDATE']
                //         let dateObject = new Date(dateString);
                //         let year = dateObject.getFullYear();
                //         let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                //         let day = dateObject.getDate();
                //         let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
                //         axiosthis.desserts[i]['VOUCHERDATE'] = formattedDate
                //     }
                // }

                for (let i = 0; i < axiosthis.desserts.length; i++) {
                    if (axiosthis.desserts[i]['SETL_PAYINDATE'] != '') {
                        let dateString = axiosthis.desserts[i]['SETL_PAYINDATE']
                        let dateObject = new Date(dateString);
                        let year = dateObject.getFullYear();
                        let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                        let day = dateObject.getDate();
                        let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
                        axiosthis.desserts[i]['SETL_PAYINDATE'] = formattedDate
                    }
                }
                // for (let z = 0; z < axiosthis.desserts.length; z++) {
                //   let dummydateString = axiosthis.desserts[z].avg_res
                for (let v = 0; v < axiosthis.desserts.length; v++) {
                    let dateString = axiosthis.desserts[v].VOUCHERDATE
                    if (dateString !== "") {
                        let dateObject = new Date(dateString);
                        if (!isNaN(dateObject.getTime())) {
                            let day = dateObject.getDate();
                            let month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
                            let year = dateObject.getFullYear();
                            // console.log('date',day,'month',month,'year',year,axiosthis.desserts[v].VOUCHERDATE,axiosthis.desserts[v].DR_AMT);
                            let formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
                            axiosthis.desserts[v].VOUCHERDATE = formattedDate;
                            // console.log(axiosthis.desserts[v].VOUCHERDATE);

                        } else {
                            // You might want to log an error or handle it in an appropriate way
                        }
                        // console.log(axiosthis.desserts, "sdsd");

                    }

                }
                for (let u = 0; u < axiosthis.desserts.length; u++) {
                    if (axiosthis.desserts[u].VOUCHERDATE !== '') {
                        const sortedData = axiosthis.desserts.slice().sort((a, b) => {
                            const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                            const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                            return dateA - dateB;
                        });
                        const sortedDataREVERSE = axiosthis.desserts.slice().sort((a, b) => {
                            const dateA = new Date(b.VOUCHERDATE.split('/').reverse().join('/'));
                            const dateB = new Date(a.VOUCHERDATE.split('/').reverse().join('/'));
                            return dateB - dateA;
                        });
                        axiosthis.dessertsforfilter = sortedData;
                        axiosthis.desserts = sortedData;
                        axiosthis.pdffile = sortedDataREVERSE
                    }
                }
                // const tags = Array.from(new Set(this.desserts.flatMap(item => item.TYPE)));
                // axiosthis.filterr = ['All', ...tags];
                // console.log(axiosthis.uniqueTags,"kjjkjkj")
                // console.log(axiosthis.pdffile,"ggg");
                // for (let e = 0; e < axiosthis.pdffile.length; e++) {
                //     if (axiosthis.pdffile[e].TYPE === 'Opening Balance') {
                //         // If the condition is satisfied, remove the object from the array
                //         console.log("true");
                //         const removedItem = axiosthis.pdffile.splice(e, 1);
                //         // Push the removed object to the last position
                //         axiosthis.pdffile.push(removedItem[0]);
                //         // Decrement e to avoid skipping the next element
                //         // e--;
                //     }
                // }

                // for (let e = 0; e < axiosthis.desserts.length; e++) {
                //     if (axiosthis.desserts[e].TYPE === 'Opening Balance') {
                //         // If the condition is satisfied, remove the object from the array
                //         console.log("true");
                //         const removedItem = axiosthis.desserts.splice(e, 1);
                //         // Push the removed object to the last position
                //         axiosthis.desserts.push(removedItem[0]);
                //         // Decrement e to avoid skipping the next element
                //         // e--;
                //     }
                // }
                // console.log(axiosthis.desserts,"gg");
                // for (let u = 0; u < axiosthis.desserts.length; u++) {
                //     if (axiosthis.desserts[u].VOUCHERDATE !== '') {
                //         const data = axiosthis.desserts[u];
                //         var sortedData = data.slice().sort((a, b) => {
                //             const dateA = new Date(a.VOUCHERDATE.split('-').reverse().join('-'));
                //             const dateB = new Date(b.VOUCHERDATE.split('-').reverse().join('-'));
                //             return dateA - dateB;
                //         });
                //         axiosthis.desserts[u] = sortedData;
                //     }
                // }
                // console.log("sorted", axiosthis.desserts, "axiosthis.desserts");
                // this.scrollToBottom();
            })
            .catch((error) => {
                console.log(error);
                // console.log(error.message);
                if (error.message == 'Network Error') {
                    this.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                    setTimeout(() => {
                        axiosthis.alert = false
                    }, 5000);
                    // alert(error.message)
                }
            });
        this.convertImageToBase64()


    },

}
</script>

<style lamg="scss">
.toolbar .v-toolbar__content {
    padding: 0 !important;
}

.headerfont {
    font-weight: 500
}

.v-menu__content.theme--light.menuable__content__active {
    border-radius: 20px;
}

.dropdown {
    /* .v-input__slot {
        width: 141px;
    } */
}
</style>