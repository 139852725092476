<template>
  <v-app>
    <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
      <v-alert v-if="alert" dense outlined type="success" style="position: fixed; top: 8; right: 0; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <v-alert v-if="alert1" dense outlined type="error" style="position: fixed; top: 8; right: 0; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <v-alert v-if="alertwrongpassword" dense type="error" style="position: fixed; top: 8; right: 0; z-index: 210">
        <strong>{{ msg }}</strong>
      </v-alert>
      <div class=" pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
        <v-col cols="12" class="pl-7">
          <v-card class="mt-7 elevation-0">
            <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
              <v-list class="" style="background-color: transparent">
                <v-list-item class="px-0 pt-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="text-start" style="
                          color: #000000;
                          font-size: 20px;
                          font-weight: 559;
                          line-height: 19px;
                          letter-spacing: -1px;
                        ">
                      Corporate Action
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-start pt-1" style="
                          color: #666666;
                          font-size: 12px;
                          font-weight: 469;
                          line-height: 14px;
                        "> Driving strategic change through decisive corporate actions.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <!-- <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                  <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
  
                                      <v-list-item-content class="pl-2 pa-0  d-none d-md-block">
                                          <v-list-item-title v-if="downsum" class="text-start"
                                              style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                              {{ dessertsoriginal.length }}
                                              Symbols
                                          </v-list-item-title>
                                          <v-list-item-title v-if="!downsum" class="text-start"
                                              style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                              {{ desserts23.length }}
                                              Symbols
                                          </v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-list> -->
              <!-- <v-menu v-if="downsum && dessertsoriginal.length > 0" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                          <v-icon size="20">mdi mdi-download</v-icon>
                                      </v-btn>
                                  </template>
                                  <v-list>
                                      <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                          <v-list-item-title @click="downloadsum(item.title)">{{ item.title
                                          }}</v-list-item-title>
                                      </v-list-item>
                                  </v-list>
                              </v-menu> -->

              <!-- <v-menu class="" v-model="menu23" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="max-width: 205px" class="mt-4 mr-2" solo rounded flat v-model="fromshow"
                      label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="black" v-model="todate" @change="gettradedata()" :allowed-dates="allowedDates"
                    :max="maxval"></v-date-picker>
                </v-menu> -->

              <!-- dense prepend-inner-icon="mdi-filter-variant" -->
              <!-- <v-select v-if="downsum"  :readonly="segloader" :loading="segloader" :items="filterr" @change="filterseg(filterdata_unpl)" dense
                                  v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                  hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px; overflow: hidden;">
                              </v-select> -->
              <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                  v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                  hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                              </v-select> -->
              <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                      size="20">mdi mdi-content-save</v-icon></v-btn>
                              <v-btn v-if="!downsum" @click="downloaddet()"
                                  class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                      mdi-content-save</v-icon></v-btn> -->
              <!-- <v-select :readonly="segloader" :loading="segloader" :items="filterr" @change="filterseg(filterdata_unpl)"
                  dense v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                  hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px; overflow: hidden">
                </v-select> -->
              <v-text-field style="width: 100px" class="tophundraedmutual d-none d-md-block mt-4 mr-5" height="36px"
                background-color="#F1F3F8" label="Search" v-model="search" solo rounded text flat dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>
            </v-toolbar>

            <!-- <v-btn @click="takeScreenshot">take</v-btn> -->
            <div id="target-element">
              <v-row>
                <v-col cols="8">
                  <v-dialog v-model="cancelconform" persistent max-width="340">

                    <v-card class="text-center">
                      <div class="text-center pt-8 ob-4">
                        <img alt="" class="shrink" src="../../../assets/cancelorder.svg" width="15%" height="" />

                      </div>

                      <p class="title mb-0 font-weight-bold">Are you sure you want to</p>
                      <p class="title mb-0 mt-n3 font-weight-bold">cancel this stock order</p>
                      <v-card-actions>
                        <v-col>
                          <v-btn block @click="cancelconform = false" class="  ma-2  text-capitalize" solo text rounded
                            flat style="background-color: #f1f3f8; color: #666"> No</v-btn>


                        </v-col>
                        <v-col>
                          <v-btn block :loading="btnloader" @click="cancelorder()"
                            class="  ma-2  text-capitalize white--text" solo text rounded flat
                            style="background-color: black;"> Yes</v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-overlay v-if="edisprocessloader" class="rounded-0" style="z-index:2000">
                    <div class="text-center">
                      <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                      <p>EDIS Processing please wait</p>
                    </div>
                  </v-overlay>
                  <v-dialog v-model="dialogmyntedis" width="600" style="height: 100vh ; " hide-overlay
                    transition="dialog-bottom-transition">
                    <v-card>
                      <!-- <v-btn @click="reloadpage()">reloadpage</v-btn> -->
                      <!-- <p>object</p>
       <object :data="`https://go.mynt.in/NorenEdis/NonPoaHoldings/?${codeforedis}`" width="600" height="400"></object>
       <p>embed</p> -->

                      <!-- <embed :src="`https://go.mynt.in/NorenEdis/NonPoaHoldings/?${codeforedis}`" type="text/html" width="600" height="400"> -->
                      <!-- <p>https://go.mynt.in/NorenEdis/NonPoaHoldings/?{{ codeforedis }}</p> -->
                      <!-- <p>iframe</p> -->

                      <iframe :src="`https://go.mynt.in/NorenEdis/NonPoaHoldings/?${codeforedis}`"
                        style="height:88vh ; width : 100%" title="Iframe Example"></iframe>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogmyntpasschange" width="600" style="height: 100vh ; " hide-overlay
                    transition="dialog-bottom-transition">
                    <v-card>
                      <iframe
                        :src="`https://mynt-ssologin.web.app/forgot_password?url=http://localhost:8080/corporateaction&uid=${actid}`"
                        style="height:88vh ; width : 100%" title="Iframe Example"></iframe>

                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialoguorderights" width="570">

<v-card v-if="orderqualitystat == 'true'" ref="form"  class="pb-3">
  <v-list-item-content class=" pt-5 pl-5">
    <v-list-item-title class="text-start"
      style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
        issuetype }}
    </v-list-item-title>
    <!-- <v-list-item-subtitle class="text-start pt-1"
            style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
        </v-list-item-subtitle> -->
    <v-btn absolute right top icon color="black" @click="dialoguorderights = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-list-item-content>
  <v-divider></v-divider>
  <div class="d-flex mx-8 mt-2 ">
    <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
      class="mb-0">{{ syname }} </p>
    <p style="color: #000000;font-size: 12px"
      class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
  </div>
  <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
    {{ biddingStartDate }} to {{ biddingEndDate }}</p>
  <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
  <div class="d-flex mx-8 mt-2 mb-2">
    <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
    <p style="color: #000000;font-size: 12px"
      class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">{{ qtyhave }}</p>
  </div>
  <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

  <div class=" mx-8">
    <!-- <div>
            <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
            v-model="bidvalueuser" style="width: " class="ml-4"
            height="36px"></v-text-field>
          </div>
          <div>
            <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
            v-model="pricevalueuser" style="width: " class="ml-4"
            height="36px"></v-text-field>
          </div> -->
    <v-row class="">
      <v-col cols="4">
        <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
        }}</span></p>
        <v-text-field v-if="issuetype == 'RIGHTS'" @keyup="ordervalcalculaterights()"
           type="number" :rules="[() => !!bidvalueuser || 'This field is required']" :min="minBidQuantity" :max="qtyhave"
          :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
          v-model="bidvalueuser" style="width: " height="36px"></v-text-field>
        <v-text-field :rules="[() => !!bidvalueuser || 'This field is required']" v-else
          @keyup="ordervalcalculate()" type="number" :min="minBidQuantity" :max="qtyhave"
          :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
          v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

      </v-col>
      <v-col cols="5">
        <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
          minPrice }} - ₹{{ maxPrice }}</span></p>
        <v-text-field :rules="[() => !!pricevalueuser || 'This field is required']"
          @change="bidlessmorechn()" @keyup="bidlessmore()" :disabled="pricetextdisabled"
          type="number" :min="minPrice" :max="maxPrice" step="1" label="Price Range" solo rounded flat
          dense background-color="#F1F3F8" v-model="pricevalueuser" style="width: " class="ml-2"
          height="36px"></v-text-field>

      </v-col>
      <v-col cols="3">
        <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2" @change="cutoffadd()"
          v-model="checkbox" label="Cutoff Price"></v-checkbox>
      </v-col>
    </v-row>


    <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
      <v-col>

        <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
        <v-text-field :error-messages="errorMessagesupiid" @blur="validateUpiId" label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
          v-model="upiidtype" style="width: " height="36px"></v-text-field>


      </v-col>
      <!-- <v-col> -->
      <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
            v-model="upiproviderselect" style="width: " class="ml-4"
            height="36px"></v-autocomplete> -->

      <!-- </v-col> -->
    </v-row>
    <v-divider class="mx-2"></v-divider>
    <div class="d-flex">
      <div v-if="issuetype == 'RIGHTS'" class="ma-2">
        <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
        <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
      </div>
      <div class="ml-auto mt-2">
        <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
          @click="dialoguorder = false">Cancel</v-btn> -->
        <v-btn :loading="btnloader" @click="orderapply()" class="  ma-2  text-capitalize white--text"
          solo text rounded flat style="background-color: black;"> Apply</v-btn>
      </div>

    </div>






  </div>

</v-card>
<v-card v-else ref="form"  class="pb-3">
  <v-list-item-content class=" pt-5 pl-5">
    <v-list-item-title class="text-start"
      style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
        issuetype }}
    </v-list-item-title>
    <!-- <v-list-item-subtitle class="text-start pt-1"
            style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
        </v-list-item-subtitle> -->
    <v-btn absolute right top icon color="black" @click="dialoguorderights = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-list-item-content>
  <v-divider></v-divider>
  <div class="d-flex mx-8 mt-2 ">
    <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
      class="mb-0">{{ syname }} </p>
    <p style="color: #000000;font-size: 12px"
      class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
  </div>
  <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
    {{ biddingStartDate }} to {{ biddingEndDate }}</p>
  <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
  <div class="d-flex mx-8 mt-2 mb-2">
    <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
    <p style="color: #000000;font-size: 12px"
      class="mb-0 ml-auto mr-3 mt-1 red--text font-weight-regular caption">Stock not in holdings list</p>
  </div>
  <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

  <div class=" mx-8">
    <!-- <div>
            <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
            v-model="bidvalueuser" style="width: " class="ml-4"
            height="36px"></v-text-field>
          </div>
          <div>
            <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
            v-model="pricevalueuser" style="width: " class="ml-4"
            height="36px"></v-text-field>
          </div> -->
    <v-row class="">
      <v-col cols="4">
        <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
        }}</span></p>
        <v-text-field :disabled="true" v-if="issuetype == 'RIGHTS'" @keyup="ordervalcalculaterights()"
          @change="ordervalcalculatechn()" type="number" :min="minBidQuantity" :max="maxbidqty"
          :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
          v-model="bidvalueuser" style="width: " height="36px"></v-text-field>
        <v-text-field :disabled="true" :rules="[() => !!bidvalueuser || 'This field is required']" v-else
          @keyup="ordervalcalculate()" type="number" :min="minBidQuantity" :max="qtyhave"
          :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
          v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

      </v-col>
      <v-col cols="5">
        <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
          minPrice }} - ₹{{ maxPrice }}</span></p>
        <v-text-field :disabled="true" :rules="[() => !!pricevalueuser || 'This field is required']"
          @change="bidlessmorechn()" @keyup="bidlessmore()" 
          type="number" :min="minPrice" :max="maxPrice" step="1" label="Price Range" solo rounded flat
          dense background-color="#F1F3F8" v-model="pricevalueuser" style="width: " class="ml-2"
          height="36px"></v-text-field>

      </v-col>
      <v-col cols="3">
        <v-checkbox :disabled="true" v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2" @change="cutoffadd()"
          v-model="checkbox" label="Cutoff Price"></v-checkbox>
      </v-col>
    </v-row>


    <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
      <v-col>

        <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
        <v-text-field :disabled="true" label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
          v-model="upiidtype" style="width: " height="36px"></v-text-field>


      </v-col>
      <!-- <v-col> -->
      <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
            v-model="upiproviderselect" style="width: " class="ml-4"
            height="36px"></v-autocomplete> -->

      <!-- </v-col> -->
    </v-row>
    <v-divider class="mx-2"></v-divider>
    <div class="d-flex">
      <div v-if="issuetype == 'RIGHTS'" class="ma-2">
        <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
        <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
      </div>
      <div class="ml-auto mt-2">
        <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
          @click="dialoguorder = false">Cancel</v-btn> -->
          <v-btn :loading="btnloader" :disabled="true" class="ma-2 text-capitalize" outlined rounded
                              color="blaack">Apply</v-btn>
      </div>

    </div>






  </div>

</v-card>
</v-dialog>
                  <v-dialog v-model="dialoguorder" width="570">

                    <v-card ref="form" v-if= " ordertype != 'ofs' && orderqualitystat == 'true' && (ddpi == 'Y' || poa == 'Y')" class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                      </div>
                      <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <div class="d-flex mx-8 mt-2 mb-2">
                        <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">{{ qtyhave }}</p>
                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">
                          <v-col cols="4">
                            <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <v-text-field v-if="issuetype == 'RIGHTS'" @keyup="ordervalcalculaterights()"
                              @change="ordervalcalculatechn()" type="number" :min="minBidQuantity" :max="maxbidqty"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>
                            <v-text-field :rules="[() => !!bidvalueuser || 'This field is required']" v-else
                              @keyup="ordervalcalculate()" type="number" :min="minBidQuantity" :max="qtyhave"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                              minPrice }} - ₹{{ maxPrice }}</span></p>
                            <v-text-field :rules="[() => !!pricevalueuser || 'This field is required']"
                              @change="bidlessmorechn()" @keyup="bidlessmore()" :disabled="pricetextdisabled"
                              type="number" :min="minPrice" :max="maxPrice" step="1" label="Price Range" solo rounded flat
                              dense background-color="#F1F3F8" v-model="pricevalueuser" style="width: " class="ml-2"
                              height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2" @change="cutoffadd()"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                        <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                          <v-col>

                            <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                            <v-text-field label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
                              v-model="upiidtype" style="width: " height="36px"></v-text-field>


                          </v-col>
                          <!-- <v-col> -->
                          <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                          <!-- </v-col> -->
                        </v-row>
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                            <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                            <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->
                            <v-btn :loading="btnloader" @click="orderapply()" class="  ma-2  text-capitalize white--text"
                              solo text rounded flat style="background-color: black;"> Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card>
                    <v-card ref="form"
                      v-else-if="ordertype != 'ofs' && edisapprove == 'true' && orderqualitystat == 'true' && (ddpi == 'N' && poa == 'N')"
                      class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                      </div>
                      <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <div class="d-flex mx-8 mt-2 mb-2">
                        <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held({{
                          qtyhave }})</p>
                        <p v-if="edisbtnorder" style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular red--text caption">Qty Approved by EDIS({{
                            quantityapproveedit }})</p>
                        <p v-else style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">Qty Approved by EDIS({{
                            quantityapproveedit }})</p>
                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">
                          <v-col cols="4">
                            <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <v-text-field v-if="issuetype == 'RIGHTS'" @keyup="ordervalcalculaterights()"
                              @change="editsquantitycheck()" type="number" :min="quantityminedis" :max="quantitymaxedis"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>
                            <v-text-field :rules="[() => !!bidvalueuser || 'This field is required']" v-else
                              @keyup="ordervalcalculateedis()" type="number" :min="quantityminedis" :max="quantitymaxedis"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                              minPrice }} - ₹{{ maxPrice }}</span></p>
                            <v-text-field :rules="[() => !!pricevalueuser || 'This field is required']"
                              @change="bidlessmorechn()" @keyup="bidlessmore()" :disabled="pricetextdisabled"
                              type="number" :min="minPrice" :max="maxPrice" step="1" label="Price Range" solo rounded flat
                              dense background-color="#F1F3F8" v-model="pricevalueuser" style="width: " class="ml-2"
                              height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2" @change="cutoffadd()"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                        <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                          <v-col>

                            <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                            <v-text-field label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
                              v-model="upiidtype" style="width: " height="36px"></v-text-field>


                          </v-col>
                          <!-- <v-col> -->
                          <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                          <!-- </v-col> -->
                        </v-row>
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                            <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                          </div>
                          <div v-if="edisbtnorder" class="mt-6">

                            <p class="mb-0 caption grey--text">Want more need to do EDIS -- ></p>
                          </div>
                          <v-text-field v-if="edisbtnorder" @change="passinput()"
                            :error-messages="errorMessageswrongpassword"
                            :rules="[() => !!tradingpassword || 'This field is required']" :disabled="disabledforblocked"
                            :append-icon="iconforpassword" color="black" :type="textfieldtype" @click:append="iconnn()"
                            label="Password" solo rounded flat dense background-color="#F1F3F8" v-model="tradingpassword"
                            style="width: " class="ml-2 mt-4" height="36px"></v-text-field>
                          <div class="ml-auto mt-2">
                            <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->
                            <v-btn v-if="!edisbtnorder" :loading="btnloader" @click="orderapply()"
                              class="  ma-2  text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> Apply</v-btn>
                            <v-btn v-if="disabledforblocked == true" :loading="edisbtn"
                              @click="dialogmyntpasschange = true" class="ma-2 text-capitalize white--text" solo text
                              rounded flat style="background-color: black;"> Unblock</v-btn>
                            <v-btn v-if="disabledforblocked == false && edisbtnorder == true" :loading="edisbtn"
                              @click="edisredirect()" class="  ma-2  text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> EDIS</v-btn>
                            <v-btn v-else-if="disabledforblocked == true && edisbtnorder == false" :disabled="true"
                              :loading="btnloader" @click="edisredirect()" class="  ma-2  text-capitalize black--text"
                              text outlined rounded color="black"> EDIS</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card>
                    <v-card
                      v-else-if="ordertype != 'ofs' && orderqualitystat == 'true' && edisapprove == 'false' && (ddpi == 'N' && poa == 'N')"
                      class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">EDIS
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;" class="mb-0">For Edis
                          need your trading password </p>
                      </div>
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->
                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">

                          <v-col cols="8">

                            <v-text-field @change="passinput()" :error-messages="errorMessageswrongpassword"
                              :rules="[() => !!tradingpassword || 'This field is required']"
                              :disabled="disabledforblocked" :append-icon="iconforpassword" color="black"
                              :type="textfieldtype" @click:append="iconnn()" label="Password" solo rounded flat dense
                              background-color="#F1F3F8" v-model="tradingpassword" style="width: " class="ml-2 mt-4"
                              height="36px"></v-text-field>
                            <!-- <p v-if="wrongmsgtxt == true" class="caption red--text ml-4 mt-n3">Wrong Password</p> -->
                            <!-- <p v-else-if="passwrongcount > 2" class="caption red--text ml-4 mt-n3">Wrong Password({{passwrongcount}}/3)</p> -->

                            <!-- <p v-else-if="disabledforblocked == true" class="caption red--text ml-4 mt-n3">Account Blocked</p> -->

                          </v-col>
                          <!-- <v-col class="pa-0" cols="12">
                        </v-col> -->
                        </v-row>



                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">

                          <div class="ml-auto mt-2">
                            <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->

                            <v-btn v-if="disabledforblocked == true" :loading="edisbtn"
                              @click="dialogmyntpasschange = true" class="ma-2 text-capitalize white--text" solo text
                              rounded flat style="background-color: black;"> Unblock</v-btn>
                            <v-btn v-if="disabledforblocked == false" :loading="edisbtn" @click="edisredirect()"
                              class="  ma-2  text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> Apply</v-btn>
                            <v-btn v-else :disabled="true" :loading="btnloader" @click="edisredirect()"
                              class="  ma-2  text-capitalize black--text" text outlined rounded color="black">
                              Apply</v-btn>

                          </div>

                        </div>






                      </div>

                    </v-card>
                    <!-- <v-card v-else-if="(orderqualitystat == 'false' && (ddpi == 'Y' || poa == 'Y')) " class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                       
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                      </div>
                      <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                      <div class="d-flex mx-8 mt-2 mb-2">
                        <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption red--text ">Stock not in holdings list
                        </p>
                      </div>

                      <div class=" mx-8">
                        
                        <v-row class="">
                          <v-col cols="3">
                            <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <v-text-field type="number" :disabled="true" :min="minBidQuantity" :max="maxbidqty"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                              minPrice }} - ₹{{ maxPrice }}</span></p>

                            <v-text-field :disabled="true" type="number" :min="minPrice" :max="maxPrice" step="1"
                              label="Bid Qty" solo rounded flat dense background-color="#F1F3F8" v-model="pricevalueuser"
                              style="width: " class="ml-2" height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox :disabled="true" v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                        <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                          <v-col>

                            <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                            <v-text-field :disabled="true" label="UPI ID" solo rounded flat dense
                              background-color="#F1F3F8" v-model="upiidtype" style="width: " height="36px"></v-text-field>


                          </v-col>
                       
                        </v-row>
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                            <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                           

                            <v-btn :loading="btnloader" :disabled="true" class="ma-2 text-capitalize" outlined rounded
                              color="blaack">Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card> -->
                    <!-- <v-card v-else-if="orderqualitystat == 'true' && (ddpi == 'N' && poa == 'N')" class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">E-Dis
                        </v-list-item-title>
                     
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;"
                          class="mb-0">For Edis need your trading password </p>
                      </div>
                    
                      <div class=" mx-8">
                      
                        <v-row class="">

                          <v-col cols="8">

                            <v-text-field @change="passinput()" :error-messages="errorMessageswrongpassword" :rules="[() => !!tradingpassword || 'This field is required']"  :disabled="disabledforblocked" :append-icon="iconforpassword" color="black" :type="textfieldtype"
                              @click:append="iconnn()" label="Password" solo rounded flat dense background-color="#F1F3F8"
                              v-model="tradingpassword" style="width: " class="ml-2 mt-4" height="36px"></v-text-field>
                           
                          </v-col>
                        
                        </v-row>



                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">

                          <div class="ml-auto mt-2">
                           
                              
                            <v-btn v-if="disabledforblocked == true" :loading="edisbtn" @click="dialogmyntpasschange = true"
                              class="ma-2 text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> Unblock</v-btn>
                            <v-btn v-if="disabledforblocked == false" :loading="edisbtn" @click="edisredirect()"
                              class="  ma-2  text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> Apply</v-btn>
                              <v-btn v-else :disabled="true" :loading="btnloader" @click="edisredirect()"
                              class="  ma-2  text-capitalize black--text"  text outlined rounded 
                              color="black"> Apply</v-btn>
                              
                          </div>

                        </div>






                      </div>

                    </v-card> -->
                    <v-card ref="form" v-else-if="orderqualitystat == 'true' && ordertype == 'ofs'" class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ ofscutOffPrice }}</p>
                      </div>
                      <!-- <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p> -->
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <div class="x-8 mt-2 mb-2">
                        <!-- <p styd-flex mle="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">{{ qtyhave }}</p> -->
                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">
                          <v-col cols="4">
                            <p class="mb-1 caption grey--text ">Bid Quantity <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <!-- <v-text-field @keyup="ordervalcalculaterights()"
                              @change="ordervalcalculatechn()" type="number" :min="minBidQuantity" :max="maxbidqty"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field> -->
                            <v-text-field :rules="[() => !!ofsbidvalueuser || 'This field is required']"
                              @change="bidlessmorechnofs()" @keyup="bidlessmorechnofs()" type="number" min=1 
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="ofsbidvalueuser" style="width: " height="36px" class="mt-3"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">Price </p>
                            <v-text-field :rules="[() => !!ofspricevalueuser || 'This field is required']"
                              @change="bidlessmorechnofs()" @keyup="bidlessmorechnofs()" :disabled="pricetextdisabled"
                              type="number" :min="ofscutOffPrice" :max="maxPrice" step="1" label="Price Range" solo rounded flat
                              dense background-color="#F1F3F8" v-model="ofspricevalueuser" style="width: " class="ml-2 mt-3"
                              height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox  color="black" class=" ml-2 mt-8" @change="cutoffadd()"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                      
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div class="ma-2">
                            <p v-if="!ofsdisable" class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p v-else class="mb-0 caption red--text">{{errmsgforofs}}</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalueofs).toFixed(2) }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                            <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->
                            <v-btn v-if="!ofsdisable" :loading="btnloader" @click="orderapply()" class="  ma-2  text-capitalize white--text"
                              solo text rounded flat style="background-color: black;"> Apply</v-btn>
                              <v-btn v-else :disabled="true" :loading="btnloader" @click="edisredirect()"
                              class="  ma-2  text-capitalize black--text"  text outlined rounded 
                              color="black"> Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card>
                    <v-card v-else class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                      {{orderqualitystat}}

                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                      </div>
                      <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <div class="d-flex mx-8 mt-2 mb-2">
                        <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption red--text ">Stock not in holdings list
                        </p>
                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">
                          <v-col cols="3">
                            <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <v-text-field type="number" :disabled="true" :min="minBidQuantity" :max="maxbidqty"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                              minPrice }} - ₹{{ maxPrice }}</span></p>

                            <v-text-field :disabled="true" type="number" :min="minPrice" :max="maxPrice" step="1"
                              label="Bid Qty" solo rounded flat dense background-color="#F1F3F8" v-model="pricevalueuser"
                              style="width: " class="ml-2" height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox :disabled="true" v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                        <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                          <v-col>

                            <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                            <v-text-field :disabled="true" label="UPI ID" solo rounded flat dense
                              background-color="#F1F3F8" v-model="upiidtype" style="width: " height="36px"></v-text-field>


                          </v-col>
                          <!-- <v-col> -->
                          <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                          <!-- </v-col> -->
                        </v-row>
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                            <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                            <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->

                            <v-btn :loading="btnloader" :disabled="true" class="ma-2 text-capitalize" outlined rounded
                              color="blaack">Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card>
                  </v-dialog>
                  <v-bottom-sheet v-model="orderformobile" inset>
                    <v-sheet class="">
                      <v-card ref="form" v-if="orderqualitystat == 'true' && ordertype != 'ofs' && (ddpi == 'Y' || poa == 'Y')" class="pb-3">
                        <v-list-item-content class=" pt-5 pl-5">
                          <v-list-item-title class="text-start"
                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                              issuetype }}
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                          <!-- <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn> -->
                        </v-list-item-content>
                        <v-divider></v-divider>
                        <div class="d-flex mx-8 mt-2 ">
                          <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                            class="mb-0">{{ syname }} </p>
                          <p style="color: #000000;font-size: 12px"
                            class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                        </div>
                        <p style="color: #000000;font-size: 14px;"
                          class="mb-1 font-weight-regular ml-8 caption grey--text">
                          {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                        <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                        <div class="d-flex mx-8 mt-2 mb-2">
                          <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                          <p style="color: #000000;font-size: 12px"
                            class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">{{ qtyhave }}</p>
                        </div>
                        <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                        <div class=" mx-8">
                          <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                          <v-row class="">
                            <v-col cols="4">
                              <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                              }}</span></p>
                              <v-text-field v-if="issuetype == 'RIGHTS'" @keyup="ordervalcalculaterights()"
                                @change="ordervalcalculatechn()" type="number" :rules="[() => !!bidvalueuser || 'This field is required']" :min="minBidQuantity" :max="qtyhave"
                                :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " height="36px"></v-text-field>
                              <v-text-field :rules="[() => !!bidvalueuser || 'This field is required']" v-else
                                @keyup="ordervalcalculate()" type="number" :min="minBidQuantity" :max="qtyhave"
                                :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="5">
                              <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                                minPrice }} - ₹{{ maxPrice }}</span></p>
                              <v-text-field :rules="[() => !!pricevalueuser || 'This field is required']"
                                @change="bidlessmorechn()" @keyup="bidlessmore()" :disabled="pricetextdisabled"
                                type="number" :min="minPrice" :max="maxPrice" step="1" label="Price Range" solo rounded
                                flat dense background-color="#F1F3F8" v-model="pricevalueuser" style="width: "
                                class="ml-2" height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="3">
                              <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2"
                                @change="cutoffadd()" v-model="checkbox" label="Cutoff Price"></v-checkbox>
                            </v-col>
                          </v-row>


                          <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                            <v-col>

                              <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                              <v-text-field label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiidtype" style="width: " height="36px"></v-text-field>


                            </v-col>
                            <!-- <v-col> -->
                            <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                            <!-- </v-col> -->
                          </v-row>
                          <v-divider class="mx-2"></v-divider>
                          <div class="d-flex">
                            <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                              <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                              <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                            </div>
                            <div class="ml-auto mt-2">
                              <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->
                              <v-btn :loading="btnloader" @click="orderapply()"
                                class="  ma-2  text-capitalize white--text" solo text rounded flat
                                style="background-color: black;"> Apply</v-btn>
                            </div>

                          </div>






                        </div>

                      </v-card>
                      <v-card ref="form"
                        v-else-if="ordertype != 'ofs' && edisapprove == 'true' && orderqualitystat == 'true' && (ddpi == 'N' && poa == 'N')"
                        class="pb-3">
                        <v-list-item-content class=" pt-5 pl-5">
                          <v-list-item-title class="text-start"
                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                              issuetype }}
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                          <!-- <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn> -->
                        </v-list-item-content>
                        <v-divider></v-divider>
                        <div class="d-flex mx-8 mt-2 ">
                          <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                            class="mb-0">{{ syname }} </p>
                          <p style="color: #000000;font-size: 12px"
                            class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                        </div>
                        <p style="color: #000000;font-size: 14px;"
                          class="mb-1 font-weight-regular ml-8 caption grey--text">
                          {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                        <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                        <div class="d-flex mx-8 mt-2 mb-2">
                          <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held({{
                            qtyhave }})</p>
                          <p v-if="edisbtnorder" style="color: #000000;font-size: 12px"
                            class="mb-0 ml-auto mr-3 mt-1 font-weight-regular red--text caption">Qty Approved by EDIS({{
                              quantityapproveedit }})</p>
                          <p v-else style="color: #000000;font-size: 12px"
                            class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">Qty Approved by EDIS({{
                              quantityapproveedit }})</p>
                        </div>
                        <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                        <div class=" mx-8">
                          <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                          <v-row class="">
                            <v-col cols="4">
                              <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                              }}</span></p>
                              <v-text-field v-if="issuetype == 'RIGHTS'" @keyup="ordervalcalculaterights()"
                                @change="editsquantitycheck()" type="number" :min="quantityminedis" :max="quantitymaxedis"
                                :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " height="36px"></v-text-field>
                              <v-text-field :rules="[() => !!bidvalueuser || 'This field is required']" v-else
                                @keyup="ordervalcalculateedis()" type="number" :min="quantityminedis"
                                :max="quantitymaxedis" :step="steplot" label="Bid Qty" solo rounded flat dense
                                background-color="#F1F3F8" v-model="bidvalueuser" style="width: "
                                height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="5">
                              <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                                minPrice }} - ₹{{ maxPrice }}</span></p>
                              <v-text-field :rules="[() => !!pricevalueuser || 'This field is required']"
                                @change="bidlessmorechn()" @keyup="bidlessmore()" :disabled="pricetextdisabled"
                                type="number" :min="minPrice" :max="maxPrice" step="1" label="Price Range" solo rounded
                                flat dense background-color="#F1F3F8" v-model="pricevalueuser" style="width: "
                                class="ml-2" height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="3">
                              <v-checkbox v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2"
                                @change="cutoffadd()" v-model="checkbox" label="Cutoff Price"></v-checkbox>
                            </v-col>
                          </v-row>


                          <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                            <v-col>

                              <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                              <v-text-field label="UPI ID" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiidtype" style="width: " height="36px"></v-text-field>


                            </v-col>
                            <!-- <v-col> -->
                            <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                            <!-- </v-col> -->
                          </v-row>
                          <v-divider class="mx-2"></v-divider>
                          <div class="d-flex">
                            <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                              <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                              <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                            </div>
                            <div v-if="edisbtnorder" class="mt-6">

                              <p class="mb-0 caption grey--text">Want more need to do EDIS -- ></p>
                            </div>
                            <v-text-field v-if="edisbtnorder" @change="passinput()"
                              :error-messages="errorMessageswrongpassword"
                              :rules="[() => !!tradingpassword || 'This field is required']"
                              :disabled="disabledforblocked" :append-icon="iconforpassword" color="black"
                              :type="textfieldtype" @click:append="iconnn()" label="Password" solo rounded flat dense
                              background-color="#F1F3F8" v-model="tradingpassword" style="width: " class="ml-2 mt-4"
                              height="36px"></v-text-field>
                            <div class="ml-auto mt-2">
                              <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->
                              <v-btn v-if="!edisbtnorder" :loading="btnloader" @click="orderapply()"
                                class="  ma-2  text-capitalize white--text" solo text rounded flat
                                style="background-color: black;"> Apply</v-btn>
                              <v-btn v-if="disabledforblocked == true" :loading="edisbtn"
                                @click="dialogmyntpasschange = true" class="ma-2 text-capitalize white--text" solo text
                                rounded flat style="background-color: black;"> Unblock</v-btn>
                              <v-btn v-if="disabledforblocked == false && edisbtnorder == true" :loading="edisbtn"
                                @click="edisredirect()" class="  ma-2  text-capitalize white--text" solo text rounded flat
                                style="background-color: black;"> EDIS</v-btn>
                              <v-btn v-else-if="disabledforblocked == true && edisbtnorder == false" :disabled="true"
                                :loading="btnloader" @click="edisredirect()" class="  ma-2  text-capitalize black--text"
                                text outlined rounded color="black"> EDIS</v-btn>
                            </div>

                          </div>






                        </div>

                      </v-card>
                      <v-card
                        v-else-if="ordertype != 'ofs' && orderqualitystat == 'true' && edisapprove == 'false' && (ddpi == 'N' && poa == 'N')"
                        class="pb-3">
                        <v-list-item-content class=" pt-5 pl-5">
                          <v-list-item-title class="text-start"
                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">EDIS
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                          <!-- <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn> -->
                        </v-list-item-content>
                        <v-divider></v-divider>
                        <div class="d-flex mx-8 mt-2 ">
                          <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;" class="mb-0">For
                            Edis need your trading password </p>
                        </div>
                        <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                        <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->
                        <div class=" mx-8">
                          <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                          <v-row class="">

                            <v-col cols="8">

                              <v-text-field @change="passinput()" :error-messages="errorMessageswrongpassword"
                                :rules="[() => !!tradingpassword || 'This field is required']"
                                :disabled="disabledforblocked" :append-icon="iconforpassword" color="black"
                                :type="textfieldtype" @click:append="iconnn()" label="Password" solo rounded flat dense
                                background-color="#F1F3F8" v-model="tradingpassword" style="width: " class="ml-2 mt-4"
                                height="36px"></v-text-field>
                              <!-- <p v-if="wrongmsgtxt == true" class="caption red--text ml-4 mt-n3">Wrong Password</p> -->
                              <!-- <p v-else-if="passwrongcount > 2" class="caption red--text ml-4 mt-n3">Wrong Password({{passwrongcount}}/3)</p> -->

                              <!-- <p v-else-if="disabledforblocked == true" class="caption red--text ml-4 mt-n3">Account Blocked</p> -->

                            </v-col>
                            <!-- <v-col class="pa-0" cols="12">
                        </v-col> -->
                          </v-row>



                          <v-divider class="mx-2"></v-divider>
                          <div class="d-flex">

                            <div class="ml-auto mt-2">
                              <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->

                              <v-btn v-if="disabledforblocked == true" :loading="edisbtn"
                                @click="dialogmyntpasschange = true" class="ma-2 text-capitalize white--text" solo text
                                rounded flat style="background-color: black;"> Unblock</v-btn>
                              <v-btn v-if="disabledforblocked == false" :loading="edisbtn" @click="edisredirect()"
                                class="  ma-2  text-capitalize white--text" solo text rounded flat
                                style="background-color: black;"> Apply</v-btn>
                              <v-btn v-else :disabled="true" :loading="btnloader" @click="edisredirect()"
                                class="  ma-2  text-capitalize black--text" text outlined rounded color="black">
                                Apply</v-btn>

                            </div>

                          </div>






                        </div>

                      </v-card>
                      <!-- <v-card v-else-if="(orderqualitystat == 'false' && (ddpi == 'Y' || poa == 'Y')) " class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                       
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                      </div>
                      <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                      <div class="d-flex mx-8 mt-2 mb-2">
                        <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption red--text ">Stock not in holdings list
                        </p>
                      </div>

                      <div class=" mx-8">
                        
                        <v-row class="">
                          <v-col cols="3">
                            <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <v-text-field type="number" :disabled="true" :min="minBidQuantity" :max="maxbidqty"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                              minPrice }} - ₹{{ maxPrice }}</span></p>

                            <v-text-field :disabled="true" type="number" :min="minPrice" :max="maxPrice" step="1"
                              label="Bid Qty" solo rounded flat dense background-color="#F1F3F8" v-model="pricevalueuser"
                              style="width: " class="ml-2" height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox :disabled="true" v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                        <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                          <v-col>

                            <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                            <v-text-field :disabled="true" label="UPI ID" solo rounded flat dense
                              background-color="#F1F3F8" v-model="upiidtype" style="width: " height="36px"></v-text-field>


                          </v-col>
                       
                        </v-row>
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                            <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                           

                            <v-btn :loading="btnloader" :disabled="true" class="ma-2 text-capitalize" outlined rounded
                              color="blaack">Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card> -->
                      <!-- <v-card v-else-if="orderqualitystat == 'true' && (ddpi == 'N' && poa == 'N')" class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">E-Dis
                        </v-list-item-title>
                     
                        <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;"
                          class="mb-0">For Edis need your trading password </p>
                      </div>
                    
                      <div class=" mx-8">
                      
                        <v-row class="">

                          <v-col cols="8">

                            <v-text-field @change="passinput()" :error-messages="errorMessageswrongpassword" :rules="[() => !!tradingpassword || 'This field is required']"  :disabled="disabledforblocked" :append-icon="iconforpassword" color="black" :type="textfieldtype"
                              @click:append="iconnn()" label="Password" solo rounded flat dense background-color="#F1F3F8"
                              v-model="tradingpassword" style="width: " class="ml-2 mt-4" height="36px"></v-text-field>
                           
                          </v-col>
                        
                        </v-row>



                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">

                          <div class="ml-auto mt-2">
                           
                              
                            <v-btn v-if="disabledforblocked == true" :loading="edisbtn" @click="dialogmyntpasschange = true"
                              class="ma-2 text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> Unblock</v-btn>
                            <v-btn v-if="disabledforblocked == false" :loading="edisbtn" @click="edisredirect()"
                              class="  ma-2  text-capitalize white--text" solo text rounded flat
                              style="background-color: black;"> Apply</v-btn>
                              <v-btn v-else :disabled="true" :loading="btnloader" @click="edisredirect()"
                              class="  ma-2  text-capitalize black--text"  text outlined rounded 
                              color="black"> Apply</v-btn>
                              
                          </div>

                        </div>






                      </div>

                    </v-card> -->
                    <v-card ref="form" v-else-if="orderqualitystat == 'true' && ordertype == 'ofs'" class="pb-3">
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                            issuetype }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                        
                       
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <div class="d-flex mx-8 mt-2 ">
                        <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                          class="mb-0">{{ syname }} </p>
                        <p style="color: #000000;font-size: 12px"
                          class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ ofscutOffPrice }}</p>
                      </div>
                      <!-- <p style="color: #000000;font-size: 14px;" class="mb-1 font-weight-regular ml-8 caption grey--text">
                        {{ biddingStartDate }} to {{ biddingEndDate }}</p> -->
                      <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                      <div class="x-8 mt-2 mb-2">
                        <!-- <p styd-flex mle="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                        <p style="color: #000000;font-size: 12px"
                          class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption">{{ qtyhave }}</p> -->
                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                      <div class=" mx-8">
                        <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                        <v-row class="">
                          <v-col cols="4">
                            <p class="mb-1 caption grey--text ">Bid Quantity <span class="caption black--text">{{ steplot
                            }}</span></p>
                            <!-- <v-text-field @keyup="ordervalcalculaterights()"
                              @change="ordervalcalculatechn()" type="number" :min="minBidQuantity" :max="maxbidqty"
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="bidvalueuser" style="width: " height="36px"></v-text-field> -->
                            <v-text-field :rules="[() => !!ofsbidvalueuser || 'This field is required']"
                              @change="bidlessmorechnofs()" @keyup="bidlessmorechnofs()" type="number" min=1 
                              :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                              v-model="ofsbidvalueuser" style="width: " height="36px" class="mt-3"></v-text-field>

                          </v-col>
                          <v-col cols="5">
                            <p class="mb-1 caption grey--text ml-2">Price </p>
                            <v-text-field :rules="[() => !!ofspricevalueuser || 'This field is required']"
                              @change="bidlessmorechnofs()" @keyup="bidlessmorechnofs()" :disabled="pricetextdisabled"
                              type="number" :min="ofscutOffPrice" :max="maxPrice" step="1" label="Price Range" solo rounded flat
                              dense background-color="#F1F3F8" v-model="ofspricevalueuser" style="width: " class="ml-2 mt-3"
                              height="36px"></v-text-field>

                          </v-col>
                          <v-col cols="3">
                            <v-checkbox  color="black" class=" ml-2 mt-8" @change="cutoffadd()"
                              v-model="checkbox" label="Cutoff Price"></v-checkbox>
                          </v-col>
                        </v-row>


                      
                        <v-divider class="mx-2"></v-divider>
                        <div class="d-flex">
                          <div class="ma-2">
                            <p v-if="!ofsdisable" class="mb-0 caption grey--text">Required amount to buy Bid</p>
                            <p v-else class="mb-0 caption red--text">{{errmsgforofs}}</p>
                            <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalueofs).toFixed(2) }}</p>
                          </div>
                          <div class="ml-auto mt-2">
                            <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->
                            <v-btn v-if="!ofsdisable" :loading="btnloader" @click="orderapply()" class="  ma-2  text-capitalize white--text"
                              solo text rounded flat style="background-color: black;"> Apply</v-btn>
                              <v-btn v-else :disabled="true" :loading="btnloader" @click="edisredirect()"
                              class="  ma-2  text-capitalize black--text"  text outlined rounded 
                              color="black"> Apply</v-btn>
                          </div>

                        </div>






                      </div>

                    </v-card>
                      <v-card v-else class="pb-3">
                        <v-list-item-content class=" pt-5 pl-5">
                          <v-list-item-title class="text-start"
                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                              issuetype }}
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">from date to to date
                            </v-list-item-subtitle> -->
                          <!-- <v-btn absolute right top icon color="black" @click="dialoguorder = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn> -->
                        </v-list-item-content>
                        <v-divider></v-divider>
                        <div class="d-flex mx-8 mt-2 ">
                          <p style="color: #000000;font-size: 14px;font-weight:559;line-height:24px;letter-spacing:-1px"
                            class="mb-0">{{ syname }} </p>
                          <p style="color: #000000;font-size: 12px"
                            class="ml-auto mr-3 mt-1 mb-0 font-weight-regular caption">₹{{ cutOffPrice }}</p>
                        </div>
                        <p style="color: #000000;font-size: 14px;"
                          class="mb-1 font-weight-regular ml-8 caption grey--text">
                          {{ biddingStartDate }} to {{ biddingEndDate }}</p>
                        <!-- <p style="color: #000000;font-size: 12px" class="mb-1 ml-auto mr-3 mt-1">Price</p> -->
                        <div class="d-flex mx-8 mt-2 mb-2">
                          <p style="color: #000000;font-size: 14px;" class="mb-0 font-weight-regular caption">Qty Held</p>
                          <p style="color: #000000;font-size: 12px"
                            class="mb-0 ml-auto mr-3 mt-1 font-weight-regular caption red--text ">Stock not in holdings
                            list
                          </p>
                        </div>
                        <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->

                        <div class=" mx-8">
                          <!-- <div>
                                <v-text-field type="number" :min="minBidQuantity" :max="maxbidqty" :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div>
                              <div>
                                <v-text-field type="number" :min="minPrice" :max="maxPrice" step="1" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-4"
                                height="36px"></v-text-field>
                              </div> -->
                          <v-row class="">
                            <v-col cols="3">
                              <p class="mb-1 caption grey--text ">Lot Size : <span class="caption black--text">{{ steplot
                              }}</span></p>
                              <v-text-field type="number" :disabled="true" :min="minBidQuantity" :max="maxbidqty"
                                :step="steplot" label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="bidvalueuser" style="width: " height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="5">
                              <p class="mb-1 caption grey--text ml-2">price Range : <span class="caption black--text">₹{{
                                minPrice }} - ₹{{ maxPrice }}</span></p>

                              <v-text-field :disabled="true" type="number" :min="minPrice" :max="maxPrice" step="1"
                                label="Bid Qty" solo rounded flat dense background-color="#F1F3F8"
                                v-model="pricevalueuser" style="width: " class="ml-2" height="36px"></v-text-field>

                            </v-col>
                            <v-col cols="3">
                              <v-checkbox :disabled="true" v-if="issuetype == 'RIGHTS'" color="black" class="mt-6 ml-2"
                                v-model="checkbox" label="Cutoff Price"></v-checkbox>
                            </v-col>
                          </v-row>


                          <v-row class="mt-n7" v-if="issuetype == 'RIGHTS'">
                            <v-col>

                              <p class="mb-1 caption black--text ml-2">UPI ID (Virtual payment addresss)</p>
                              <v-text-field :disabled="true" label="UPI ID" solo rounded flat dense
                                background-color="#F1F3F8" v-model="upiidtype" style="width: "
                                height="36px"></v-text-field>


                            </v-col>
                            <!-- <v-col> -->
                            <!-- <p class="mb-1 caption black--text ml-4">UPI Provider</p>

                                    <v-autocomplete :items="upiprovider"  label="UPI Provider" solo rounded flat dense background-color="#F1F3F8"
                                v-model="upiproviderselect" style="width: " class="ml-4"
                                height="36px"></v-autocomplete> -->

                            <!-- </v-col> -->
                          </v-row>
                          <v-divider class="mx-2"></v-divider>
                          <div class="d-flex">
                            <div v-if="issuetype == 'RIGHTS'" class="ma-2">
                              <p class="mb-0 caption grey--text">Required amount to buy Bid</p>
                              <p class="mb-0 body-1 black--text"> ₹ {{ (inverstvalue).toFixed(2) }}</p>
                            </div>
                            <div class="ml-auto mt-2">
                              <!-- <v-btn class="ma-2 text-capitalize" outlined rounded color="blaack"
                              @click="dialoguorder = false">Cancel</v-btn> -->

                              <v-btn :loading="btnloader" :disabled="true" class="ma-2 text-capitalize" outlined rounded
                                color="blaack">Apply</v-btn>
                            </div>

                          </div>






                        </div>

                      </v-card>
                    </v-sheet>
                  </v-bottom-sheet>
                  <v-tabs v-model="tab" background-color="transparent" color="basil" grow>

                    <v-tab v-model="tab2">
                      Buyback
                    </v-tab>


                    <v-tab v-model="tab3">
                      Delisting
                    </v-tab>

                    <v-tab v-model="tab4">
                      Takeover
                    </v-tab>
                    <v-tab v-model="tab5">
                      OFS
                    </v-tab>
                    <!-- <v-tab v-model="tab6">
                      Rights
                    </v-tab> -->
                    <!-- <div v-else-if="!disabletabs">
                    <v-tab v-model="tab3" @click="tabdata('Derivatives')">
                        Derivatives
                    </v-tab>
                    <v-tab v-model="tab4" @click="tabdata('Commodity')">
                        Commodity
                    </v-tab>
                    <v-tab v-model="tab5" @click="tabdata('Currency')">
                        Currency
                    </v-tab>
                    </div> -->
                  </v-tabs>
                </v-col>
                <v-col class="text-right">
                  <!-- <v-btn @click="edisredirect()"></v-btn> -->
                  <v-card v-if="poa == 'Y'" height="22" elevation="0" class="px-2 mt-4 ml-2 mr-4"
                    style="display: inline; float: right" color="#43A833">
                    <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                      POA
                    </p>
                  </v-card>
                  <v-card v-else height="22" elevation="0" class="px-2 mt-4 ml-2" style="display: inline; float: right"
                    color="red">
                    <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                      POA
                    </p>
                  </v-card>
                  <v-card v-if="ddpi == 'Y'" height="22" elevation="0" class="px-2 mt-4"
                    style="display: inline; float: right" color="#43A833">
                    <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                      DDPI
                    </p>
                  </v-card>
                  <v-card v-else height="22" elevation="0" class="px-2 mt-4" style="display: inline; float: right"
                    color="red">
                    <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                      DDPI
                    </p>

                  </v-card>
                </v-col>
              </v-row>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                    fixed-header disable-sort :headers="Overviewheaders" :items="dessertsbuyback"
                    :items-per-page="itemperpage" style="border: 1px solid #efeef3; cursor: pointer"
                    class="elevation-0 rounded-lg">
                    <template v-slot:[`item.name`]="{ item }">
                      <span style="text-transform: uppercase;" class="font-weight-bold subtitle-2 mt-2">
                        {{ item.name }} <v-chip v-if="item.exchange == 'NSETender'" small color="#00ACC1"
                          class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">NSE</span></v-chip>
                        <v-chip v-else small color="#00ACC1" class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">{{ item.exchange }}</span></v-chip></span>

                    </template>
                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                      <span v-if="item.NOT_PROFIT > 0" style="color: green" class="font-weight-regular">{{
                        item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT < 0" style="color: red" class="font-weight-regular">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT == 0" style="color: black" class="font-weight-regular">
                        0</span>
                    </template>
                    <template v-slot:[`item.cutOffPrice`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.cutOffPrice }}</span>
                    </template>

                    <template v-slot:[`item.opendatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingStartDate }}<span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyStartTime }}</span></span>
                    </template>
                    <template v-slot:[`item.closedatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingEndDate }} <span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyEndTime }}</span></span>

                    </template>
                    <template v-slot:[`item.buyorder`]="{ item }">

                      <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                        class="ma-2 text-capitalize" @click="orderplaced(item)" small outlined rounded
                        color="black">Order</v-btn>
                      <div class="pt-3  text-center"
                        v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                        <v-btn class="ma-1 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                          color="red">Cancel</v-btn>
                        <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                            v-if="item.appbidqty == '1'">quantity</span><span
                            v-else-if="item.appbidqty > '1'">quantities</span> bid </p>
                        <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                      </div>
                      <!-- <v-btn 
class="ma-2 text-capitalize"  small outlined rounded
color="red">{{item.appbidqty}}Cancel</v-btn> -->
                    </template>
                    <!-- <template v-slot:[`item.cancelorder`]="{ item }">

<v-btn :disabled="item.statusorder != 'pending' && item.statusorder != 'success'"
  class="ma-2 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
  color="red">Cancel</v-btn>
</template> -->
                    <template v-slot:[`item.rpnl`]="{ item }">
                      <span v-if="item.pnl_type == 'Realized'" class="font-weight-regular" style="text-align: right">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-5">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <!-- <template v-slot:footer>
                                                  <v-divider></v-divider>
                                                  <div class="text-center" v-if="more">
                                                      <v-btn text color="primary" dark class="ma-2 text-none"
                                                          @click="morebtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                              more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                      </v-btn>
                                                  </div>
                                                  <div class="text-center" v-if="less">
                                                      <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                          @click="lessbtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                              Show Less</span> <v-icon color="black">
                                                              mdi-chevron-up</v-icon>
                                                      </v-btn>
                                                  </div>
                                              </template> -->
                  </v-data-table>

                </v-tab-item>
                <v-tab-item>
                  <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                    fixed-header disable-sort :headers="Overviewheaders" :items="dessertsdelist"
                    :items-per-page="itemperpage" style="border: 1px solid #efeef3; cursor: pointer"
                    class="elevation-0 rounded-lg">
                    <template v-slot:[`item.name`]="{ item }">
                      <span style="text-transform: uppercase;" class="font-weight-bold subtitle-2 mt-2">
                        {{ item.name }}<v-chip v-if="item.exchange == 'NSETender'" small color="#00ACC1"
                          class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">NSE</span></v-chip>
                        <v-chip v-else small color="#00ACC1" class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">{{ item.exchange }}</span></v-chip></span>

                    </template>
                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                      <span v-if="item.NOT_PROFIT > 0" style="color: green" class="font-weight-regular">{{
                        item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT < 0" style="color: red" class="font-weight-regular">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT == 0" style="color: black" class="font-weight-regular">
                        0</span>
                    </template>
                    <template v-slot:[`item.cutOffPrice`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.cutOffPrice }}</span>
                    </template>

                    <template v-slot:[`item.opendatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingStartDate }}<span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyStartTime }}</span></span>
                    </template>
                    <template v-slot:[`item.closedatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingEndDate }} <span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyEndTime }}</span></span>

                    </template>
                    <template v-slot:[`item.buyorder`]="{ item }">

                      <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                        class="ma-2 text-capitalize" @click="orderplaced(item)" small outlined rounded
                        color="black">Order</v-btn>
                      <div class="pt-3  text-center"
                        v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                        <v-btn class="ma-1 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                          color="red">Cancel</v-btn>
                        <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                            v-if="item.appbidqty == '1'">quantity</span><span
                            v-else-if="item.appbidqty > '1'">quantities</span> bid </p>
                        <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                      </div>
                      <!-- <v-btn 
class="ma-2 text-capitalize"  small outlined rounded
color="red">{{item.appbidqty}}Cancel</v-btn> -->
                    </template>
                    <!-- <template v-slot:[`item.cancelorder`]="{ item }">


<v-btn :disabled="item.statusorder != 'pending' && item.statusorder != 'success'"
  class="ma-2 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
  color="red">Cancel</v-btn>
</template> -->
                    <template v-slot:[`item.rpnl`]="{ item }">
                      <span v-if="item.pnl_type == 'Realized'" class="font-weight-regular" style="text-align: right">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-5">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <!-- <template v-slot:footer>
                                                  <v-divider></v-divider>
                                                  <div class="text-center" v-if="more">
                                                      <v-btn text color="primary" dark class="ma-2 text-none"
                                                          @click="morebtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                              more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                      </v-btn>
                                                  </div>
                                                  <div class="text-center" v-if="less">
                                                      <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                          @click="lessbtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                              Show Less</span> <v-icon color="black">
                                                              mdi-chevron-up</v-icon>
                                                      </v-btn>
                                                  </div>
                                              </template> -->
                  </v-data-table>


                </v-tab-item>
                <v-tab-item>
                  <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                    fixed-header disable-sort :headers="Overviewheaders" :items="dessertstake"
                    :items-per-page="itemperpage" style="border: 1px solid #efeef3;" class="elevation-0 rounded-lg">
                    <template v-slot:[`item.name`]="{ item }">
                      <span style="text-transform: uppercase;" class="font-weight-bold subtitle-2 mt-2">
                        {{ item.name }} <v-chip v-if="item.exchange == 'NSETender'" small color="#00ACC1"
                          class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">NSE</span></v-chip>
                        <v-chip v-else small color="#00ACC1" class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">{{ item.exchange }}</span></v-chip></span>

                    </template>
                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                      <span v-if="item.NOT_PROFIT > 0" style="color: green" class="font-weight-regular">{{
                        item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT < 0" style="color: red" class="font-weight-regular">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT == 0" style="color: black" class="font-weight-regular">
                        0</span>
                    </template>
                    <template v-slot:[`item.cutOffPrice`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.cutOffPrice }}</span>
                    </template>

                    <template v-slot:[`item.opendatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingStartDate }}<span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyStartTime }}</span></span>
                    </template>
                    <template v-slot:[`item.closedatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingEndDate }} <span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyEndTime }}</span></span>

                    </template>
                    <template v-slot:[`item.buyorder`]="{ item }">

                      <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                        class="ma-2 text-capitalize" @click="orderplaced(item)" small outlined rounded
                        color="black">Order</v-btn>
                      <div class="pt-3  text-center"
                        v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                        <v-btn class="ma-1 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                          color="red">Cancel</v-btn>
                        <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                            v-if="item.appbidqty == '1'">quantity</span><span
                            v-else-if="item.appbidqty > '1'">quantities</span> bid </p>
                        <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
                          Bid</p> -->

                      </div>
                      <!-- <v-btn 
  class="ma-2 text-capitalize"  small outlined rounded
  color="red">{{item.appbidqty}}Cancel</v-btn> -->
                    </template>
                    <!-- <template v-slot:[`item.cancelorder`]="{ item }">

                      
                      <v-btn :disabled="item.statusorder != 'pending' && item.statusorder != 'success'"
                        class="ma-2 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                        color="red">Cancel</v-btn>
                    </template> -->
                    <template v-slot:[`item.rpnl`]="{ item }">
                      <span v-if="item.pnl_type == 'Realized'" class="font-weight-regular" style="text-align: right">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-5">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <!-- <template v-slot:footer>
                                                  <v-divider></v-divider>
                                                  <div class="text-center" v-if="more">
                                                      <v-btn text color="primary" dark class="ma-2 text-none"
                                                          @click="morebtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                              more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                      </v-btn>
                                                  </div>
                                                  <div class="text-center" v-if="less">
                                                      <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                          @click="lessbtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                              Show Less</span> <v-icon color="black">
                                                              mdi-chevron-up</v-icon>
                                                      </v-btn>
                                                  </div>
                                              </template> -->
                  </v-data-table>
                </v-tab-item>
                <v-tab-item>
                  <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                    fixed-header disable-sort :headers="Overviewheaders2" :items="dessertsofs"
                    :items-per-page="itemperpage" style="border: 1px solid #efeef3; cursor: pointer"
                    class="elevation-0 rounded-lg">
                    <template v-slot:[`item.name`]="{ item }">
                      <span style="text-transform: uppercase;" class="font-weight-bold subtitle-2 mt-2">
                        {{ item.name }} <v-chip v-if="item.exchange == 'NSETender'" small color="#00ACC1"
                          class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">NSE</span></v-chip>
                        <v-chip v-else small color="#00ACC1" class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">{{ item.exchange }}</span></v-chip>
                      </span>

                    </template>
                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                      <span v-if="item.NOT_PROFIT > 0" style="color: green" class="font-weight-regular">{{
                        item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT < 0" style="color: red" class="font-weight-regular">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT == 0" style="color: black" class="font-weight-regular">
                        0</span>
                    </template>
                    <template v-slot:[`item.cutOffPrice`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.cutOffPrice }}</span>
                    </template>

                    <template v-slot:[`item.opendatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingStartDate }}<span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyStartTime }}</span></span>
                    </template>
                    <template v-slot:[`item.closedatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingEndDate }} <span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyEndTime }}</span></span>

                    </template>
                    <template v-slot:[`item.buyorder`]="{ item }">

                      <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                        class="ma-2 text-capitalize" @click="orderplaced(item)" small outlined rounded
                        color="black">Order</v-btn>
                      <div class="pt-3  text-center"
                        v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                        <v-btn class="ma-1 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                          color="red">Cancel</v-btn>
                        <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                            v-if="item.appbidqty == '1'">quantity</span><span
                            v-else-if="item.appbidqty > '1'">quantities</span> bid </p>
                        <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                      </div>
                      <!-- <v-btn 
class="ma-2 text-capitalize"  small outlined rounded
color="red">{{item.appbidqty}}Cancel</v-btn> -->
                    </template>
                    <!-- <template v-slot:[`item.cancelorder`]="{ item }">


<v-btn :disabled="item.statusorder != 'pending' && item.statusorder != 'success'"
  class="ma-2 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
  color="red">Cancel</v-btn>
</template> -->
                    <template v-slot:[`item.rpnl`]="{ item }">
                      <span v-if="item.pnl_type == 'Realized'" class="font-weight-regular" style="text-align: right">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-5">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <!-- <template v-slot:footer>
                                                  <v-divider></v-divider>
                                                  <div class="text-center" v-if="more">
                                                      <v-btn text color="primary" dark class="ma-2 text-none"
                                                          @click="morebtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                              more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                      </v-btn>
                                                  </div>
                                                  <div class="text-center" v-if="less">
                                                      <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                          @click="lessbtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                              Show Less</span> <v-icon color="black">
                                                              mdi-chevron-up</v-icon>
                                                      </v-btn>
                                                  </div>
                                              </template> -->
                  </v-data-table>
                </v-tab-item>
                <v-tab-item>
                  <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                    fixed-header disable-sort :headers="Overviewheaders" :items="dessertstrights"
                    :items-per-page="itemperpage" style="border: 1px solid #efeef3; cursor: pointer"
                    class="elevation-0 rounded-lg">
                    <template v-slot:[`item.name`]="{ item }">
                      <span style="text-transform: uppercase;" class="font-weight-bold subtitle-2 mt-2">
                        {{ item.name }} <v-chip v-if="item.exchange == 'NSETender'" small color="#00ACC1"
                          class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">NSE</span></v-chip>
                        <v-chip v-else small color="#00ACC1" class="elevation-0 ml-2" width="25px"><span
                            class=" pa-1  caption white--text">{{ item.exchange }}</span></v-chip></span>

                    </template>
                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                      <span v-if="item.NOT_PROFIT > 0" style="color: green" class="font-weight-regular">{{
                        item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT < 0" style="color: red" class="font-weight-regular">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT == 0" style="color: black" class="font-weight-regular">
                        0</span>
                    </template>
                    <template v-slot:[`item.cutOffPrice`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.cutOffPrice }}</span>
                    </template>

                    <template v-slot:[`item.opendatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingStartDate }}<span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyStartTime }}</span></span>
                    </template>
                    <template v-slot:[`item.closedatetime`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.biddingEndDate }} <span class="font-weight-regular caption grey--text ml-2"
                          style="text-align: right">{{ item.dailyEndTime }}</span></span>

                    </template>
                    <template v-slot:[`item.buyorder`]="{ item }">

                      <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                        class="ma-2 text-capitalize" @click="orderplacedrights(item)" small outlined rounded
                        color="black">Order</v-btn>
                      <div class="pt-3  text-center"
                        v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                        <v-btn class="ma-1 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                          color="red">Cancel</v-btn>
                        <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                            v-if="item.appbidqty == '1'">quantity</span><span
                            v-else-if="item.appbidqty > '1'">quantities</span> bid </p>
                        <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                      </div>
                      <!-- <v-btn 
class="ma-2 text-capitalize"  small outlined rounded
color="red">{{item.appbidqty}}Cancel</v-btn> -->
                    </template>
                    <!-- <template v-slot:[`item.cancelorder`]="{ item }">


<v-btn :disabled="item.statusorder != 'pending' && item.statusorder != 'success'"
  class="ma-2 text-capitalize" @click="cancelorderpop(item)" small outlined rounded
  color="red">Cancel</v-btn>
</template> -->
                    <template v-slot:[`item.rpnl`]="{ item }">
                      <span v-if="item.pnl_type == 'Realized'" class="font-weight-regular" style="text-align: right">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-5">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <!-- <template v-slot:footer>
                                                  <v-divider></v-divider>
                                                  <div class="text-center" v-if="more">
                                                      <v-btn text color="primary" dark class="ma-2 text-none"
                                                          @click="morebtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                              more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                      </v-btn>
                                                  </div>
                                                  <div class="text-center" v-if="less">
                                                      <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                          @click="lessbtn()">
                                                          <span class=""
                                                              style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                              Show Less</span> <v-icon color="black">
                                                              mdi-chevron-up</v-icon>
                                                      </v-btn>
                                                  </div>
                                              </template> -->
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>

              <!-- <div class="display-flex row d-none d-md-block pa-8">
                  <v-row>
                    <v-col>
                      <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                        <p class="pt-1 mb-3" style="
                          font-weight: 450;
                          font-size: 16px;
                          color: #000000;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          Realised P&L
                        </p>
                        <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                        <div class="display-flex row pt-6 pl-2">
                          <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                            <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl < 0" style="
                              color: red;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disrealised_pnl.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl > 0" style="
                              color: green;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disrealised_pnl.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl == 0" style="
                              color: black;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disrealised_pnl.toFixed(2) }}
                            </p>
                          </v-card>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                        <p class="pt-1 mb-3" style="
                          font-weight: 450;
                          font-size: 16px;
                          color: #000000;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          Unrealised P&L
                        </p>
                        <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                        <div class="display-flex row pt-6 pl-2">
                          <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                            <p class="mb-0" v-if="disunrealised_pnl && disunrealised_pnl < 0" style="
                              color: red;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disunrealised_pnl.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl > 0" style="
                              color: green;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disunrealised_pnl.toFixed(2) }}
                            </p>
  
                            <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl == 0
                                                    " style="
                              color: black;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ disunrealised_pnl.toFixed(2) }}
                            </p>
                          </v-card>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                        <p class="pt-1 mb-3" style="
                          font-weight: 450;
                          font-size: 16px;
                          color: #000000;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          Total P&L
                        </p>
                        <v-progress-linear style="width: 30px" v-if="segloader" indeterminate
                          color="blue"></v-progress-linear>
                        <v-divider v-else-if="!segloader" class="mt-2" style="border: solid px #000000" width="30px"
                          color="#000000"></v-divider>
                        <div class="display-flex row pt-6 pl-2">
                          <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                            <p class="mb-0" v-if="segval && segval < 0" style="
                              color: red;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ segval.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-else-if="segval && segval > 0" style="
                              color: green;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ segval.toFixed(2) }}
                            </p>
                            <p class="mb-0" v-else-if="segval && segval == 0" style="
                              color: black;
                              font-size: 13px;
                              font-weight: 469;
                              line-height: 12px;
                              letter-spacing: 0.9px;
                            ">
                              {{ segval.toFixed(2) }}
                            </p>
                          </v-card>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col>
                     
                      <div v-if="!loader">
                        <p class="mt-2 mb-3" style="
                          font-weight: 450;
                          font-size: 14px;
                          color: #4b4b4b;
                          line-height: 16px;
                          letter-spacing: -0.4px;
                        ">
                          <v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
                          P&L verified by Zebu
                         
                        </p>
                      
                        <v-row>
                          <v-col cols="7" class="pa-0 pl-3">
  
                           
                            <p class="mt-2 mb-0 " style="font-size: 12px">Share to Everyone</p>
                            <div class="d-flex mt-n1">
                              <v-switch color="black" @change="switchbtn" class="mt-1" v-model="agreeforshare" inset dense></v-switch>
                              <v-btn class="mt-2 px-3"  disabled  rounded x-small v-if="!agreeforshare">
                                <v-icon class="" size="16">mdi mdi-share-variant</v-icon>
                              </v-btn>
                              <v-btn dark class="mt-2 px-3" rounded x-small v-else @click="shareto">
                                <v-icon color="white" class="" size="16">mdi mdi-share-variant</v-icon>
                              </v-btn>
  
                            </div>
                          </v-col>
  
                        </v-row>
  
                      </div>
                     
                    </v-col>
                  </v-row>
                </div> -->

            </div>
          </v-card>
        </v-col>
      </div>
      <div class="d-md-none">
        <v-overlay v-if="edisprocessloader" class="rounded-0" style="z-index:2000">
          <div class="text-center">
            <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
            <p>EDIS Processing please wait</p>
          </div>
        </v-overlay>
        <v-toolbar class="mt-6 mb-1 px-2 toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent">
            <v-list-item class="pt-0 px-0">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                      <path
                        d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                        fill="#C8C8C8" />
                      <path
                        d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                        fill="#C8C8C8" />
                      <path
                        d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                        fill="#363636" />
                      <path
                        d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                        fill="#E3E3E3" />
                      <path
                        d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                        fill="#43A6DD" />
                      <path
                        d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                        fill="#43A6DD" />
                      <path
                        d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                        fill="#43A6DD" />
                      <path
                        d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                        fill="#43A6DD" />
                      <path
                        d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                        fill="#43A6DD" />
                      <path
                        d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                        fill="#363636" />
                    </svg> -->
              <v-list-item-content class="pt-0">
                <v-list-item-title class="text-start d-flex">
                  <p class="mt-2" style="
                      color: #000000;
                      font-size: 20px;
                      font-weight: 559;
                      line-height: 16px;
                      letter-spacing: -1px;
                    ">Corporate Action</p>
                  <div class="mt-n3 ml-3">
                    <v-card v-if="poa == 'Y'" height="22" elevation="0" class="px-2 mt-4 ml-2 mr-4"
                      style="display: inline; float: right" color="#43A833">
                      <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                        POA
                      </p>
                    </v-card>
                    <v-card v-else height="22" elevation="0" class="px-2 mt-4 ml-2" style="display: inline; float: right"
                      color="red">
                      <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                        POA
                      </p>
                    </v-card>
                    <v-card v-if="ddpi == 'Y'" height="22" elevation="0" class="px-2 mt-4"
                      style="display: inline; float: right" color="#43A833">
                      <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                        DDPI
                      </p>
                    </v-card>
                    <v-card v-else height="22" elevation="0" class="px-2 mt-4" style="display: inline; float: right"
                      color="red">
                      <p class="pa-0 mb-0" style="font-size: 14px; color: white; margin-top: 2px">
                        DDPI
                      </p>

                    </v-card>
                  </div>
                </v-list-item-title>

                <v-list-item-subtitle class="text-start pt-1" style="
                      color: #666666;
                      font-size: 12px;
                      font-weight: 469;
                      line-height: 14px;
                    ">Driving strategic change through decisive corporate actions.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>


        </v-toolbar>
        <v-divider class="mx-2 mb-2"></v-divider>
        <!-- <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
            <div class="text-center pa-4">
              <p class="caption" style="
                  color: #696969;
                  letter-spacing: 0.16em;
                  text-transform: uppercase;
                ">
                Realised P&L
              </p>
              <p v-if="disrealised_pnl && disrealised_pnl < 0" class="headline ctnbtltext-center"
                style="line-height: 28px; color: #e12626">
                {{ disrealised_pnl.toFixed(2) }}
              </p>
              <p v-else-if="disrealised_pnl && disrealised_pnl > 0" class="headline ctnbtl text-center"
                style="line-height: 28px; color: #34a853">
                {{ disrealised_pnl.toFixed(2) }}
              </p>
              <p v-else class="headline ctnbtl text-center" style="line-height: 28px; color: #505359">
                0
              </p>
            </div>
            <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p class="caption" style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        ">
                        Unrealised P&L
                      </p>
                    </v-col>
                    <v-col cols="7">
                      <p v-if="disunrealised_pnl < 0 && disunrealised_pnl" class="ctnbtl body-1 text-right"
                        style="line-height: 28px; color: #e12626">
                        {{ disunrealised_pnl.toFixed(2) }}
                      </p>
                      <p v-else-if="disunrealised_pnl > 0 && disunrealised_pnl" class="ctnbtl body-1 text-right"
                        style="line-height: 28px; color: #34a853">
                        {{ disunrealised_pnl.toFixed(2) }}
                      </p>
                      <p v-else class="ctnbtl body-1 text-right" style="line-height: 28px; color: #505359">
                        0
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p class="caption" style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        ">
                        Total P&L
                      </p>
                    </v-col>
                    <v-col cols="7">
                      <span v-if="segloader" class="d-flex">
                        <v-progress-linear class="ml-auto" style="width: 30px" indeterminate
                          color="blue"></v-progress-linear>
                      </span>
                      <p v-else-if="segval < 0 && segval" class="ctnbtl body-1 text-right"
                        style="line-height: 28px; color: #e12626">
                        {{ segval.toFixed(2) }}
                      </p>
                      <p v-else-if="segval > 0 && segval" class="ctnbtl body-1 text-right"
                        style="line-height: 28px; color: #34a853">
                        {{ segval.toFixed(2) }}
                      </p>
                      <p v-else class="ctnbtl body-1 text-right" style="line-height: 28px; color: #505359">
                        0
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-card> -->
        <v-col cols="12">

          <v-tabs next-icon="mdi mdi-menu-right-outline" prev-icon="mdi mdi-menu-left-outline" show-arrows v-model="tab"
            background-color="transparent" color="basil" grow>
            <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
            <v-tab v-model="tab2">
              Buyback
            </v-tab>


            <v-tab v-model="tab3">
              Delisting
            </v-tab>

            <v-tab v-model="tab4">
              Takeover
            </v-tab>
            <v-tab v-model="tab5">
              OFS
            </v-tab>
            <!-- <v-tab v-model="tab6">
              Rights
            </v-tab> -->

            <!-- <div v-else-if="!disabletabs">
                    <v-tab v-model="tab3" @click="tabdata('Derivatives')">
                        Derivatives
                    </v-tab>
                    <v-tab v-model="tab4" @click="tabdata('Commodity')">
                        Commodity
                    </v-tab>
                    <v-tab v-model="tab5" @click="tabdata('Currency')">
                        Currency
                    </v-tab>
                    </div> -->
          </v-tabs>
        </v-col>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card v-for="item in dessertsbuyback" :key="item.index" style="border-top: 5px #f1f3f8 solid"
              class="elevation-0 px-4 pt-2" width="100%">
              <div class="d-flex">
                <span class="font-weight-bold">
                  {{ item.name
                  }}<v-icon color="#0037B7">mdi mdi-menu-right</v-icon>


                </span>

                <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                  class="ma-2 ml-auto text-capitalize" @click="orderplacedmobile(item)" small outlined rounded
                  color="black">Order</v-btn>
                <div class="pt-3  ml-auto text-center"
                  v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                  <v-btn class="ma-1 ml-auto text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                    color="red">Cancel</v-btn>
                  <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                      v-if="item.appbidqty == '1'">quantity</span><span v-else-if="item.appbidqty > '1'">quantities</span>
                    bid </p>
                  <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                </div>
              </div>

              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                    Price Offered :
                  </p>


                </v-col>
                <v-col class="text-end">

                  <p class="mb-0 body-2 font-weight-regular" style="color: #000000">
                    {{ item.cutOffPrice }}
                  </p>


                </v-col>
              </v-row>
              <p class="mb-1 ml-3 body-2 font-weight-regular" style="color: #666666">
                Start Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingStartDate
                }}</span>
                <span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.biddingStartDate
                }})</span>
              </p>
              <p class="mb-3 ml-3 body-2 font-weight-regular" style="color: #666666">
                End Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingEndDate
                }}</span><span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.dailyEndTime
}})</span>
              </p>
            </v-card>
            <v-card v-if="dessertsbuyback.length == 0 && loader == false" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>

          </v-tab-item>
          <v-tab-item>
            <v-card v-for="item in dessertsdelist" :key="item.index" style="border-top: 5px #f1f3f8 solid"
              class="elevation-0 px-4 pt-2" width="100%">
              <div class="d-flex">
                <span class="font-weight-bold">
                  {{ item.name
                  }}<v-icon color="#0037B7">mdi mdi-menu-right</v-icon>


                </span>

                <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                  class="ma-2 ml-auto text-capitalize" @click="orderplacedmobile(item)" small outlined rounded
                  color="black">Order</v-btn>
                <div class="pt-3  ml-auto text-center"
                  v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                  <v-btn class="ma-1 ml-auto text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                    color="red">Cancel</v-btn>
                  <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                      v-if="item.appbidqty == '1'">quantity</span><span v-else-if="item.appbidqty > '1'">quantities</span>
                    bid </p>
                  <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                </div>
              </div>

              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                    Price Offered :
                  </p>


                </v-col>
                <v-col class="text-end">

                  <p class="mb-0 body-2 font-weight-regular" style="color: #000000">
                    {{ item.cutOffPrice }}
                  </p>


                </v-col>
              </v-row>
              <p class="mb-1 ml-3 body-2 font-weight-regular" style="color: #666666">
                Start Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingStartDate
                }}</span>
                <span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.biddingStartDate
                }})</span>
              </p>
              <p class="mb-3 ml-3 body-2 font-weight-regular" style="color: #666666">
                End Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingEndDate
                }}</span><span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.dailyEndTime
}})</span>
              </p>
            </v-card>
            <v-card v-if="dessertsdelist.length == 0 && loader == false" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>

          </v-tab-item>
          <v-tab-item>
            <v-card v-for="item in dessertstake" :key="item.index" style="border-top: 5px #f1f3f8 solid"
              class="elevation-0 px-4 pt-2" width="100%">
              <div class="d-flex">
                <span class="font-weight-bold">
                  {{ item.name
                  }}<v-icon color="#0037B7">mdi mdi-menu-right</v-icon>


                </span>
                <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                  class="ma-2 ml-auto text-capitalize" @click="orderplacedmobile(item)" small outlined rounded
                  color="black">Order</v-btn>
                <div class="pt-3  ml-auto text-center"
                  v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                  <v-btn class="ma-1 ml-auto text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                    color="red">Cancel</v-btn>
                  <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                      v-if="item.appbidqty == '1'">quantity</span><span v-else-if="item.appbidqty > '1'">quantities</span>
                    bid </p>
                  <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                </div>
              </div>

              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                    Price Offered :
                  </p>


                </v-col>
                <v-col class="text-end">

                  <p class="mb-0 body-2 font-weight-regular" style="color: #000000">
                    {{ item.cutOffPrice }}
                  </p>


                </v-col>
              </v-row>
              <p class="mb-1 ml-3 body-2 font-weight-regular" style="color: #666666">
                Start Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingStartDate
                }}</span>
                <span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.biddingStartDate
                }})</span>
              </p>
              <p class="mb-3 ml-3 body-2 font-weight-regular" style="color: #666666">
                End Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingEndDate
                }}</span><span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.dailyEndTime
}})</span>
              </p>
            </v-card>
            <v-card v-if="dessertstake.length == 0 && loader == false" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>


            <v-card v-if="loader" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>

          </v-tab-item>
          <v-tab-item>
            <v-card v-for="item in dessertsofs" :key="item.index" style="border-top: 5px #f1f3f8 solid"
              class="elevation-0 px-4 pt-2" width="100%">
              <div class="d-flex">
                <span class="font-weight-bold">
                  {{ item.name
                  }}<v-icon color="#0037B7">mdi mdi-menu-right</v-icon>


                </span>
                <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                  class="ma-2 ml-auto text-capitalize" @click="orderplacedmobile(item)" small outlined rounded
                  color="black">Order</v-btn>
                <div class="pt-3  ml-auto text-center"
                  v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                  <v-btn class="ma-1 ml-auto text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                    color="red">Cancel</v-btn>
                  <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                      v-if="item.appbidqty == '1'">quantity</span><span v-else-if="item.appbidqty > '1'">quantities</span>
                    bid </p>
                  <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                </div>
              </div>

              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                    Price Offered :
                  </p>


                </v-col>
                <v-col class="text-end">

                  <p class="mb-0 body-2 font-weight-regular" style="color: #000000">
                    {{ item.cutOffPrice }}
                  </p>


                </v-col>
              </v-row>
              <p class="mb-1 ml-3 body-2 font-weight-regular" style="color: #666666">
                Start Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingStartDate
                }}</span>
                <span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.biddingStartDate
                }})</span>
              </p>
              <p class="mb-3 ml-3 body-2 font-weight-regular" style="color: #666666">
                End Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingEndDate
                }}</span><span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.dailyEndTime
}})</span>
              </p>
            </v-card>
            <v-card v-if="dessertsofs.length == 0 && loader == false" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>

          </v-tab-item>
          <v-tab-item>
            <v-card v-for="item in dessertstrights" :key="item.index" style="border-top: 5px #f1f3f8 solid"
              class="elevation-0 px-4 pt-2" width="100%">
              <div class="d-flex">
                <span class="font-weight-bold">
                  {{ item.name
                  }}<v-icon color="#0037B7">mdi mdi-menu-right</v-icon>


                </span>
                <v-btn v-if="item.statusorder != 'pending' && item.statusorder != 'success'"
                  class="ma-2 ml-auto text-capitalize" @click="orderplacedmobile(item)" small outlined rounded
                  color="black">Order</v-btn>
                <div class="pt-3  ml-auto text-center"
                  v-if="item.statusorder == 'pending' || item.statusorder == 'success'">
                  <v-btn class="ma-1 ml-auto text-capitalize" @click="cancelorderpop(item)" small outlined rounded
                    color="red">Cancel</v-btn>
                  <p class="mb-0 font-weight-regular " style="font-size: 10px">{{ item.appbidqty }} <span
                      v-if="item.appbidqty == '1'">quantity</span><span v-else-if="item.appbidqty > '1'">quantities</span>
                    bid </p>
                  <!-- <p class="caption mb-0  red--text" style=" cursor: pointer" @click="cancelorderpop(item)"> Cancel
    Bid</p> -->

                </div>
              </div>

              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row class="px-2">
                <v-col>
                  <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                    Price Offered :
                  </p>


                </v-col>
                <v-col class="text-end">

                  <p class="mb-0 body-2 font-weight-regular" style="color: #000000">
                    {{ item.cutOffPrice }}
                  </p>


                </v-col>
              </v-row>
              <p class="mb-1 ml-3 body-2 font-weight-regular" style="color: #666666">
                Start Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingStartDate
                }}</span>
                <span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.biddingStartDate
                }})</span>
              </p>
              <p class="mb-3 ml-3 body-2 font-weight-regular" style="color: #666666">
                End Date
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.biddingEndDate
                }}</span><span class="mb-0 caption font-weight-regular" style="color: #666666">({{ item.dailyEndTime
}})</span>
              </p>
            </v-card>
            <v-card v-if="dessertstrights.length == 0 && loader == false" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>

            <v-card v-if="loader" style="
              border-top: 5px #f1f3f8 solid;
              border-bottom: 5px #f1f3f8 solid;
            " class="elevation-0 px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-10">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>

          </v-tab-item>
        </v-tabs-items>


        <!-- </v-tab-item> -->
        <!-- <v-tab-item>
                          <v-card v-for="item in filteredDesserts1 " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                              class="elevation-0  px-4" width="100%">
  
                              <p v-if="item.COMPANY_CODE == 'NSE_FNO'" class="text-start pa-2 pb-0 mb-0"
                                  style="color: #000000;font-size: 15px;font-weight:459;">{{ item.SCRIP_SYMBOL }}
                              </p>
                              <p v-else-if="item.COMPANY_CODE == 'BSE_CASH' || item.COMPANY_CODE == 'NSE_CASH'"
                                  class="text-start pb-0 pa-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                  {{ item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}
                              </p>
                              <span class="font-weight-regular pt-0 caption text-start pa-2 mb-0"> {{ item.TRADE_DATE
                              }}</span>
                              <v-divider class="mb-2"></v-divider>
  
                              <v-row class="px-2">
                                  <v-col>
                                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount:
                                      </p>
                                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BQTY
                                              }}</span>
                                      </p>
                                      <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                  (item.BRATE.toFixed(2)) }}</span>
                                      </p>
  
  
                                  </v-col>
                                  <v-col class="text-end">
                                      <p v-if="item.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">
                                          {{ item.NETAMT }}</p>
                                      <p v-if="item.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                          item.NETAMT }}</p>
                                      <p v-if="item.NETAMT == 0" class="mb-0 body-2 font-weight-regular"
                                          style="color: #000000;">
                                          {{ item.NETAMT }}</p>
  
                                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SQTY }}
  
                                          </span>
                                      </p>
                                      <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                              class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                  (item.SRATE).toFixed(2) }}
                                          </span>
                                      </p>
  
  
                                  </v-col>
                              </v-row>
                          </v-card>
                          <v-card v-if="filteredDesserts1.length == 0 && loader == false"
                              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                              class="elevation-0  px-4" width="100%">
                              <v-row class="px-2">
                                  <v-col class="mx-auto text-center my-4">
                                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                      <p class="font-weight-bold black--text">No data available</p>
                                  </v-col>
                              </v-row>
                          </v-card>
                          <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                              class="elevation-0  px-4" width="100%">
                              <v-row class="px-2">
                                  <v-col class="mx-auto text-center my-4">
                                      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-tab-item>
                  </v-tabs-items> -->
      </div>
    </v-container>
  </v-app>
</template>
  
<script>
// import CalendarProfitlose from '@/views/heatmap calendars/CalendarProfitlose.vue';
import { apiurlipo } from "../../../Api.js";
import { apiurl } from '../../../Api.js'
// import { sha256 } from "js-sha256";
import axios from "axios"

// import b64u from "base64url";
// import { apidailypnl } from "../../../Api.js";
// import exportFromJSON from "export-from-json";
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
// import html2canvas from 'html2canvas';


export default {
  data() {
    return {
      checkordercoparray: [],
      visible: false,
      tradingpassword: '',
      isinEdisprocess: '',
      bidvalueuser: '',
      pricevalueuser: '',
      dialogmobile: false,
      dialoguorder: false,
      dialoguorderights : false,
      username: '',
      segval: 0,
      summary: true,
      radios: null,
      radios1: null,
      inject: {
        theme: {
          default: { isDark: false },
        },
      },
      itemss: [],
      search: "",
      searchde: "",
      expanded: [],
      more: true,
      less: false,
      more1: true,
      less1: false,
      itemperpage: 8,
      // msg: "",

      // alert: false,

      sheet: false,
      sheet1: false,
      sheetforshare: false,
      itemperpage1: 8,
      loader: true,
      headerval: [],
      ordertype : '',
      dialog: false,
      dialogcopytext: false,
      qtyhave: 0,
      detailedpoparray: [],

      yearindex: "",
      search1: "",

      itemperpage1inner: 0,
      // tab: null,
      // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
      desserts: [],
      dessertsoriginal: [],
      tab1: null,
      tab2: null,
      tab3: null,
      tab4: null,
      tab5: null,
      tab6: null,
      scriptname: "",
      dessertspop: [],
      dessertspopexpense: [],
      errorMessageswrongpassword: '',
      errmsgforofs : '',
      desserts2: [],
      uniqueCode: '',
      sortBy: "SCRIP_NAME",
      offset: "",
      segfrom: "",
      dates: [""],
      roughtdata: '',
      filterr: [
        "All",
        "Equities",
        "Future & Options",
        "Commodities",
        "Currencies",
      ],
      filterdata_unpl: "All",
      orderformobile: false,
      filterdata_unpl1: "All",
      dessertsbuyback: [],
      dessertsdelist: [],
      dessertstake: [],
      dessertsofs: [],
      dessertstrights: [],
      itemsdwl: [
        {
          title: "Download Excel",
        },
        {
          title: "Download Pdf",
        },
      ],
      show: false,
      alert: false,
      ledgerbalance : 0,
      alert1: false,
      msg: '',
      cancelarraypop: [],

      // Overviewheaders2: [
      //   {
      //     text: "Trade Date",
      //     value: "TRADE_DATE",
      //     width: "10%",
      //     class: "headerfont",
      //   },

      //   // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },

      //   {
      //     text: "Buy Qty",
      //     value: "BQTY",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      //   {
      //     text: "Buy Rate",
      //     value: "BRATE",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      //   // { text: 'Buy Amt', value: 'BAMT', align: "end" },
      //   {
      //     text: "Sell Qty",
      //     value: "SQTY",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      //   {
      //     text: "Sell Rate",
      //     value: "SRATE",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      //   // { text: 'Sale Amt', value: 'SAMT', align: "end" },
      //   {
      //     text: "Net Qty",
      //     value: "NETQTY",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      //   {
      //     text: "Net Rate",
      //     value: "NRATE",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      //   // { text: 'NET Amt', value: 'NETAMT', align: "end" },
      //   // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
      //   {
      //     text: "P & L",
      //     value: "NETAMT",
      //     align: "end",
      //     class: "headerfont",
      //     width: "10%",
      //   },
      // ],
      finaltotal: 0,
      errorMessagesupiid : '',
      Overviewheaders: [
        { text: "Symbol", value: "name", class: "headerfont" },
        // { text: "com", value: "Sort_No", class: "headerfont", },
        // { text: "comcode", value: "COMPANY_CODE", class: "headerfont", },
        // { text: '', value: 'data-table-expand', class: "headerfont" },
        // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
        // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
        //   {
        //   text: "Exchange",
        //   value: "type",
        //   align: "start",
        //   class: "headerfont",
        // },

        // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
        {
          text: "Start Date",
          value: "opendatetime",
          align: "start",
          class: "headerfont",
        },
        {
          text: "End Date",
          value: "closedatetime",
          align: "start",
          class: "headerfont",
        },
        // // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
        // {
        //   text: "Record Date",
        //   value: "rpnl",
        //   align: "end",
        //   class: "headerfont",
        // },
        // { text: "Net Rate", value: "", align: "end", class: "headerfont" },
        // { text: "Close price", value: "", align: "end", class: "headerfont" },
        // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
        // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        // {
        //   text: "Cut Off Time",
        //   value: "NOT_PROFIT",
        //   align: "end",
        //   class: "headerfont",
        // },
        {
          text: "Price Offered",
          value: "cutOffPrice",
          align: "end",
          class: "headerfont",
        },
        {
          text: "",
          value: "buyorder",
          align: "center",
          class: "headerfont",
        },
        // {
        //   text: "",
        //   value: "cancelorder",
        //   align: "end",
        //   class: "headerfont",
        // },
      ],
      Overviewheaders2: [
        { text: "Symbol", value: "name", class: "headerfont" },
        // { text: "com", value: "Sort_No", class: "headerfont", },
        // { text: "comcode", value: "COMPANY_CODE", class: "headerfont", },
        // { text: '', value: 'data-table-expand', class: "headerfont" },
        // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
        // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
        //   {
        //   text: "Exchange",
        //   value: "type",
        //   align: "start",
        //   class: "headerfont",
        // },

        // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
        {
          text: "Series",
          value: "issueType",
          align: "start",
          class: "headerfont",
        },
        {
          text: "Issue size",
          value: "issueSize",
          align: "start",
          class: "headerfont",
        },
        {
          text: "Base Price",
          value: "baseprice",
          align: "start",
          class: "headerfont",
        },
        {
          text: "Open date",
          value: "openondate",
          align: "start",
          class: "headerfont",
        },

        // {
        //   text: "End Date",
        //   value: "closedatetime",
        //   align: "start",
        //   class: "headerfont",
        // },
        // // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
        // {
        //   text: "Record Date",
        //   value: "rpnl",
        //   align: "end",
        //   class: "headerfont",
        // },
        // { text: "Net Rate", value: "", align: "end", class: "headerfont" },
        // { text: "Close price", value: "", align: "end", class: "headerfont" },
        // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
        // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        // {
        //   text: "Cut Off Time",
        //   value: "NOT_PROFIT",
        //   align: "end",
        //   class: "headerfont",
        // },
        // {
        //   text: "Price Offered",
        //   value: "cutOffPrice",
        //   align: "end",
        //   class: "headerfont",
        // },
        {
          text: "",
          value: "buyorder",
          align: "center",
          class: "headerfont",
        },
        // {
        //   text: "",
        //   value: "cancelorder",
        //   align: "end",
        //   class: "headerfont",
        // },
      ],
      tab: null,

      items: ["Summary", "Detailed"],
      desserts23: [],
      sortKey: "Sort_No",
      detpop: [],
      actid: "",
      susertoken: "",
      loaderpopup: false,
      keyyy: "",
      cancelconform: false,
      downsum: true,
      disrealised_pnl: "",
      disunrealised_pnl: "",
      ddpi: '',
      poa: '',
      year: "",
      dessertstaxdis: [],
      menu2: false,
      btnloader: false,
      menu23: false,
      panno: '',
      todate: "",
      fromdate: "",
      segloader: false,
      fromshow: "",
      mobilescreen: false,
      sortOrder: 1, // 1 for ascending, -1 for descending
      originalrea: 0,
      originalunrea: 0,
      maxval: '',
      ofscutOffPrice: '',
      switchval: '',
      agreeforshare: false,
      shareresdata: [],
      quantityapproveedit: 0,
      edisbtnorder: false,
      edisapprove: 'false',
      dataforedischeck: [],
      alertwrongpassword: false,
      disabledforblocked: false,
      msgtext: '',
      text1: 'kgghkhkhh',
      inverstvalueofs : 0,
      postalready: null,
      ofsdisable : false,
      ofsbidvalueuser : '',
      postalreadystats: '',
      postalreadyucode: '',
      switch1: true,
      edisprocessloader: false,
      switch2: false,
      ofspricevalueuser : '',
      edisbtn: false,
      issuetype: '',
      opendatetime: '',
      closedatetime: '',
      cutOffPrice: '',
      maxbidqty: 0,
      minBidQuantity: 0,
      syname: '',
      biddingEndDate: '',
      biddingStartDate: '',
      dailyStartTime: '',
      dialogmyntpasschange: false,
      dailyEndTime: '',
      minPrice: '',
      maxPrice: '',
      orderquality: '',
      steplot: 0,
      clearintervaledis: false,
      checkbox: false,
      pricetextdisabled: false,
      dialogmyntedis: false,
      inverstvalue: '',
      apisymbol: '',
      quantityminedis: 0,
      quantitymaxedis: 0,
      // inverstvalue : '',
      apitype: '',
      codeforedis: '',
      upiidtype: '',
      redirecttoedit: '',
      hstoken: '',
      upiproviderselect: '',
      orderqualitystat: '',
      upiprovider: ["@allbank",
        '@aubank',
        '@axisbank',
        '@axl',
        '@barodampay', '@bandhan',
        '@citi',
        '@citigold', '@dbs',
        '@dlb',
        '@federal',
        '@freecharge',
        '@hsbc',
        '@icici',
        '@idbi',
        '@indus',
        '@ibl',
        '@kbl',
        '@kotak',
        '@okaxis',
        '@okhdfcbank',
        '@okicici',
        '@oksbi',
        '@paytm', '@rbl', '@sbi',
        '@sib',
        '@upi',
        '@uco',
        '@ybl', '@yesbank',
        '@pz',
        '@axisb',
        '@zoicici',
        '@wasbi',
        '@wahdfcbank',
        '@waaxis',
        '@waicici',
        '@idfcbank', '@timecosmos',
        '@tapicici', '@sliceaxis', '@shriramhdfcbank',
        '@pingpay', '@NIYOICICI', '@naviaxis', '@ikwik', '@goaxb',
        '@jupiteraxis', '@yesg', '@abfspay', '@abfspay', '@rapl', '@yapl', '@apl', '@slice', '@mbk', '@pockets', '@idfcpay', '@amazonpay', '@spicepay', '@omni', '@dhani', '@bpaywallet', '@trans', '@fam', '@pinelabs', '@digikhata', '@oxymoney',




      ],
      apprice: '',
      appbidqty: '',
      appno: '',
      sheetyear: false,
      wrongmsgtxt: false,
      textfieldtype: 'password',
      iconforpassword: 'mdi mdi-eye-outline',
    };
  },
  // components: { CalendarProfitlose },
  computed: {
    // sortedItems() {
    //   return this.dessertsoriginal.slice().sort((a, b) => {
    //     const modifier = this.sortOrder;
    //     return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
    //   });
    // },
    // dateRangeText() {
    //   return this.dates.join(" ~ ");
    // },
    // filteredDesserts() {
    //   const searchTerm = this.search.toLowerCase();
    //   return this.desserts.filter((item) =>
    //     item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm)
    //   );
    // },
    // filteredDesserts1() {
    //   const searchTerm = this.searchde.toLowerCase();
    //   return this.desserts23.filter((item) =>
    //     item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm)
    //   );
    // },
  },
  created() {
    // Perform default sort on component creation
    // this.performDefaultSort();
    //  let dydyd = localStorage.getItem("side");
    // if (dydyd == 'process') {
    // alert("jkjkjkj")
    // }
  },
  methods: {
    unblockid() {
      // window.open('');
      window.location.href = "https://go.mynt.in/";
    },
    iconnn() {
      if (this.textfieldtype == 'text') {
        this.textfieldtype = 'password'
        this.iconforpassword = 'mdi mdi-eye-outline'
      } else if (this.textfieldtype == 'password') {
        this.textfieldtype = 'text'
        this.iconforpassword = 'mdi mdi-eye-off'
      }
    },
    passinput() {
      if (this.tradingpassword.length < 0) {
        this.errorMessageswrongpassword = 'Password required'
        // console.log('less')

      } else if (this.tradingpassword.length > 0) {
        this.errorMessageswrongpassword = ''
        // console.log('greater')

      }
    },
    reloadpage() {
      this.edisredirect()
    },
    validateUpiId() {
      const upiIdRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]/;
      if (!upiIdRegex.test(this.upiidtype)) {
        this.errorMessagesupiid = 'Invalid UPI ID format';
      } else {
        this.errorMessagesupiid = ""
      }
      
    },
    edisredirect() {
      // this.synameEdisprocess = syname
      if (this.tradingpassword != '') {
        // Sarbvc#7337
        // const axios = require('axios');
        this.edisbtn = true
        let data = JSON.stringify({
          "clientid": this.actid,
          "password": this.tradingpassword
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://copy.mynt.in/get_session',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        let axiosthis = this
        axios.request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            const axios = require('axios');

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://go.mynt.in/NorenWClient/ValidateHsToken?LoginId=ADMIN&token=null',
              headers: {}
            };

            axios.request(config)
              .then((response) => {
                // console.log(JSON.stringify(response.data));
                this.roughtdata = response
              })
              .catch((error) => {
                console.log(error);
              });

            var resdata = response.data
            if (resdata.stat == 'Ok') {
              var apitkforhs = response.data.apitoken
              // const axios = require('axios');
              let data = JSON.stringify({
                "clientid": axiosthis.actid,
                "apitoken": apitkforhs
              });

              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://copy.mynt.in/gen_hsToken',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: data
              };

              axios.request(config)
                .then((response) => {
                  // console.log(JSON.stringify(response.data));
                  axiosthis.hstoken = response.data.hstk
                  var CryptoJS = require("crypto-js");
                  axiosthis.edisbtn = false
                  if (axiosthis.hstoken) {
                    let beforehas = CryptoJS.enc.Utf8.parse(
                      `sLoginId=${axiosthis.actid}&sAccountId=${axiosthis.actid}&prd=C&token=${axiosthis.hstoken}&sBrokerId=ZEBU&open=edis`
                    );
                    axiosthis.codeforedis = CryptoJS.enc.Base64.stringify(beforehas);
                    // console.log(axiosthis.codeforedis)
                    axiosthis.redirecttoedit = axiosthis.codeforedis
                    // axiosthis.dialogmyntedis = true
                    // localStorage.setItem("side", 'process');
                    axiosthis.edisprocessloader = true
                    axiosthis.dialoguorder = false
                    axiosthis.orderformobile = false
                    axiosthis.tradingpassword = ''
                    axiosthis.edisprocesss()
                    this.clearintervaledis = setInterval(() => {
                      axiosthis.edisprocesss()
                    }, 5000);
                    window.open(
                      `https://go.mynt.in/NorenEdis/NonPoaHoldings/?${axiosthis.codeforedis},
              "_blank"`
                    );

                  }
                })
                .catch((error) => {
                  console.log(error);
                });

            } else if (resdata.emsg == 'Invalid Input : Wrong Password') {
              if (response.data.emsg == 'Invalid Input : Wrong Password') {
                axiosthis.errorMessageswrongpassword = 'Wrong password try again'
                // axiosthis.wrongmsgtxt = true
                axiosthis.alertwrongpassword = true
                axiosthis.msg = 'Wrong Password'
                axiosthis.edisbtn = false
                axiosthis.disabledforblocked = false
                setTimeout(() => {
                  axiosthis.alertwrongpassword = false
                  axiosthis.msg = ''
                }, 3000);
              }

            } else if (resdata.emsg == 'Invalid Input : User Blocked due to multiple wrong attempts') {
              // axiosthis.wrongmsgtxt = false
              axiosthis.alertwrongpassword = true
              axiosthis.disabledforblocked = true
              // alert('fghjk')
              axiosthis.errorMessageswrongpassword = 'Account Blocked'

              axiosthis.msg = 'Account Blocked'
              axiosthis.edisbtn = false

              setTimeout(() => {
                axiosthis.alertwrongpassword = false
                axiosthis.msg = ''
              }, 3000);
            } else {
              this.edisbtn = false
            }
          })
          .catch((error) => {
            console.log(error);
          });

      } else {
        this.errorMessageswrongpassword = 'Password required'

      }

      // var shapass = sha256(this.tradingpassword);
      // var apikey = '|Apikey18012023zebu'
      // var addak = this.actid + apikey;
      // var appkey = sha256(addak);

      // var data = `jData={"uid":"${this.actid}","pwd":"${shapass}","factor2":"${this.panno}","apkversion":"20220921","imei":"","vc":"PRO_MYNT","appkey":"${appkey}","source":"API"}`;
      // var config = {
      //   method: "post",
      //   url: 'https://go.mynt.in/NorenWClientTP/QuickAuth',
      //   headers: {
      //     'Content-Type': 'text/plain',
      //     'Cookie': 'NWC_ID=3e03100f85c0155ffb62d5d9bcc0ee273e804c489e430ddb9e00eda1aab50f54'
      //   },
      //   data: data
      // };
      // axios.request(config)
      //   .then((response) => {
      //     console.log(JSON.stringify(response.data.susertoken, "jhjhjh"));
      //     var sessforhs = response.data.susertoken
      //     // const axios = require('axios');
      //     let data = `jData={"uid":"${this.actid}"}&jKey=${sessforhs}`;

      //     let config = {
      //       method: 'post',
      //       maxBodyLength: Infinity,
      //       url: 'https://go.mynt.in/NorenWClientTP/GetHsToken',
      //       headers: {
      //         'Content-Type': 'text/plain'
      //       },
      //       data: data
      //     };

      //     axios.request(config)
      //       .then((response) => {
      //         console.log(JSON.stringify(response.data, "dada"));
      //         this.hstoken = response.data.hstk
      //         var CryptoJS = require("crypto-js");

      //         if (this.hstoken) {
      //           let beforehas = CryptoJS.enc.Utf8.parse(
      //             `sLoginId=${this.actid}&sAccountId=${this.actid}&prd=C&token=${this.hstoken}&sBrokerId=ZEBU&open=edis`
      //           );
      //           let codeforedis = CryptoJS.enc.Base64.stringify(beforehas);
      //           window.open(
      //             `https://go.mynt.in/NorenEdis/NonPoaHoldings/?${codeforedis},
      //         "_blank"`
      //           );
      //         }
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //       });

      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    cancelorder() {
      this.btnloader = true
      // console.log(this.appno,"kjkjkjkj");
      // const axios = require('axios');
      let data 
      if (this.issuetype == 'IS' || this.issuetype == 'RS' ) {
        data = JSON.stringify(
          {
          "symbol":this.apisymbol,
          "exchange":this.apitype,
          "activityType":"CR",
          "series":this.issuetype,
          "applicationNo":this.appno,
          "price": this.ofscutOffPrice

          }


      );
      }else{
      data = JSON.stringify(
        {
          "exchange": this.apitype,
          "symbol": this.apisymbol,
          "series": this.issuetype,
          "activityType": "CR",
          "bidQuantity": this.appbidqty,
          "price": this.apprice,
          "applicationNo": this.appno,

        }

      );
      }
      let axiosthis = this
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.122:9005/addIPOtoPortfolio',
        url: `${apiurlipo}/addCorporateAction`,

        headers: {
          'Authorization': axiosthis.susertoken,
          'clientid': axiosthis.actid,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == 'success') {
            const axios = require('axios');
            let data = '';

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${apiurlipo}/fetchorderdetailcorp`,

              // url: 'http://192.168.5.122:9005/fetchorderdetailcorp',
              headers: {
                'Authorization': this.susertoken,
                'clientid': this.actid
              },
              data: data
            };
            let axiosthis = this
            axios.request(config)
              .then((response) => {
                // console.log((response.data));
                var holddata = response.data.msg
                if (holddata != 'No data') {
                  for (let i = 0; i < holddata.length; i++) {
                    const holdSymbol = holddata[i].symbol;

                    // Check if there is a corresponding dessert with the same symbol
                    const matchingDessert = axiosthis.desserts.find(dessert => dessert.symbol === holdSymbol);

                    if (matchingDessert) {
                      matchingDessert.statusorder = holddata[i].status;
                      matchingDessert.appno = holddata[i].application_no;
                      matchingDessert.appbidqty = holddata[i].bid_quan;
                      matchingDessert.apprice = holddata[i].price;
                    }
                  }

                  // Reset properties for desserts without a match
                  axiosthis.desserts.forEach(dessert => {
                    if (!holddata.some(hold => hold.symbol === dessert.symbol)) {
                      dessert.statusorder = '';
                      dessert.appno = '';
                      dessert.appbidqty = '';
                      dessert.apprice = '';
                    }
                  });
                  axiosthis.btnloader = false

                  axiosthis.cancelconform = false
                } else {
                  axiosthis.desserts.forEach(dessert => {

                    dessert.statusorder = '';
                    dessert.appno = '';
                    dessert.appbidqty = '';
                    dessert.apprice = '';

                  });
                  axiosthis.dessertsbuyback = []
                  axiosthis.dessertsdelist = []
                  axiosthis.dessertstake = []
                  axiosthis.dessertsofs = []
                  axiosthis.dessertstrights = []
                  const axios = require('axios');
                  let dataedis = JSON.stringify({
                    "isin": this.dataforedischeck,
                    "clientid": this.actid
                  });

                  let configedis = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: 'http://192.168.5.79:9003/EdisReport',
                    url: `${apiurlipo}/EdisReport`,

                    headers: {
                      'Content-Type': 'application/json'
                    },
                    data: dataedis
                  };

                  axios.request(configedis)
                    .then((response) => {
                      // console.log(JSON.stringify(response.data));
                      let edisdata = response.data.data
                      for (let i = 0; i < edisdata.length; i++) {
                        for (let u = 0; u < axiosthis.desserts.length; u++) {
                          // console.log(edisdata[i].isin == axiosthis.desserts[u].isin,edisdata[i].isin,axiosthis.desserts[u].isin,'damdam')
                          if (edisdata[i].isin == axiosthis.desserts[u].isin) {
                            if (edisdata[i].qty > 0) {
                              axiosthis.desserts[u].edisqtyapprove = edisdata[i].qty
                              axiosthis.desserts[u].edisapprove = 'true'
                              axiosthis.desserts[u].edisminqty = 1
                              axiosthis.desserts[u].edisMaxqty = edisdata[i].qty
                              axiosthis.edisapprove = 'true'

                            } else {
                              axiosthis.desserts[u].edisqtyapprove = 0
                              axiosthis.desserts[u].edisapprove = 'false'
                              axiosthis.desserts[u].edisminqty = ''
                              axiosthis.desserts[u].edisMaxqty = ''
                              axiosthis.edisapprove = 'false'
                            }

                          }
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  for (let i = 0; i < axiosthis.desserts.length; i++) {
                    if (axiosthis.desserts[i].issueType == 'BUYBACK' || axiosthis.desserts[i].issueType == 'BB') {
                      axiosthis.dessertsbuyback.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'DELISTING' || axiosthis.desserts[i].issueType == 'DS') {
                      axiosthis.dessertsdelist.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'TAKEOVER' || axiosthis.desserts[i].issueType == 'TO') {
                      axiosthis.dessertstake.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'IS' ||axiosthis.desserts[i].issueType == 'RS') {
                      axiosthis.dessertsofs.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'RIGHTS') {
                      axiosthis.dessertstrights.push(axiosthis.desserts[i])
                    }
                  }
                  axiosthis.btnloader = false

                  axiosthis.cancelconform = false
                }


                axiosthis.cancelarraypop = []

                // console.log(axiosthis.desserts,'val');

                // for (let i = 0; i < axiosthis.checkordercoparray.length; i++) {

                // }
              })
              .catch((error) => {

                console.log(error);
                if (error.message == 'Network Error') {
                  this.msg = error.message
                  axiosthis.loader = false
                  axiosthis.alert1 = true
                  setTimeout(() => {
                    axiosthis.alert1 = false
                  }, 5000);
                }
              });
            // axiosthis.mountedcop()
            axiosthis.alert = true
            axiosthis.msg = 'Order cancelled'
            setTimeout(() => {
              axiosthis.msg = ''
              axiosthis.alert = false
            }, 3000);


          } else if (response.data.msg == 'error occured on data fetch') {
            axiosthis.btnloader = false
            axiosthis.dialoguorder = false
            axiosthis.orderformobile = false
            axiosthis.alert1 = true
            axiosthis.msg = 'Error occured on data fetch'
            setTimeout(() => {
              axiosthis.msg = ''
              axiosthis.alert1 = false
            }, 3000);
          }
          axiosthis.dialoguorder = false
          axiosthis.orderformobile = false
        })
        .catch((error) => {
          console.log(error);
          if (error.message == 'Network Error') {
            this.msg = error.message
            axiosthis.loader = false
            axiosthis.alert1 = true
            setTimeout(() => {
              axiosthis.alert1 = false
            }, 5000);
          }
        });
    },
    orderapply() {
      if ((this.errorMessagesupiid == '' && this.pricevalueuser != '' && this.bidvalueuser != '' && this.upiidtype !== '' && this.issuetype === 'RIGHTS') || (['BUYBACK', 'DELISTING', 'TO', 'BB', 'DS', 'TO'].includes(this.issuetype) && this.bidvalueuser != '' && (this.bidvalueuser >= this.minBidQuantity)  && this.pricevalueuser != '' && this.pricevalueuser >= this.minPrice) || ( ['IS' , 'RS'].includes(this.issuetype) && this.ofspricevalueuser != '' && this.ofsbidvalueuser != '')) {
        this.btnloader = true

        // const axios = require('axios');
        let data;
        if (this.issuetype == 'IS' || this.issuetype == 'RS') {
          data = JSON.stringify(
            {
            "symbol":this.apisymbol,
            "exchange":this.apitype,
            "activityType":"ER",
            "series":this.issuetype,
            "cutOffPrice":false,
            "quantity":parseFloat(this.ofsbidvalueuser),
            "price": parseFloat(this.ofspricevalueuser)
            }
        );
        }else if (this.issuetype == 'RIGHTS') {
          data = JSON.stringify( {
        "symbol": this.apisymbol,
        "UPI": this.upiidtype,
        "category": "IND",
        "company_name": this.syname,
        "exchange":this.apitype,
        "BID": [
            {
                "activityType": "new",
                "quantity": parseFloat(this.bidvalueuser),
                "price":  this.minPrice,
                "amount": this.inverstvalue,
                "atCutOff": this.checkbox,
                "remark": "BD/000001"
            }
        ]
}
          )

        }else{
          data = JSON.stringify(
          {
            "exchange": this.apitype,
            "symbol": this.apisymbol,
            "series": this.issuetype,
            "activityType": "ER",
            "bidQuantity": parseFloat(this.bidvalueuser),
            "price": parseFloat(this.pricevalueuser)
          }
        );
        }
      
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          // url: 'http://192.168.5.122:9005/addIPOtoPortfolio',
          url: `${apiurlipo}/addCorporateAction`,

          headers: {
            'Authorization': axiosthis.susertoken,
            'clientid': axiosthis.actid,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            if (response.data.msg == 'success') {
              const axios = require('axios');
              let data = '';

              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${apiurlipo}/fetchorderdetailcorp`,

                // url: 'http://192.168.5.122:9005/fetchorderdetailcorp',
                headers: {
                  'Authorization': this.susertoken,
                  'clientid': this.actid
                },
                data: data
              };
              let axiosthis = this
              axios.request(config)
                .then((response) => {
                  // console.log((response.data));
                  var holddata = response.data.msg
                  for (let i = 0; i < holddata.length; i++) {
                    const holdSymbol = holddata[i].symbol;

                    // Check if there is a corresponding dessert with the same symbol
                    const matchingDessert = axiosthis.desserts.find(dessert => dessert.symbol === holdSymbol);

                    if (matchingDessert) {
                      matchingDessert.statusorder = holddata[i].status;
                      matchingDessert.appno = holddata[i].application_no;
                      matchingDessert.appbidqty = holddata[i].bid_quan;
                      matchingDessert.apprice = holddata[i].price;
                    }
                  }

                  // Reset properties for desserts without a match
                  axiosthis.desserts.forEach(dessert => {
                    if (!holddata.some(hold => hold.symbol === dessert.symbol)) {
                      dessert.statusorder = '';
                      dessert.appno = '';
                      dessert.appbidqty = '';
                      dessert.apprice = '';
                    }
                  });
                  axiosthis.dessertsbuyback = []
                  axiosthis.dessertsdelist = []
                  axiosthis.dessertstake = []
                  axiosthis.dessertsofs = []
                  axiosthis.dessertstrights = []
                  const axios = require('axios');
                  let dataedis = JSON.stringify({
                    "isin": this.dataforedischeck,
                    "clientid": this.actid
                  });

                  let configedis = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: 'http://192.168.5.79:9003/EdisReport',
                    url: `${apiurlipo}/EdisReport`,

                    headers: {
                      'Content-Type': 'application/json'
                    },
                    data: dataedis
                  };

                  axios.request(configedis)
                    .then((response) => {
                      // console.log(JSON.stringify(response.data));
                      let edisdata = response.data.data
                      for (let i = 0; i < edisdata.length; i++) {
                        for (let u = 0; u < axiosthis.desserts.length; u++) {
                          // console.log(edisdata[i].isin == axiosthis.desserts[u].isin,edisdata[i].isin,axiosthis.desserts[u].isin,'damdam')
                          if (edisdata[i].isin == axiosthis.desserts[u].isin) {
                            if (edisdata[i].qty > 0) {
                              axiosthis.desserts[u].edisqtyapprove = edisdata[i].qty
                              axiosthis.desserts[u].edisapprove = 'true'
                              axiosthis.desserts[u].edisminqty = 1
                              axiosthis.desserts[u].edisMaxqty = edisdata[i].qty
                              axiosthis.edisapprove = 'true'

                            } else {
                              axiosthis.desserts[u].edisqtyapprove = 0
                              axiosthis.desserts[u].edisapprove = 'false'
                              axiosthis.desserts[u].edisminqty = ''
                              axiosthis.desserts[u].edisMaxqty = ''
                              axiosthis.edisapprove = 'false'
                            }

                          }
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  // axiosthis.desserts = corpdata
                  for (let i = 0; i < axiosthis.desserts.length; i++) {
                    if (axiosthis.desserts[i].issueType == 'BUYBACK' || axiosthis.desserts[i].issueType == 'BB') {
                      axiosthis.dessertsbuyback.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'DELISTING' || axiosthis.desserts[i].issueType == 'DS') {
                      axiosthis.dessertsdelist.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'TAKEOVER' || axiosthis.desserts[i].issueType == 'TO') {
                      axiosthis.dessertstake.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'IS' || axiosthis.desserts[i].issueType == 'RS') {
                      axiosthis.dessertsofs.push(axiosthis.desserts[i])
                    } else if (axiosthis.desserts[i].issueType == 'RIGHTS') {
                      axiosthis.dessertstrights.push(axiosthis.desserts[i])
                    }
                  }
                  // console.log(axiosthis.desserts);
                  axiosthis.loader = false
                  axiosthis.btnloader = false
                  axiosthis.dialoguorder = false
                  axiosthis.dialoguorderights = false
                  axiosthis.orderformobile = false
                  axiosthis.alert = true
                  axiosthis.msg = 'Order Placed'
                  setTimeout(() => {
                    axiosthis.msg = ''
                    axiosthis.alert = false
                  }, 3000);
                  // console.log(axiosthis.desserts,'val');

                  // for (let i = 0; i < axiosthis.checkordercoparray.length; i++) {

                  // }
                })
                .catch((error) => {
                  console.log(error);
                  if (error.message == 'Network Error') {
                    this.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert1 = true
                    setTimeout(() => {
                      axiosthis.alert1 = false
                    }, 5000);
                  }
                });
              // axiosthis.mountedcop()

            } else if (response.data.msg == 'error occured on data fetch' || response.data.msg == "client data or request data is invalid") {
              axiosthis.btnloader = false
              axiosthis.dialoguorder = false
              axiosthis.orderformobile = false
              axiosthis.alert1 = true
              axiosthis.msg = response.data.msg
              setTimeout(() => {
                axiosthis.msg = ''
                axiosthis.alert1 = false
              }, 3000);
            }

          })
          .catch((error) => {
            console.log(error);
            if (error.message == 'Network Error') {
              this.msg = error.message
              axiosthis.loader = false
              axiosthis.alert1 = true
              setTimeout(() => {
                axiosthis.alert1 = false
              }, 5000);
            }
          });
      }else if(this.upiidtype == ''){
        this.errorMessagesupiid = 'UPI Required'
      } else {
        this.$refs.validate(true)
      }

    },
    bidlessmore() {
      // console.log("jkjk");
      if (this.pricevalueuser > this.maxPrice) {
        this.pricevalueuser = this.maxPrice
        // } else if (this.pricevalueuser < this.minPrice) {
        //   this.pricevalueuser = this.minPrice
        // } else if (this.pricevalueuser <=  0) {
        //   this.pricevalueuser = this.minPrice
      }

    },
    ordervalcalculaterights() {
      if (this.bidvalueuser > this.qtyhave) {
        this.bidvalueuser = this.qtyhave
      }
    },
    ordervalcalculateedis() {
      if (this.bidvalueuser > this.qtyhave) {
        this.bidvalueuser = this.quantitymaxedis

      }
      if (this.bidvalueuser > this.quantitymaxedis) {
        // this.bidvalueuser = this.quantitymaxedis
        this.edisbtnorder = true
      } else if (this.bidvalueuser <= this.quantitymaxedis) {
        this.edisbtnorder = false

      }
    },
    ordervalcalculatechn(){
      if (this.bidvalueuser > this.qtyhave) {
        this.bidvalueuser = this.qtyhave
      }else{
        this.bidvalueuser = 1
      }
      //  else if (this.bidvalueuser <= 0) {
      //   this.bidvalueuser = this.minBidQuantity
      // }
      this.inverstvalue = this.bidvalueuser * this.pricevalueuser
    },
    ordervalcalculate() {
      if (this.bidvalueuser > this.qtyhave) {
        this.bidvalueuser = this.qtyhave
      }
      //  else if (this.bidvalueuser <= 0) {
      //   this.bidvalueuser = this.minBidQuantity
      // }
      this.inverstvalue = this.bidvalueuser * this.pricevalueuser
    },
    bidlessmorechnofs(){
      this.inverstvalueofs = this.ofspricevalueuser * this.ofsbidvalueuser
      if (this.inverstvalueofs > 200000) {
        this.ofsdisable = true
        this.errmsgforofs = 'OFS application amount cannot exceed ₹2,00,000'
      }else if(this.inverstvalueofs > this.ledgerbalance){
        this.ofsdisable = true
        this.errmsgforofs = 'Dont have a much amount to bid'
      }else if(this.ofspricevalueuser < this.ofscutOffPrice){
        // this.pricevalueuser = this.maxPrice
        this.ofsdisable = true
        this.errmsgforofs = `Price must be atleast ${this.ofscutOffPrice}`
      }else{
        this.ofsdisable = false
      }
    },
    cutoffadd() {
      // alert("ghjk")
      this.pricetextdisabled = !this.pricetextdisabled
      this.pricevalueuser = this.maxPrice
      this.ofspricevalueuser = this.ofscutOffPrice
      this.inverstvalueofs = this.ofspricevalueuser * this.ofsbidvalueuser
      if (this.inverstvalueofs > 200000) {
        this.ofsdisable = true
        this.errmsgforofs = 'OFS application amount cannot exceed ₹2,00,000'
      }else if(this.inverstvalueofs > this.ledgerbalance){
        this.ofsdisable = true
        this.errmsgforofs = 'Dont have a much amount to bid'
      }else if(this.ofspricevalueuser < this.ofscutOffPrice){
        // this.pricevalueuser = this.maxPrice
        this.ofsdisable = true
        this.errmsgforofs = `Price must be atleast ${this.ofscutOffPrice}`
      }else{
        this.ofsdisable = false
      }
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    edisprocesss() {
      // console.log(this.synameEdisprocess);
      const axios = require('axios');
      let dataedis = JSON.stringify({
        "isin": this.dataforedischeck,
        "clientid": this.actid
      });

      let configedis = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.79:9003/EdisReport',
        url: `${apiurlipo}/EdisReport`,

        headers: {
          'Content-Type': 'application/json'
        },
        data: dataedis
      };
      let axiosthis = this
      axios.request(configedis)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          let edisdata = response.data.data
          for (let y = 0; y < edisdata.length; y++) {
            // console.log(edisdata[y].isin == axiosthis.synameEdisprocess,edisdata[y].isin ,axiosthis.synameEdisprocess,"dardardar");

            if (edisdata[y].isin == axiosthis.synameEdisprocess) {
              // console.log(edisdata[y].isin == axiosthis.synameEdisprocess,edisdata[y].isin ,axiosthis.synameEdisprocess,"dardardar");
              // console.log(edisdata[y].qty > axiosthis.quantityapproveedit,edisdata[y].qty , axiosthis.quantityapproveedit,"dardardar2");

              if (edisdata[y].qty > axiosthis.quantityapproveedit && edisdata[y].qty > 0) {
                // console.log('tytytytytytytyty');
                          // new Notification(");
                          if ("Notification" in window) {
                            new Notification("Edis prosess complete  return to Corporateaction tab");
                          } else {
                            navigator.serviceWorker.ready
          .then(registration => registration.showNotification("Button Pressed"))
          .catch(error => console.error('Service Worker registration failed:', error));
                          }
                // console.log(edisdata[y].qty > axiosthis.quantityapproveedit,edisdata[y].qty , axiosthis.quantityapproveedit,"dardardar2");
                axiosthis.edisprocessloader = false
                if (this.mobilescreen == true) {
                  axiosthis.orderformobile = true
                  for (let i = 0; i < edisdata.length; i++) {
                    for (let u = 0; u < axiosthis.desserts.length; u++) {
                      // console.log(edisdata[i].isin == axiosthis.desserts[u].isin,edisdata[i].isin,axiosthis.desserts[u].isin,'damdam')
                      if (edisdata[i].isin == axiosthis.desserts[u].isin) {
                        if (edisdata[i].qty > 0) {

                          axiosthis.desserts[u].edisqtyapprove = edisdata[i].qty
                          axiosthis.desserts[u].edisapprove = 'true'
                          axiosthis.desserts[u].edisminqty = 1
                          axiosthis.desserts[u].edisMaxqty = edisdata[i].qty
                          axiosthis.edisapprove = 'true'
                          axiosthis.edisbtnorder = false
                          this.quantityapproveedit = parseInt(axiosthis.desserts[u].edisqtyapprove)
                          this.quantitymaxedis = parseInt(axiosthis.desserts[u].edisMaxqty)
                          this.quantityminedis = parseInt(axiosthis.desserts[u].edisminqty)
                          this.edisapprove = axiosthis.desserts[u].edisapprove
                          this.qtyhave = axiosthis.desserts[u].orderqualityhave
                          // console.log( axiosthis.desserts[u],'lklk');
                          this.orderqualitystat = axiosthis.desserts[u].orderquality
                          this.inverstvalue = ''
                          this.upiproviderselect = ''
                          this.upiidtype = ''
                          this.checkbox = false
                          // console.log( axiosthis.desserts[u])
                          this.isinEdisprocess = axiosthis.desserts[u].isin
                          this.tradingpassword = ''
                          this.issuetype = axiosthis.desserts[u].issueType
                          this.biddingEndDate = axiosthis.desserts[u].biddingEndDate
                          this.biddingStartDate = axiosthis.desserts[u].biddingStartDate
                          this.dailyStartTime = axiosthis.desserts[u].dailyStartTime
                          this.dailyEndTime = axiosthis.desserts[u].dailyEndTime
                          this.cutOffPrice = axiosthis.desserts[u].cutOffPrice
                          this.maxbidqty = axiosthis.desserts[u].maxbidqty
                          this.minBidQuantity = axiosthis.desserts[u].minBidQuantity
                          this.bidvalueuser = axiosthis.desserts[u].edisqtyapprove
                          this.syname = axiosthis.desserts[u].name
                          this.synameEdisprocess = axiosthis.desserts[u].isin
                          this.steplot = axiosthis.desserts[u].lotSize
                          this.minPrice = axiosthis.desserts[u].minPrice
                          this.maxPrice = axiosthis.desserts[u].maxPrice
                          this.pricevalueuser = axiosthis.desserts[u].minPrice
                          this.inverstvalue = this.steplot * this.minPrice
                          this.apisymbol = axiosthis.desserts[u].symbol
                          this.apitype = axiosthis.desserts[u].exchange
                          this.pricetextdisabled = false
                        }
                        // else{
                        //   axiosthis.desserts[u].edisqtyapprove = 0
                        //   axiosthis.desserts[u].edisapprove = 'false'
                        //   axiosthis.desserts[u].edisminqty = ''
                        //   axiosthis.desserts[u].edisMaxqty = ''
                        //   axiosthis.edisapprove = 'false'
                        // }


                      }
                    }
                  }
                } else {
                  axiosthis.dialoguorder = true
                  for (let i = 0; i < edisdata.length; i++) {
                    for (let u = 0; u < axiosthis.desserts.length; u++) {
                      // console.log(edisdata[i].isin == axiosthis.desserts[u].isin,edisdata[i].isin,axiosthis.desserts[u].isin,'damdam')
                      if (edisdata[i].isin == axiosthis.desserts[u].isin) {
                        if (edisdata[i].qty > 0) {

                          axiosthis.desserts[u].edisqtyapprove = edisdata[i].qty
                          axiosthis.desserts[u].edisapprove = 'true'
                          axiosthis.desserts[u].edisminqty = 1
                          axiosthis.desserts[u].edisMaxqty = edisdata[i].qty
                          axiosthis.edisapprove = 'true'
                          axiosthis.edisbtnorder = false
                          this.quantityapproveedit = parseInt(axiosthis.desserts[u].edisqtyapprove)
                          this.quantitymaxedis = parseInt(axiosthis.desserts[u].edisMaxqty)
                          this.quantityminedis = parseInt(axiosthis.desserts[u].edisminqty)
                          this.edisapprove = axiosthis.desserts[u].edisapprove
                          this.qtyhave = axiosthis.desserts[u].orderqualityhave
                          // console.log( axiosthis.desserts[u],'lklk');
                          this.orderqualitystat = axiosthis.desserts[u].orderquality
                          this.inverstvalue = ''
                          this.upiproviderselect = ''
                          this.upiidtype = ''
                          this.checkbox = false
                          // console.log( axiosthis.desserts[u])
                          this.isinEdisprocess = axiosthis.desserts[u].isin
                          this.tradingpassword = ''
                          this.issuetype = axiosthis.desserts[u].issueType
                          this.biddingEndDate = axiosthis.desserts[u].biddingEndDate
                          this.biddingStartDate = axiosthis.desserts[u].biddingStartDate
                          this.dailyStartTime = axiosthis.desserts[u].dailyStartTime
                          this.dailyEndTime = axiosthis.desserts[u].dailyEndTime
                          this.cutOffPrice = axiosthis.desserts[u].cutOffPrice
                          this.maxbidqty = axiosthis.desserts[u].maxbidqty
                          this.minBidQuantity = axiosthis.desserts[u].minBidQuantity
                          this.bidvalueuser = axiosthis.desserts[u].edisqtyapprove
                          this.syname = axiosthis.desserts[u].name
                          this.synameEdisprocess = axiosthis.desserts[u].isin
                          this.steplot = axiosthis.desserts[u].lotSize
                          this.minPrice = axiosthis.desserts[u].minPrice
                          this.maxPrice = axiosthis.desserts[u].maxPrice
                          this.pricevalueuser = axiosthis.desserts[u].minPrice
                          this.inverstvalue = this.steplot * this.minPrice
                          this.apisymbol = axiosthis.desserts[u].symbol
                          this.apitype = axiosthis.desserts[u].exchange
                          this.pricetextdisabled = false
                        }
                        // else{
                        //   axiosthis.desserts[u].edisqtyapprove = 0
                        //   axiosthis.desserts[u].edisapprove = 'false'
                        //   axiosthis.desserts[u].edisminqty = ''
                        //   axiosthis.desserts[u].edisMaxqty = ''
                        //   axiosthis.edisapprove = 'false'
                        // }


                      }
                    }
                  }
                  clearInterval(this.clearintervaledis);
                }
              }
            }
          }



        })
        .catch((error) => {
          console.log(error);
        });
    },
    ledgercheck(){
              const axios = require('axios');
        let data = JSON.stringify({
          "clientID": this.actid
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://rekycbe.mynt.in/getLedgerBalance',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        let axiosthis = this
        axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          axiosthis.ledgerbalance = parseFloat(response.data.Total)
          // console.log(axiosthis.ledgerbalance)
        })
        .catch((error) => {
          console.log(error);
        });

    },
    mountedcop() {
      this.ddpi = localStorage.getItem("ddpi");
      this.poa = localStorage.getItem("poa");
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      // var panno = localStorage.getItem("nap");
      // this.panno = this.decryptionFunction(panno)
      // console.log(this.panno, 'panpanpan');
      // const axios = require('axios');
      this.ledgercheck()
      let data = '';

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.122:9005/getCorporateAction',
        url: `${apiurlipo}/getCorporateAction`,

        headers: {},
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          if (response.data.msg == "get corporate action error") {
          axiosthis.loader = false
            
          }
          var corpdata = response.data.corporateAction
          // console.log(JSON.stringify(response.data));
          axiosthis.checkordercoparray = corpdata
          axiosthis.selectedTag = 'All'
          axiosthis.loadermobile = true
          axiosthis.loader = true
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
          var todayapi = dd + '/' + mm + '/' + yyyy;
          axiosthis.susertoken = localStorage.getItem("usession");
          axiosthis.actid = localStorage.getItem("userid");
          let data = JSON.stringify({
            // "cc": this.actid,
            "cc": axiosthis.actid,
            "to": todayapi

          });

          let config = {
            method: 'post',
            url: `${apiurl}/getHoldings`,

            headers: {
              'Content-Type': 'application/json',
              'clientid': axiosthis.actid,
              'Authorization': axiosthis.susertoken
            },
            data: data
          };
          // let axiosthis = this;
          axios.request(config)
            .then((response) => {
              // axiosthis.fetchorderdetails()

              var res = response.data
              //  //console.log(res)
              if (res != 'no data found') {
                var holddata = []
                holddata = response.data['Holdings']
                //         var dummy = {}
                //          dummy = {
                //     "asbanonasba": "1",
                //     "biddingEndDate": "07-02-2024",
                //     "biddingStartDate": "01-02-2024",
                //     "cutOffPrice": "21.00",
                //     "dailyEndTime": "15:30:00",
                //     "dailyStartTime": "09:15:00",
                //     "NET": 1,
                //     "discountvalue": "0",
                //     "errorcode": "0",
                //     "exchange": "BSE",
                //     "faceValue": "",
                //     "index": "",
                //     "isin": "INE185K01036",
                //     "issueSize": "6300000.0000",
                //     "issueType": "BUYBACK",
                //     "issueValueSize": "",
                //     "lotSize": 1,
                //     "maxPrice": 21.0,
                //     "maxbidqty": "6300000",
                //     "maxvalue": "132300000.0000",
                //     "message": "",
                //     "minBidQuantity": "1",
                //     "minPrice": "21.00",
                //     "minvalue": "21.0000",
                //     "name": "Arnold Holdings Ltd",
                //     "registrar": "",
                //     "seriesDetails": "",
                //     "SCRIP_SYMBOL": "ARNOLD",
                //     "tickSize": "0.01"
                // },
                // holddata.push(dummy);

                for (let i = 0; i < holddata.length; i++) {
                  let found = false;  // Flag to track if a match is found for holddata[i]

                  for (let u = 0; u < corpdata.length; u++) {
                    if (holddata[i].ISIN == corpdata[u].isin) {
                      // console.log(holddata[i].SCRIP_SYMBOL, corpdata[u].symbol);
                      corpdata[u].orderquality = 'true';
                      corpdata[u].orderqualityhave = holddata[i].NET
                      // console.log(corpdata);
                      // axiosthis.dataforedischeck = []
                      this.dataforedischeck.push(holddata[i].ISIN)
                      // this.dataforedischeck= [...new Set(this.dataforedischeck)];
                      // console.log(this.this.dataforedischeck)
                      // console.log(this.dataforedischeck,'fulldata')
                      found = true;  // Set the flag to true when a match is found
                    } else {
                      if (corpdata[u].issueType == 'IS' || corpdata[u].issueType == 'RS') {
                        corpdata[u].orderquality = 'true';
                      corpdata[u].orderqualityhave = holddata[i].NET
                      corpdata[u].ordertype = 'ofs'
                      }
                      // corpdata[u].orderquality = 'true';
                      // corpdata[u].orderqualityhave = holddata[i].NET
                      // corpdata[u].orderqualityhave = 0

                    }
                  }

                  if (!found) {
                    // If no match was found for holddata[i], set orderquality to false
                    // You can choose to log or handle this case as needed
                    // corpdata[u].orderquality = false; // This line is removed
                    // console.log(holddata[i].SCRIP_SYMBOL, "No match found");
                  }
                }

                const axios = require('axios');


                // const axios = require('axios');
                let data = '';

                let config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  // url: 'http://192.168.5.122:9005/fetchorderdetailcorp',
                  url: `${apiurlipo}/fetchorderdetailcorp`,

                  headers: {
                    'Authorization': this.susertoken,
                    'clientid': this.actid
                  },
                  data: data
                };
                let axiosthis = this
                axios.request(config)
                  .then((response) => {
                    // console.log((response.data));
                    axiosthis.desserts = corpdata

                    var holddatafech = response.data.msg
                    if (holddatafech != 'No data') {
                      for (let i = 0; i < holddatafech.length; i++) {
                        const holdSymbol = holddatafech[i].symbol;

                        // Check if there is a corresponding dessert with the same symbol
                        const matchingDessert = axiosthis.desserts.find(dessert => dessert.symbol === holdSymbol);

                        if (matchingDessert) {
                          matchingDessert.statusorder = holddatafech[i].status;
                          matchingDessert.appno = holddatafech[i].application_no;
                          matchingDessert.appbidqty = holddatafech[i].bid_quan;
                          matchingDessert.apprice = holddatafech[i].price;
                        }
                      }

                      // Reset properties for desserts without a match
                      axiosthis.desserts.forEach(dessert => {
                        if (!holddatafech.some(hold => hold.symbol === dessert.symbol)) {
                          dessert.statusorder = '';
                          dessert.appno = '';
                          dessert.appbidqty = '';
                          dessert.apprice = '';
                        }
                      });
                    }
                    const axios = require('axios');
                    let dataedis = JSON.stringify({
                      "isin": this.dataforedischeck,
                      "clientid": this.actid
                    });

                    let configedis = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      // url: 'http://192.168.5.79:9003/EdisReport',
                      url: `${apiurlipo}/EdisReport`,

                      headers: {
                        'Content-Type': 'application/json'
                      },
                      data: dataedis
                    };

                    axios.request(configedis)
                      .then((response) => {
                        // console.log(JSON.stringify(response.data));
                        let edisdata = response.data.data
                        for (let i = 0; i < edisdata.length; i++) {
                          for (let u = 0; u < axiosthis.desserts.length; u++) {
                            // console.log(edisdata[i].isin == axiosthis.desserts[u].isin,edisdata[i].isin,axiosthis.desserts[u].isin,'damdam')
                            if (edisdata[i].isin == axiosthis.desserts[u].isin) {
                              if (edisdata[i].qty > 0) {
                                axiosthis.desserts[u].edisqtyapprove = edisdata[i].qty
                                axiosthis.desserts[u].edisapprove = 'true'
                                axiosthis.desserts[u].edisminqty = 1
                                axiosthis.desserts[u].edisMaxqty = edisdata[i].qty
                                axiosthis.edisapprove = 'true'

                              } else {
                                axiosthis.desserts[u].edisqtyapprove = 0
                                axiosthis.desserts[u].edisapprove = 'false'
                                axiosthis.desserts[u].edisminqty = ''
                                axiosthis.desserts[u].edisMaxqty = ''
                                axiosthis.edisapprove = 'false'
                              }

                            }
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });

                    for (let i = 0; i < axiosthis.desserts.length; i++) {
                      if (axiosthis.desserts[i].issueType == 'BUYBACK' || axiosthis.desserts[i].issueType == 'BB') {
                        axiosthis.dessertsbuyback.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'DELISTING' || axiosthis.desserts[i].issueType == 'DS') {
                        axiosthis.dessertsdelist.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'TAKEOVER' || axiosthis.desserts[i].issueType == 'TO') {
                        axiosthis.dessertstake.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'IS' ||axiosthis.desserts[i].issueType ==  'RS') {
                        axiosthis.dessertsofs.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'RIGHTS') {
                        axiosthis.dessertstrights.push(axiosthis.desserts[i])
                      }
                    }
                    // console.log(axiosthis.desserts);
                    axiosthis.loader = false
                    // console.log(axiosthis.desserts,'val');

                    // for (let i = 0; i < axiosthis.checkordercoparray.length; i++) {

                    // }
                  })
                  .catch((error) => {
                    console.log(error);
                    if (error.message == 'Network Error') {
                      this.msg = error.message
                      axiosthis.loader = false
                      axiosthis.alert1 = true
                      setTimeout(() => {
                        axiosthis.alert1 = false
                      }, 5000);
                    }
                  });


              } else {
                const axios = require('axios');
                let data = '';

                let config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  // url: 'http://192.168.5.122:9005/fetchorderdetailcorp',
                  url: `${apiurlipo}/fetchorderdetailcorp`,

                  headers: {
                    'Authorization': this.susertoken,
                    'clientid': this.actid
                  },
                  data: data
                };
                let axiosthis = this
                axios.request(config)
                  .then((response) => {
                    // console.log((response.data));
                    var holddata = response.data.msg
                    for (let i = 0; i < holddata.length; i++) {
                      const holdSymbol = holddata[i].symbol;

                      // Check if there is a corresponding dessert with the same symbol
                      const matchingDessert = axiosthis.desserts.find(dessert => dessert.symbol === holdSymbol);

                      if (matchingDessert) {
                        matchingDessert.statusorder = holddata[i].status;
                        matchingDessert.appno = holddata[i].application_no;
                        matchingDessert.appbidqty = holddata[i].bid_quan;
                        matchingDessert.apprice = holddata[i].price;
                      }
                    }
                    axiosthis.desserts = axiosthis.checkordercoparray
                    for (let i = 0; i < axiosthis.desserts.length; i++) {
                      if (axiosthis.desserts[i].issueType == 'BUYBACK' || axiosthis.desserts[i].issueType == 'BB') {
                        axiosthis.dessertsbuyback.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'DELISTING' || axiosthis.desserts[i].issueType == 'DS') {
                        axiosthis.dessertsdelist.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'TAKEOVER' || axiosthis.desserts[i].issueType == 'TO') {
                        axiosthis.dessertstake.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'IS' || axiosthis.desserts[i].issueType ==  'RS') {
                        axiosthis.dessertsofs.push(axiosthis.desserts[i])
                      } else if (axiosthis.desserts[i].issueType == 'RIGHTS') {
                        axiosthis.dessertstrights.push(axiosthis.desserts[i])
                      }
                    }
                    // console.log(axiosthis.desserts);
                    axiosthis.loader = false

                    // console.log(axiosthis.desserts,'val');

                    // for (let i = 0; i < axiosthis.checkordercoparray.length; i++) {

                    // }
                  })
                  .catch((error) => {
                    console.log(error);
                    if (error.message == 'Network Error') {
                      this.msg = error.message
                      axiosthis.loader = false
                      axiosthis.alert1 = true
                      setTimeout(() => {
                        axiosthis.alert1 = false
                      }, 5000);
                    }
                  });


              }
            })





        })
        .catch((error) => {
          console.log(error);
          if (error.message == 'Network Error') {
            this.msg = error.message
            axiosthis.loader = false
            axiosthis.alert1 = true
            setTimeout(() => {
              axiosthis.alert1 = false
            }, 5000);
          }

        });

    },
    requestNotificationPermission() {
      // Check if the browser supports notifications
      if ("Notification" in window) {
        Notification.requestPermission().then(permission => {
          console.log("Notification permission:", permission);
        });
      } else {
        alert("This browser does not support desktop notifications");
      }
    },
    notify() {
      // Check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notifications");
        // console.log(Notification.permission);
      } else if (Notification.permission === "granted") {
        // If notification permission is granted, show notification
        new Notification("Button Pressed");
        // console.log(Notification.permission);

      } else if (Notification.permission !== 'denied') {
        // Otherwise, request permission from the user
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            new Notification("Button Pressed");
          }
        });
      }
      // console.log(Notification.permission);

    },
    orderplacedrights(item) {
      this.mobilescreen = false

      this.edisbtnorder = false
      this.quantityapproveedit = parseInt(item.edisqtyapprove)
      this.quantitymaxedis = parseInt(item.edisMaxqty)
      this.quantityminedis = parseInt(item.edisminqty)
      this.edisapprove = item.edisapprove
      this.qtyhave = item.orderqualityhave
      // console.log(item,'lklk');
      this.orderqualitystat = item.orderquality
      this.inverstvalue = ''
      this.upiproviderselect = ''
      this.upiidtype = ''
      this.checkbox = false
      // console.log(item)
      this.isinEdisprocess = item.isin
      this.tradingpassword = ''
      this.dialoguorderights = true
      this.issuetype = item.issueType
      this.biddingEndDate = item.biddingEndDate
      this.biddingStartDate = item.biddingStartDate
      this.dailyStartTime = item.dailyStartTime
      this.dailyEndTime = item.dailyEndTime
      this.cutOffPrice = item.cutOffPrice
      this.maxbidqty = item.maxbidqty
      this.minBidQuantity = 1
      this.bidvalueuser = item.minBidQuantity
      this.syname = item.name
      this.synameEdisprocess = item.isin
      this.steplot = item.lotSize
      this.minPrice = item.minPrice
      this.maxPrice = item.maxPrice
      this.pricevalueuser = item.minPrice
      this.inverstvalue = this.steplot * this.minPrice
      this.apisymbol = item.symbol
      this.apitype = item.exchange
      this.pricetextdisabled = false
    },
    orderplaced(item) {
      this.mobilescreen = false
      this.edisbtnorder = false
      this.quantityapproveedit = parseInt(item.edisqtyapprove)
      this.quantitymaxedis = parseInt(item.edisMaxqty)
      this.quantityminedis = parseInt(item.edisminqty)
      this.edisapprove = item.edisapprove
      this.qtyhave = item.orderqualityhave
      this.ordertype = item.ordertype
      // console.log(item,'lklk');
      this.orderqualitystat = item.orderquality
      this.inverstvalue = ''
      this.upiproviderselect = ''
      this.upiidtype = ''
      this.checkbox = false
      // console.log(item)
      this.isinEdisprocess = item.isin
      this.tradingpassword = ''
      this.dialoguorder = true
      this.issuetype = item.issueType
      this.biddingEndDate = item.biddingEndDate
      this.biddingStartDate = item.biddingStartDate
      this.dailyStartTime = item.dailyStartTime
      this.dailyEndTime = item.dailyEndTime
      this.cutOffPrice = item.cutOffPrice
      this.ofscutOffPrice = item.baseprice
      this.maxbidqty = item.maxbidqty
      this.minBidQuantity = item.minBidQuantity
      this.bidvalueuser = item.minBidQuantity
      this.ofsbidvalueuser = 1
      this.syname = item.name
      this.synameEdisprocess = item.isin
      this.steplot = item.lotSize
      this.minPrice = item.minPrice
      this.maxPrice = item.maxPrice
      this.pricevalueuser = item.minPrice
      this.ofspricevalueuser = item.baseprice
      this.inverstvalue = this.steplot * this.minPrice
      this.inverstvalueofs = this.ofspricevalueuser * this.ofsbidvalueuser
      this.apisymbol = item.symbol
      this.apitype = item.exchange
      this.pricetextdisabled = false
      this.inverstvalueofs = this.ofspricevalueuser * this.ofsbidvalueuser
      if (this.inverstvalueofs > 200000) {
        this.ofsdisable = true
        this.errmsgforofs = 'OFS application amount cannot exceed ₹2,00,000'
      }else if(this.inverstvalueofs > this.ledgerbalance){
        this.ofsdisable = true
        this.errmsgforofs = 'Dont have a much amount to bid'
      }else if(this.ofspricevalueuser < this.ofscutOffPrice){
        // this.pricevalueuser = this.maxPrice
        this.ofsdisable = true
        this.errmsgforofs = `Price must be atleast ${this.ofscutOffPrice}`
      }else{
        this.ofsdisable = false
      }
    },
    orderplacedmobile(item) {
      this.ofsbidvalueuser = 1
      
      this.mobilescreen = true
      this.edisbtnorder = false
      this.quantityapproveedit = parseInt(item.edisqtyapprove)
      this.quantitymaxedis = parseInt(item.edisMaxqty)
      this.quantityminedis = parseInt(item.edisminqty)
      this.edisapprove = item.edisapprove
      this.qtyhave = item.orderqualityhave
      // console.log(this.qtyhave, item.orderqualityhave);
      this.orderqualitystat = item.orderquality
      this.inverstvalue = ''
      this.upiproviderselect = ''
      this.upiidtype = ''
      this.checkbox = false
      // console.log(item)
      this.orderformobile = true
      this.issuetype = item.issueType
      this.biddingEndDate = item.biddingEndDate
      this.biddingStartDate = item.biddingStartDate
      this.dailyStartTime = item.dailyStartTime
      this.dailyEndTime = item.dailyEndTime
      this.cutOffPrice = item.cutOffPrice
      this.ofscutOffPrice = item.baseprice
      this.maxbidqty = item.maxbidqty
      this.minBidQuantity = item.minBidQuantity
      this.bidvalueuser = item.minBidQuantity
      this.syname = item.name
      this.steplot = item.lotSize
      this.minPrice = item.minPrice
      this.maxPrice = item.maxPrice
      this.pricevalueuser = item.minPrice
      this.inverstvalue = this.steplot * this.minPrice
      this.apisymbol = item.symbol
      this.apitype = item.exchange
      this.pricetextdisabled = false
      this.ofspricevalueuser = item.baseprice
      this.ofscutOffPrice = item.baseprice
      this.ordertype = item.ordertype
      this.inverstvalueofs = this.ofspricevalueuser * this.ofsbidvalueuser

      if (this.inverstvalueofs > 200000) {
        this.ofsdisable = true
        this.errmsgforofs = 'OFS application amount cannot exceed ₹2,00,000'
      }else if(this.inverstvalueofs > this.ledgerbalance){
        this.ofsdisable = true
        this.errmsgforofs = 'Dont have a much amount to bid'
      }else if(this.ofspricevalueuser < this.ofscutOffPrice){
        // this.pricevalueuser = this.maxPrice
        this.ofsdisable = true
        this.errmsgforofs = `Price must be atleast ${this.ofscutOffPrice}`
      }else{
        this.ofsdisable = false
      }

    },
    cancelorderpop(item) {
      this.cancelconform = true
      // console.log(item);
      // console.log(item)
      // this.dialoguorder = true
      this.issuetype = item.issueType
      this.apprice = parseFloat(item.apprice)
      this.appbidqty = item.appbidqty
      this.appno = item.appno
      this.apisymbol = item.symbol
      this.apitype = item.exchange
      this.ofscutOffPrice = item.baseprice
    },
    fetchorderdetails() {


    },
    edisprocessendpoint() {
      const axios = require('axios');
      let dataedis = JSON.stringify({
        "isin": this.isinEdisprocess,
        "clientid": this.actid
      });

      let configedis = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.78:9003/EdisReport',
        url: `${apiurlipo}/EdisReport`,

        headers: {
          'Content-Type': 'application/json'
        },
        data: dataedis
      };

      axios.request(configedis)
        .then((response) => {
          this.roughtdata = response
        })
        .catch((error) => {
          console.log(error);
        });

    }
  },

  mounted() {
    // Example usage
    this.mountedcop()
    // this.notify()
    // Notification.requestPermission().then(permission => {
    // if (permission === "granted") {
    // }else{
    // new Notification("Button Pressed");
    // }
    // });
    // setInterval(() => {
    //   this.notify()
    // }, 3000);

  },
};
</script>
  
<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}


input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #020202 !important;
}

.v-tab {
  text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
  /* flex: 1 0 auto; */
  max-width: none !important;
}</style>